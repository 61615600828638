import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
// import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { DatatableComponent } from 'src/app/components/datatable-custom/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { ModalClientListsFormComponent } from '../components/form/client-lists-form.component';
import { ModalClientListsFilterSelectorComponent } from '../components/filter-selector/client-lists-filter-selector.component';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';

@Component({
  selector: 'client-filter-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatatableComponent,
    PaginatorComponent,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    ButtonCapYeiComponent,
  ]
})
export class ClientFilterListPage implements OnInit, OnDestroy {

  title = 'Listas de Contactos';

  pagination = null;
  data:any = [];

  model = 'list-group';

  noData: boolean = false;
  loadingData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:0,width:100},
    {id:'name',label:'Nombre',hideOrder:2},
    {id:'type_data',label:'Tipo',hideOrder:3},
    {id:'date',label:'Fecha de registro',hideOrder:4},
    {id:'action_buttons',label:'Acciones',hideOrder:1},
  ];

  visibilityBtns:any[] = [];
  create:boolean = true;

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,

    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private dialog: MatDialog,
    private alert: AlertService,
  ) {
    this.initializeApp();
  }

  clickEvent(fila: any, action: any, id: any, item: any) {
    console.log(item);
    if(action == 'remove') {
      this.removeItem((`"${item['name']}"`), id);
    } else if(action == 'active') {
      this.activeItem((`"${item['name']}"`), id);
    } else if(action == 'add') {
      this.formAddFilter(id, item);
    } else {
      // this.router.navigate([`${this.model}`, action, id]);
      this.formCreate(action, id);
    }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    this.loadingData = true;
    this.noData = false;
    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`)).subscribe(result => {
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      let items = {
        ...element,
        name: element['name'],
        type_data: element['type'],
        date: element['created_at'] ? this.xServices.formatDate(element['created_at']) : null,
      };
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      this.pagination = result['items'];
    } else {
      this.noData = true;
    }

    if(result['actions']) {
      this.showActionsButtons(result);
    }
  }

  userData(name?: string, img?: string, email?: string, phone?: string) {
    if(name) {
      return `<div class="user-custom">
        <div class="img-custom">
        ${ img ? `<img src="${img}" alt="${name}">` : `<img src="assets/img/user.png" alt="${name}">`}
        </div>
        <div class="info-custom">
          <h5>${name}</h5>
          ${email? `<p>${email}</p>` : ''}
          ${phone? `<p class="phone">${phone}</p>` : ''}
        </div>
      </div>`;
    } else {
      return `<div class="not__asign">Sin asignar</div>`;
    }
  }

  typeData(name: string) {
    switch (name) {
      case 'static':
        return 'Por contactos';

      case 'dynamic':
        return 'Por segmentos';
    
      default:
        return '-';
    }
  }

  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          this.processData(result);
        } else {
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']){
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/active`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = [
      // { id: 'roles', active: this.isAdmin },
      { id: 'read', active: result['actions']['read'] },
      { id: 'edit', active: result['actions']['edit'] },
      { id: 'active', active: result['actions']['delete'] },
      { id: 'inactive', active: result['actions']['delete'] },
      { id: 'remove', active: result['actions']['delete'] },
    ];
    this.create = result['actions']['create'];
  }

  formCreate(action: 'create'|'read'|'edit', id?:any) {
    const dialogRef = this.dialog.open(ModalClientListsFormComponent, {
      width: '550px',
      // maxWidth: '100%',
      // height: '100%',
      disableClose: false,
      autoFocus: false,
      // position: {
      //   top: '0px',
      //   bottom: '0px',
      //   right: '0px',
      // },
      data: {
        action: action,
        id: id ?? null,
        // classComponent: 'full__hsize',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }
  
  formAddFilter(id:any, item: any) {
    const dialogRef = this.dialog.open(ModalClientListsFilterSelectorComponent, {
      width: '850px',
      disableClose: false,
      autoFocus: false,
      data: {
        id: id,
        item: item,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      // if(result&&result['reloadData']) {
        this.initializeApp();
      // }
    });
  }
}
