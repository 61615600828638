import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogData } from 'src/app/interfaces/interfaces';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SearchDirective } from 'src/app/directives/search.directive';
import { FilterPipe } from 'src/app/pipes/filter';

@Component({
  selector: 'modal-mailing-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule, FilterPipe, SearchDirective, MatFormFieldModule, MatDialogModule, MatButtonModule],
})
export class ModalMailingFormPage implements OnInit {

  title = 'ENVIAR E-MAIL';

  dataForm:any = this.fb.group({
    name_user:              new FormControl(null, [Validators.required]),
    name_recipients:        new FormControl(null, [Validators.required]),
    title_msg:              new FormControl(null, [Validators.required]),
    message:                new FormControl(null, [Validators.required]),
    template:               new FormControl(null, [Validators.required]),
  });

  filterContactsList:string = '';
  loadingContactsList:boolean = false;
  noDataContactsList:boolean = false;
  contacts_list:any[] = [
    { id: 1, name: 'Lista de ejemplo'},
    { id: 2, name: 'Lista de ejemplo 2'},
  ];

  filterTemplate:string = '';
  loadingTemplate:boolean = false;
  noDataTemplate:boolean = false;
  templates:any[] = [
    { id: 1, name: 'Template 1'},
    { id: 2, name: 'Template 2'},
  ];
  
  constructor(
    private load: LoadingService,
    public xServices: XtrasService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<ModalMailingFormPage>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm() {
    let actives = false;
    this.dataForm.patchValue
    console.log("FORMULARIO .....", this.dataForm.value)
    this.load.loadingShow();

    setTimeout(()=>{
      actives= true;
      this.load.dismiss()
      this.dialogRef.close({status: true, msg:"Mensaje enviados exitosamente"});
    }, 2000);
  }

}
