<div mat-dialog-title class="!m-0 w-full block before:!hidden">
  <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
    ENVIAR E-MAIL
  </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
  <div class="block w-full p-6">
    <form class="account-settings-form" (ngSubmit)="submitForm()" [formGroup]="dataForm">
      <div class="w-full mt-2 grid grid-cols-1 gap-4">
    
        <div class="w-full">
          <label for="name_recipients" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Lista</span>:</label>
          <mat-select id="name_recipients" name="name_recipients" formControlName="name_recipients" placeholder="Seleccione una opción" class="custom__mat-select">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterContactsList = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingContactsList; else elseLoadingContactsList">
              <ng-container *ngIf="!noDataContactsList; else elseContactsList">
                <mat-option *ngFor="let item of contacts_list | filter: filterContactsList : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
              </ng-container>
              <ng-template #elseContactsList>
                <mat-option [value]="null">No se encontraron listas de contactos</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingContactsList>
              <mat-option [value]="null">Cargando listas de contactos...</mat-option>
            </ng-template>
          </mat-select>
        </div>

        <div class="w-full">
          <label for="template" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Template</span>:</label>
          <mat-select id="template" name="template" formControlName="template" placeholder="Seleccione una opción" class="custom__mat-select">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterTemplate = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingTemplate; else elseLoadingTemplate">
              <ng-container *ngIf="!noDataTemplate; else elseTemplate">
                <mat-option *ngFor="let item of templates | filter: filterTemplate : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
              </ng-container>
              <ng-template #elseTemplate>
                <mat-option [value]="null">No se encontraron templates</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingTemplate>
              <mat-option [value]="null">Cargando templates...</mat-option>
            </ng-template>
          </mat-select>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
  <div class="w-full flex flex-wrap justify-between actions_modal">
    <div class="block"></div>
    <div class="flex flex-wrap justify-between gap-4">
      <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

      <button mat-button type="button" (click)="submitForm()" class="cy__btn btn__second">
        <i class="fa-light fa-check"></i> Enviar E-mail
      </button>
    </div>
  </div>
</div>
