import { Component, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';

import {UserCustomComponent} from 'src/app/components/user-custom/user-custom.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalMailingFormPage } from 'src/app/pages/mailing/components/email-form-modal/email-form.component';
import { MailingMsgModalPage } from 'src/app/components/msg-modal/msg-modal.page';


@Component({
  selector: 'mailing-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, DatatableComponent, PaginatorComponent, UserCustomComponent, MatDialogModule, MatButtonModule]
})
export class MailingListPage implements OnInit, OnDestroy {

  title = 'Mailing';

  pagination = null;
  data:any = [];

  organizations:any = [];
  codOrganization = new FormControl(null);
  model = 'email';

  isAdmin:boolean = false;

  noData: boolean = false;
  loadingData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:2,html:true},
    {id:'list',label:'Lista',hideOrder:3},
    {id:'template',label:'Template',hideOrder:4,html:true},
    {id:'status',label:'Estado',hideOrder:5},
    /* {id:'action_buttons',label:'Acciones',hideOrder:1}, */
  ];

  actions:any[] = [
    { id: 'read', label: 'Ver', only_icon: true, icon: 'fa-light fa-eye', class: 'btn-action info', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'edit', label: 'Editar', only_icon: true, icon: 'fa-light fa-edit', class: 'btn-action success', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'remove', label: 'Eliminar', only_icon: true, icon: 'fa-light fa-trash', class: 'btn-action danger', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
  ];

  visibilityBtns:any[] = [];
  create:boolean = true;

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,
    public dialog: MatDialog,
    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private alert: AlertService,
  ) {
    this.initializeApp();
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    if(action == 'remove') {
      this.removeItem((`"${item['name']}"`), id);
    } else if(action == 'active') {
      this.activeItem((`"${item['name']}"`), id);
    } else if(action == 'roles') {
      this.router.navigate([`/${this.model}/role`, id]);
    } else {
      this.router.navigate([`/${this.model}`, action, id]);
    }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    
    this.noData = false;
    this.loadingData = true;

    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;

      let items = {
        ...element,
        id: element['id'] ?? 0,
        list: element['list_name'] ?? '-',
        template: element['template_name'] ?? '-',
        status: element['status'] ? `<div class="status status__${element['status']}">${element['status_name']}</div>` : '-',
      };
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      this.pagination = result['items'];
    } else {
      this.noData = true;
    }

    // if(result['actions']) {
    //   this.showActionsButtons(result);
    // }
  }
  
  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          this.processData(result);
        } else {
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']){
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/remove`), { id: id }).subscribe(result => {
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      }
    });
  }

  activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/active`), { id: id }).subscribe(result => {
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      }
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = [
      { id: 'read', active: result['actions']['read'] },
      { id: 'edit', active: result['actions']['edit'] },
      { id: 'active', active: result['actions']['delete'] },
      { id: 'inactive', active: result['actions']['delete'] },
      { id: 'remove', active: result['actions']['delete'] },
    ];
    this.create = result['actions']['create'];
  }

  openForm (): void {
    const dialogRef = this.dialog.open(ModalMailingFormPage, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      panelClass: 'content_modal',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result?.status) {
        this.madalMsg(result['msg'], result['status'] );
      }
    });
  }

  madalMsg ( msg:string, status:boolean): void {
    const dialogRef = this.dialog.open(MailingMsgModalPage, {
      width: '350px',
      data: {content: msg, note: status,},
      disableClose: true,
    });
  }

  userTable(name_img:any, name_user:string, email?:string, phone?:string){
    return `<div class="user-custom">
      <div class="img-custom">
      ${ name_img? `<img src="${name_img}" alt="img">` : '<img src="assets/img/user.png" alt="img">'}
      </div>
      <div class="info-custom">
        <h5>${name_user}</h5>
        ${email? `<p>${email}</p>` : ``}
        ${phone? `<p class="phone">${phone}</p>` : ``}
      </div>
    </div>`;
  }

}