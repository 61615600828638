<div class="pt-5 lg:px-5 px-0 pb-[5rem] w-full container mx-auto">
  <div class="lg:grid lg:grid-cols-[minmax(0,30%)_minmax(0,70%)] flex md:flex-col flex-wrap gap-4 w-full">
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <h5 class="text-[color:var(--primary-color)] font-semibold text-lg inline-block relative m-0 mb-4 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">Foto de Perfil</h5>
      <div class="w-full">
        <div class="content__img-profile block w-[200px] max-w-full mx-auto relative before:pb-[100%] before:block">
          <ng-container *ngIf="!imagePreview">
            <ng-container *ngIf="user$ | async as _user">
              <img [src]="_user.image_url || '/assets/img/user.png'" class="w-full h-full absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 object-cover object-center" alt="Account Image">
            </ng-container>
          </ng-container>
          <img *ngIf="imagePreview" [src]="imagePreview" class="w-full h-full absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 object-cover object-center" alt="Account Image">
        </div>
        <form class="grid grid-cols-1 mt-4" files="true" (ngSubmit)="submitFormImage()" [formGroup]="fieldsCredentialImage">
          <div class="justify-self-center flex flex-wrap justify-center items-center gap-2">
            <div class="content__file-input cursor-pointer">
              <label for="image" class="btn__image-change flex items-center py-2 px-4 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white cursor-pointer text-base">
                <i class="fa-light fa-image mr-2"></i>
                <span>Seleccionar Imagen</span>
              </label>
              <input type="file" name="image" id="image" accept="image/*" (change)="showPreview($event)" required class="file__img hidden">
            </div>
            <!-- <a href="·" class="flex bg-red-400 hover:bg-red-500 text-white py-2 px-4 rounded cursor-pointer text-base items-center"><i class="fa-light fa-trash mr-2"></i> Eliminar Foto</a> -->
          </div>
          <div class="block w-full mt-4"><hr class="m-0"></div>
          <input type="submit" [disabled]="fieldsCredentialImage.invalid" class="block bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white py-2 px-6 rounded cursor-pointer text-base mt-3 justify-self-center" value="Guardar Foto de Perfil" />
        </form>
      </div>
    </div>
    
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <form class="account-settings-form grid grid-cols-1" (ngSubmit)="submitForm()" #formData="ngForm">
        <h5 class="text-[color:var(--primary-color)] font-semibold text-lg inline-block relative m-0 mb-4 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">Información General</h5>
        <p class="small-paragraph-spacing text-sm mb-4">Al saber tus datos personales, podemos hacer de tu experiencia mas cálida y cercana.</p>
        <div class="w-full mt-2 grid md:grid-cols-[minmax(0,50%)_minmax(0,50%)] grid-cols-[minmax(0,100%)] gap-4">
          <div class="w-full">
            <label for="first-name" class="block w-full text-[color:var(--second-color)] text-sm font-semibold mb-2">Nombre:</label>
            <input type="text" name="first_name" id="first-name" [(ngModel)]="fieldsCredential.name" class="block m-0 w-full border-solid border-[1px] border-gray-300 rounded py-2 px-4 text-base">
          </div>
        </div>
        
        <hr class="block w-full my-4">
        <div class="block w-full text-center">
          <input type="submit" [disabled]="!formData.form.valid" class="font__text cursor-pointer inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white" value="Guardar los Cambios" />
        </div>
      </form>
    </div>
  </div>
</div>