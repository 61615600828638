import { MatDatepickerModule } from '@angular/material/datepicker';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule } from '@angular/material/core';
import { FilterPipe } from '../../pipes/filter';
import { SearchDirective } from '../../directives/search.directive';
import { DynamicFormFieldModel, FileData } from '../../interfaces/interfaces';

@Component({
	selector: 'app-dynamic-form-field',
	templateUrl: './dynamic-form-field.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    FilterPipe,
    SearchDirective,
  ],
  providers: [
    MatDatepickerModule,
  ],

})
export class DynamicformFieldComponent {

	@Input() formItem!: DynamicFormFieldModel;
	@Input() submitted!: boolean;
	@Input() type!: DynamicFormFieldModel['type'];
	@Input() action!: DynamicFormFieldModel['action'];
  @Output() changeValue = new EventEmitter<any>();

  date: Date | null = null;

  filterTerm!: any;

	form!: FormGroup;

  get regFormControl() {
    return this.form.controls;
  }

  filePreview: string | null = null;

  fileItem?: FileData | null = null;
  initDate = null;

	constructor(
    private rootFormGroup: FormGroupDirective,
    private datePipe: DatePipe,
  ) {
		this.form = this.rootFormGroup.control;

	}

  ngOnInit() {
    if(this.formItem.parent != null || this.formItem.parent != undefined) {
      this.form.get(this.formItem.name)?.valueChanges.subscribe(data => {
        this.changeValue.emit(data);
      });
    }
    if(this.type == 'file' || this.type == 'image') {
      this.filePreview = this.form.get(this.formItem.name)?.value;
    } else if( this.type == 'date' && this.form.get(this.formItem.name)?.value) {
      var d = new Date(this.form.get(this.formItem.name)?.value);
      d.setMinutes( d.getMinutes() + d.getTimezoneOffset() )
      this.date = d;
    }
  }

  public hasRequiredField = (abstractControl: AbstractControl | any): boolean => {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({}as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  fileChange(event: any) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files![0];
    if(file) {
      this.form.get(this.formItem.name)?.patchValue(file);
      // File
      const reader = new FileReader();
      reader.onload = () => {
        this.fileItem = {name: file.name, file: ''};
      }
      reader.readAsDataURL(file)
    } else {
      console.log('no hay archivo');
      this.form.get(this.formItem.name)?.patchValue(null);
    }
  }

  showPreview(event: any) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files![0];
    this.filePreview = null;
    if(file) {
      this.form.get(this.formItem.name)?.patchValue(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.fileItem = {name: file.name, file: ''};
        this.filePreview = reader.result as string;
      }
      reader.readAsDataURL(file)
    } else {
      console.log('no hay imagen');
      this.form.get(this.formItem.name)?.patchValue(null);
    }
  }

  dateChange(event: Date) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.form.get(this.formItem.name)?.patchValue(date);
    console.log(this.form.get(this.formItem.name)?.value);
  }
}
