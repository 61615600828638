<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>
      
      <div *ngIf="create" class="w-auto max-w-full flex flex-wrap gap-2">
        <button mat-button (click)="formCreate('create')" class="cy__btn btn__primary">
          <i class="fa-light fa-plus"></i> Crear Listado
        </button>
      </div>
    </div>

    <div class="block w-full pt-5">
      <datatable-capyei
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [loadingData]="loadingData"
        [noData]="noData"
        [customTemplate]="customTemplate">
        <!-- TODO: Si se manda "customTemplate" debes añadir un default al switch de lo que se manda -->
        <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
          <ng-container [ngSwitch]="column.id">
            <ng-container *ngSwitchCase="'type_data'">
              <div class="status">{{ typeData(item) }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="'action_buttons'">
              <button-cy (onPress)="clickEvent(i, 'add', row.id, row)" label="Añadir" addClass="btn-action warning" icon="fa-light fa-filter" />
              <button-cy (onPress)="clickEvent(i, 'read', row.id, row)" label="Ver" addClass="btn-action info" icon="fa-light fa-eye" [iconOnly]="true" />
              <button-cy (onPress)="clickEvent(i, 'edit', row.id, row)" label="Editar" addClass="btn-action success" icon="fa-light fa-edit" [iconOnly]="true" />
              <button-cy (onPress)="clickEvent(i, 'remove', row.id, row)" label="Eliminar" addClass="btn-action danger" icon="fa-light fa-trash" [iconOnly]="true" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="two_line-hidden">{{ item ?? '-' }}</span>
            </ng-container>
          </ng-container>
        </ng-template>
      </datatable-capyei>
    </div>
  </div>
</div>