import { AccountPage } from './pages/account/account.page';
import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CleanComponent } from './layout/clean/clean.component';
import { MainComponent } from './layout/main/main.component';
import { NotFoundPage } from './pages/notfound/notfound.page';
import { UserListPage } from './pages/user/list/list.page';
import { UserFormPage } from './pages/user/form/form.page';
import { UserRolePage } from './pages/user/role/role.page';
import { RoleFormPage } from './pages/role/form/form.page';
import { RoleListPage } from './pages/role/list/list.page';
import { AuditsPage } from './pages/audits/audits.page';
import { LeadListPage } from './pages/lead/list/list.page';
import { MailingListPage } from './pages/mailing/list/list.page';
import { SociosListPage } from './pages/socios/list/list.page';
import { SocioFormPage } from './pages/socios/form/form.page';
import { AssignedLeadListPage } from './pages/assigned-lead/list/list.page';
import { AssignedLeadDetailPage } from './pages/assigned-lead/detail/detail.page';
import { ModelListPage } from './pages/model/list/list.page';
import { ModelFormPage } from './pages/model/form/form.page';
import { NotificationsListPage } from './pages/notifications/list/list.page';
import { SalesForceListPage } from './pages/sales-force/list/list.page';
import { ReportListPage } from './pages/report/list/list.page';
import { MetasListPage } from './pages/metas/list/list.page';
import { ClientListPage } from './pages/client/list/list.page';
import { BusinessListPage } from './pages/business-list/list/list.page';
import { BusinessDetailPage } from './pages/business-list/business-detail/detail-business.page';
import { MySalesaListPage } from './pages/my-sales/list/list.page';
import { ClientFilterListPage } from './pages/client/filter/list.page';
import { EmailTemplatesListPage } from './pages/email-templates/list/list.page';
import { BroadcastListPage } from './pages/broadcast/list/list.page';
import { MessageTemplatesListPage } from './pages/message-templates/list/list.page';
import { ActivityTypePage } from './pages/assigned-lead/type/type.page';
import { AccountSecurityPage } from './pages/account-security/account-security.page';
import { ReminderListPage } from './pages/reminder/list/list.page';


export const routes: Routes = [
    {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full',
    },
    {
        path: '',
        component: MainComponent,
        loadChildren: () => [
            {
                path: '',
                redirectTo: 'account',
                pathMatch: 'full',
            },
            {
                path: 'account',
                component: AccountPage
            },
            {
                path: 'account-security',
                component: AccountSecurityPage
            },
            //* Audits
            {
                path: 'audits',
                component: AuditsPage
            },
            //* Users
            {
                path: 'user/:action',
                component: UserFormPage
            },
            {
                path: 'user/role/:id',
                component: UserRolePage,
            },
            {
                path: 'user/:action/:id',
                component: UserFormPage
            },
            {
                path: 'users',
                component: UserListPage
            },
            //* Roles
            {
                path: 'role/:action',
                component: RoleFormPage
            },
            {
                path: 'role/:action/:id',
                component: RoleFormPage
            },
            {
                path: 'roles',
                component: RoleListPage
            },
            //* Clients
            {
                path: 'contacts',
                component: ClientListPage
            },
            {
                path: 'contacts/:node/:returnpage',
                component: ClientListPage
            },
            {
                path: 'contact-lists',
                component: ClientFilterListPage
            },
            //* Leads
            {
                path: 'leads',
                component: LeadListPage
            },
            {
                path: 'leads/:type',
                component: LeadListPage
            },
            //* Assigned Leads
            {
                path: 'assigned-leads',
                component: AssignedLeadListPage
            },
            {
                path: 'assigned-lead/:id',
                component: AssignedLeadDetailPage
            },
            // ACtivity Type
            {
                path: 'activity/:type/:id',
                component: ActivityTypePage
            },
            // ACtivity Type Lead
            {
                path: 'activity/:type/:id/:lead_id',
                component: ActivityTypePage
            },
            //* E-mail - Templates
            {
                path: 'email-templates',
                component: EmailTemplatesListPage
            },
            //* Mainlings
            {
                path: 'mailings',
                component: MailingListPage
            },
            //* Socios
            {
                path: 'socios',
                component: SociosListPage
            },
            {
                path: 'socios/:action/:id',
                component: SocioFormPage
            },
            //* Models
            {
                path: 'model/:model',
                component: ModelListPage
            },
            {
                path: 'model/:model/:action',
                component: ModelFormPage
            },
            {
                path: 'model/:model/:action/:id',
                component: ModelFormPage
            },
            //* notifications
            {
                path: 'notifications',
                component: NotificationsListPage
            },
            //* fuerza de ventas
            {
                path: 'sales-force',
                component: SalesForceListPage
            },
            //* reportes
            {
                path: 'dashboard',
                component: ReportListPage
            },
            {
                path: 'report',
                component: ReportListPage
            },
            //* metas
            {
                path: 'metas',
                component: MetasListPage
            },
            //* Business list
            {
                path: 'business',
                component: BusinessListPage
            },
            {
                path: 'business-detail/:id',
                component: BusinessDetailPage
            },
            //* My sales
            {
                path: 'my-sales',
                component: MySalesaListPage
            },
            //* Difusiones
            {
                path: 'broadcast',
                component: BroadcastListPage
            },
            //* Message templates
            {
                path: 'message-templates',
                component: MessageTemplatesListPage
            },
            //* Reminders
            {
                path: 'reminders',
                component: ReminderListPage
            },

        ],
        canActivate:[AuthGuard]
    },
    {
        path: 'auth',
        component: CleanComponent,
        loadChildren: () => import('./pages/auth/routes'),
    },

    // * Error
    {
        path: '',
        component: CleanComponent,
        children: [
            {
                path: '**',
                redirectTo: 'error/404'
            },
            {
                path: 'error/:error',
                component: NotFoundPage
            }
        ]
    }
];