 <div class="w-full block">
  <div class="w-full block table_responsive p-6">
    <div class="w-full content_btns flex flex-wrap gap-4 justify-between items-start pb-4">
      <h5 class="text-slate-800 font-bold text-lg inline-block relative m-0 pb-1 text-center">
        {{ title }}
      </h5>

      <form [formGroup]="range" (submit)="initializeApp()" class="max-w-full flex flex-wrap items-center gap-4">
        <div class="w-[300px] max-w-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4 items-center gap-2">
  
          <div class="w-full relative input__date type_full border border-[#F1F1F1]">
            <mat-date-range-input [rangePicker]="picker" class="input__date-item !bg-white">
              <input matStartDate readonly [(ngModel)]="start_date" (ngModelChange)="dateChange($event, 'start_date')" [ngModelOptions]="{standalone: true}">
              <input matEndDate readonly [(ngModel)]="end_date" (ngModelChange)="dateChange($event, 'end_date')" [ngModelOptions]="{standalone: true}">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
        </div>
        <button-cy type="submit" addClass="btn__second">
          <i class="fa-light fa-filter"></i> Filtrar
        </button-cy>
        <button-cy (onPress)="clearFilter()" addClass="btn__primary" icon="fa-light fa-rotate-right" label="Limpiar filtros" [iconOnly]="true"></button-cy>
      </form>
    </div>


    <section class="grid-sales">

      <ng-container *ngFor="let item of salesForce">
        <article class="item-sales">
          <h4 class="title-list">{{ item?.title ?? 'Estadistica' }}</h4>
          <ul *ngFor="let subitem of (item?.data ?? [])">
            <li>
              <app-user-custom [img]="subitem['url_img']" [name]="subitem['name']"></app-user-custom>
              
              <div class="content__progress-bar {{ ((subitem['percent'] ?? 0) > 49) && 'percent__white' }}">
                <div class="percent__progress-bar">
                  {{ subitem['percent'] ?? 0 | number:'.0-2' }}%
                </div>
                <mat-progress-bar mode="determinate" value="{{subitem['percent']? subitem['percent'] : 0 }}" class="flex-1 p-green" *ngIf="(subitem['percent'] ?? 0) > 49"></mat-progress-bar>
                <mat-progress-bar mode="determinate" value="{{subitem['percent']? subitem['percent'] : 0 }}" class="flex-1 p-yellow" *ngIf="(subitem['percent'] ?? 0) > 35 && (subitem['percent'] ?? 0) <= 49"></mat-progress-bar>
                <mat-progress-bar mode="determinate" value="{{subitem['percent']? subitem['percent'] : 0 }}" class="flex-1 p-red" *ngIf="(subitem['percent'] ?? 0) <= 35"></mat-progress-bar>
              </div>
  
              <div class="prg-text">
                <span class="{{subitem['status']? '': 'txt-p-gray' }}" >{{ (subitem['today']? subitem['today'] : 0) | number:'.0-2':'es-ES' }}/{{ (subitem['after']? subitem['after'] : 0) | number:'.0-2':'es-ES' }} </span>
                <!-- <ng-container [ngSwitch]="subitem['status']" >
                  <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'" ></i>
                  <i class="fa fa-arrow-up txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'" ></i>
                  <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'" ></i>
                  <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault ></i>
                </ng-container> -->
              </div>
            </li>
          </ul>
        </article>
      </ng-container>

      <article class="item-sales">
        <h4 class="title-list">Medios de comunicaciones</h4>
        <ng-container *ngIf="comunications.length > 0; else elseDataComunications">
          <ul *ngFor="let item of comunications">
            <li>
              <app-user-custom [img]="item['image']" [name]="item['name']"></app-user-custom>
  
              <div class="prg-text !gap-5">
                <div *ngFor="let subitem of item['data']" class="flex items-center gap-2" [title]="subitem['name'] ?? ''">
                  <i *ngIf="subitem['code']" class="{{ subitem['code'] }} !text-[16px]" aria-hidden="true" [style.color]="subitem['color'] ?? '#999999'"></i>
                  <i *ngIf="!subitem['code']" class="fa fa-asterisk !text-[16px]" aria-hidden="true" [style.color]="subitem['color'] ?? '#999999'"></i>
                  <span>{{subitem['quantity'] | number:'.0-2':'es-ES'}}</span>
                </div>
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-template #elseDataComunications>
          <p><small>No se encontraron datos</small></p>
        </ng-template>
      </article>
    </section>
  </div>
</div>