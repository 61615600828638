<div class="w-full block">
  <div class="w-full block table_responsive p-6">
    <div class="w-full content_btns flex flex-wrap gap-4 justify-between items-start pb-4">
      <h5 class="text-slate-800 font-bold text-lg inline-block relative m-0 text-center">
        Reporte de Ventas
      </h5>
      <form [formGroup]="dataForm" (submit)="dataInit()" class="max-w-full flex flex-wrap items-center gap-4">
        <div class="w-[600px] max-w-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4 items-center gap-2">
  
          <div class="w-full relative input__date type_full border border-[#F1F1F1]">
            <mat-date-range-input [rangePicker]="picker" class="input__date-item !bg-white">
              <input matStartDate readonly [(ngModel)]="start_date" (ngModelChange)="dateChange($event, 'start_date')" [ngModelOptions]="{standalone: true}">
              <input matEndDate readonly [(ngModel)]="end_date" (ngModelChange)="dateChange($event, 'end_date')" [ngModelOptions]="{standalone: true}">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
          <mat-select id="user_id" name="user_id" formControlName="user_id" placeholder="Por vendedor" class="custom__mat-select bg__white">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterSellers = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingSellers; else elseLoadingSellers">
              <ng-container *ngIf="xServices.lengthItems(sellers); else elseSellers">
                <mat-option *ngFor="let item of sellers | filter: filterSellers : 'fullname'" [value]="item['id']" >{{ item['fullname'] }}</mat-option>
              </ng-container>
              <ng-template #elseSellers>
                <mat-option [value]="null">No se encontraron vendedores</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingSellers>
              <mat-option [value]="null">Cargando vendedores...</mat-option>
            </ng-template>
          </mat-select>
        </div>
        <button-cy type="submit" addClass="btn__second">
          <i class="fa-light fa-filter"></i> Filtrar
        </button-cy>
        <button-cy (onPress)="clearFilter()" addClass="btn__primary" icon="fa-light fa-rotate-right" label="Limpiar filtros" [iconOnly]="true"></button-cy>
      </form>
    </div>

    <section class="grid-panel">
      <div class="item-panel" *ngFor="let item of dataPanel">
        <p>{{item['name']}}</p>
        <div>
          <span>{{ item['cant'] ?? 0 | number:'.0-2':'es-ES' }}</span>
          <!-- <ng-container [ngSwitch]="item['status']">
            <i *ngSwitchCase="'high'" class="fa fa-arrow-up txt-p-green" aria-hidden="true"></i>
            <i *ngSwitchCase="'half'" class="fa fa-exchange txt-p-yellow" aria-hidden="true"></i>
            <i *ngSwitchCase="'low'" class="fa fa-arrow-down txt-p-red" aria-hidden="true"></i>
            <i *ngSwitchDefault class="fa fa-ban txt-p-gray" aria-hidden="true"></i>
          </ng-container> -->
        </div>
      </div>
    </section>

    <section class="grid-sales">
      <ng-container *ngFor="let item of chartOptionsColum; let i = index;">
        <article class="item-sales">
          <div class="panel-header">
            <h4 class="title-list !m-0">{{ item?.name ?? 'Estadistica' }}</h4>           
            <div class="prg-text--medui">
              <span>{{ item?.total ?? 0 | number:'.0-2':'es-ES' }}</span>
              <!-- <ng-container [ngSwitch]="item['status']">
                <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
              </ng-container> -->
            </div>
          </div>
          <div *ngIf="item?.options">
            <highcharts-chart [Highcharts]="Highcharts" [constructorType]="chartConstructor" [options]="item?.options"
              [(update)]="updateFlag" [oneToOne]="oneToOneFlag" [runOutsideAngular]="runOutsideAngular"
              style="width: 100%; display: block;">
            </highcharts-chart>
          </div>
        </article>  
      </ng-container>

      <ng-container *ngFor="let item of dataList" >
        <article class="layout-card1">
          <div class="item-sales">
            <div class="panel-header">
              <h4 class="title-list !m-0">{{ item?.name ?? 'Datos'}} generados </h4>
              <div class="prg-text--medui">
                <span>{{ item['cant_total'] ?? 0 | number:'.0-2':'es-ES' }}</span>
                <!-- <ng-container [ngSwitch]="item['status']">
                  <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                  <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                  <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                  <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
                </ng-container> -->
              </div>
            </div>
            <ul class="ls--pall" *ngFor="let subItem of item['list_item']">
              <li>
                <div class="etq-dashboard"><span>{{subItem['name']}}</span></div>
                <div class="prg-text">
                  <span>{{subItem['cant_percent']}}</span>
                  <!-- <ng-container [ngSwitch]="subItem['status']">
                    <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                    <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                    <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                    <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
                  </ng-container> -->
                </div>
              </li>
            </ul>
          </div>
          <div class="grid-2" >
            <div class="item-grid2 item-panel !py-3 !px-4" *ngFor="let itemCard of (item?.list_card ?? [])">
              <h4 class="title-list">{{ item['name'] }}({{ itemCard['name_card'] }})</h4>
              <div>
                <span class="leading-[normal]">{{ itemCard?.subtotal ?? 0 | number:'.0-2':'es-ES' }}</span>
                <!-- <ng-container [ngSwitch]="itemCard['status']">
                  <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                  <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                  <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                  <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
                </ng-container> -->
              </div>
            </div>
          </div>
        </article>
      </ng-container>

      <ng-container *ngFor="let item of chartOptionsCake; let i = index;">
        <article class="layout-card1 bg-grid">
          <div>
            <h4 class="title-list">{{ item?.name ?? 'Estadistica' }}</h4>
            <div class="prg-text prg-text--big">
              <span>{{ item?.total ?? 0 | number:'.0-2':'es-ES' }}</span>
              <!-- <ng-container [ngSwitch]="item['status']">
                <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
              </ng-container> -->
            </div>
            <ul class="lst-paralela" *ngFor="let subItem of item.items">
              <li>
                <div class="etq-dashboard"><span>{{subItem['name']}}</span></div>
                <div class="prg-text">
                  <span>{{subItem['value']}}%</span>
                  <!-- <ng-container [ngSwitch]="subItem['status']">
                    <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                    <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                    <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                    <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
                  </ng-container> -->
                </div>
              </li>
            </ul>
          </div>
          <div class="grid-2">
            <highcharts-chart [Highcharts]="Highcharts" [constructorType]="chartConstructor" [options]="item.options"
              [(update)]="updateFlag" [oneToOne]="oneToOneFlag" [runOutsideAngular]="runOutsideAngular"
              style="width: 100%; display: block;">
            </highcharts-chart>
          </div>
        </article>
      </ng-container>
    </section>
  </div>
</div>