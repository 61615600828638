<div class="pt-5 lg:px-5 px-0 pb-[5rem] w-full container mx-auto">
  <div class="lg:grid lg:grid-cols-[minmax(0,50%)_minmax(0,50%)] flex md:flex-col flex-wrap gap-4 w-full">
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <form class="account-settings-form grid grid-cols-1" (ngSubmit)="submitFormPassword()" #formPassword="ngForm">
        <h5 class="text-[color:var(--primary-color)] font-semibold text-lg inline-block relative m-0 mb-4 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">Cambiar contraseña</h5>
        <p class="small-paragraph-spacing text-sm mb-4">Puedes cambiar la contraseña de tu cuenta aqui ( <strong>Mínimo 6 carácteres</strong> ).</p>
        <div class="w-full mt-2 grid md:grid-cols-[minmax(0,50%)_minmax(0,50%)] grid-cols-[minmax(0,100%)] gap-4">
          <div class="w-full col-span-2">
            <label for="current_password" class="block w-full text-[color:var(--second-color)] text-sm font-semibold mb-2">Contraseña Actual:</label>
            <input type="password" name="verify_password" id="current_password" [(ngModel)]="fieldsCredentialPassword.verify_password" placeholder="xxxxx" class="block m-0 w-full border-solid border-[1px] border-gray-300 rounded py-2 px-4 text-base">
          </div>

          <div class="w-full">
            <label for="new-password" class="block w-full text-[color:var(--second-color)] text-sm font-semibold mb-2">Nueva Contraseña:</label>
            <input type="password" name="password" [(ngModel)]="fieldsCredentialPassword.password" id="new-password" placeholder="xxxxx" class="block m-0 w-full border-solid border-[1px] border-gray-300 rounded py-2 px-4 text-base">
          </div>
          
          <div class="w-full">
            <label for="confirm-new-password" class="block w-full text-[color:var(--second-color)] text-sm font-semibold mb-2">Confirmar contraseña:</label>
            <input type="password" name="password_confirmation" [(ngModel)]="fieldsCredentialPassword.password_confirmation" id="confirm-new-password" placeholder="xxxxx" class="block m-0 w-full border-solid border-[1px] border-gray-300 rounded py-2 px-4 text-base">
          </div>
        </div>
        
        <hr class="block w-full my-4">
        <div class="text-center">
          <button type="submit" [disabled]="!formPassword.form.valid" class="font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white">
            Guardar los Cambios
          </button>
        </div>
      </form>
    </div>
    
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <form class="account-settings-form grid grid-cols-1" (ngSubmit)="submitFormEmailPhone()" #formEmailPhone="ngForm">
        <h5 class="text-[color:var(--primary-color)] font-semibold text-lg inline-block relative m-0 mb-4 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">Información de Seguridad</h5>
        <p class="small-paragraph-spacing text-sm mb-4">Al saber tus datos personales, podemos hacer de tu experiencia mas cálida y cercana.</p>
        <div class="w-full mt-2 grid grid-cols-[minmax(0,100%)] gap-4">
          
          <div class="full mt-2 grid grid-cols-[minmax(0,1fr)_minmax(0,min-content)] gap-4">
            <div class="w-full self-center">
              <label for="email" class="block w-full text-[color:var(--second-color)] text-sm font-semibold mb-2">Correo Electrónico:</label>
              <input type="email" name="email" [(ngModel)]="fieldsCredentialEmailPhone.email" id="email" class="block m-0 w-full border-solid border-[1px] border-gray-300 rounded py-2 px-4 text-base">
            </div>
            <!-- <div class="w-auto max-w-full block self-end">
              <button class="block w-[42px] h-[42px] rounded-full flex items-center justify-center bg-[color:var(--primary-color)] hover:bg-[color:var(--third-color)] text-white cursor-pointer text-lg">
                <i class="fa-light fa-pen-to-square"></i>
              </button>
            </div> -->
          </div>

          <div class="full mt-2 grid grid-cols-[minmax(0,1fr)_minmax(0,min-content)] gap-4">
            <div class="w-full self-center">
              <label for="cellphone" class="block w-full text-[color:var(--second-color)] text-sm font-semibold mb-2">Celular o Teléfono:</label>
              <input type="number" name="cellphone" [(ngModel)]="fieldsCredentialEmailPhone.cellphone" id="cellphone" class="block m-0 w-full border-solid border-[1px] border-gray-300 rounded py-2 px-4 text-base">
            </div>
            <!-- <div class="w-auto max-w-full block self-end">
              <button type="submit" class="block w-[42px] h-[42px] rounded-full flex items-center justify-center bg-[color:var(--primary-color)] hover:bg-[color:var(--third-color)] text-white cursor-pointer text-lg">
                <i class="fa-light fa-pen-to-square"></i>
              </button>
            </div> -->
          </div>

          <hr class="block w-full my-4">
          <div class="text-center">
            <button type="submit" [disabled]="!formEmailPhone.form.valid" class="font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white">
              Guardar los Cambios
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>