import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';


@Component({
  selector: 'app-audits',
  templateUrl: './audits.page.html',
  styleUrls: ['./audits.page.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule, DatatableComponent]
})
export class AuditsPage implements OnInit {

  title = 'Auditoria';

  pagination = null;
  data:any          = [];
  model = 'activity';

  dataForm = this.fb.group({
    node:                 new FormControl(''),
    codItem:              new FormControl(''),
  });

  nodes = [
    { value: 'city', name: 'Ciudades' },
    { value: 'branch', name: 'Sucursales' },
    { value: 'box', name: 'Cajas' },
    { value: 'warehouse', name: 'Almacenes' },
    { value: 'geofence', name: 'Rutas' },
    { value: 'zone', name: 'Zonas' },
    { value: 'district', name: 'Distritos' },
    { value: 'location', name: 'Ubicaciones' },
    { value: 'organization', name: 'Organizaciones' },
    { value: 'client', name: 'Personas' },
    // { value: 'user', name: 'Usuarios' },
    { value: 'role', name: 'Roles' },
    { value: 'subroute', name: 'Sub-rutas' },
    { value: 'user-type', name: 'Tipos de Usuario' },
    { value: 'warehouse-type', name: 'Tipos de Vendedor' },
    { value: 'client-type', name: 'Tipos de Persona' },
    { value: 'organization-type', name: 'Tipos de Organización' },
    { value: 'location-type', name: 'Tipos de Ubicación' },
    { value: 'warehouse-type', name: 'Tipos de Almacen' },
    { value: 'warehouse-state', name: 'Estados de Almacen' },
    { value: 'frequency-visit', name: 'Frecuencias de visita' },
    // { value: 'activity', name: 'Actividades' },
  ]

  isAdmin:boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'tableName',label:'Nodo',hideOrder:1},
    {id:'user_name',label:'Usuario',hideOrder:2},
    {id:'action',label:'Acción',hideOrder:3},
    {id:'date',label:'Fecha creación',hideOrder:4},
    {id:'updatedAt_format',label:'Fecha edición',hideOrder:5},
    {id:'deletedAt_format',label:'Fecha Eliminación',hideOrder:6},
    {id:'id',label:'ID',hideOrder:0,width:100},
    {id:'action_buttons',label:'Acciones',hideOrder:1},
  ];

  actions:any[] = [
    { id: 'read', label: 'Ver Actividad', icon: 'fa-light fa-eye', class: 'btn-action info', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id) },
  ];

  visibilityBtns:any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClientProvider,
    private load: LoadingService,

    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private authService: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
  ) {
    this.route.queryParams.subscribe(data => {
      // console.log(data);
      this.loadData();
      if(data['node']||data['codItem']) {
        this.dataForm.get('node')?.patchValue(data['node'] || '');
        this.dataForm.get('codItem')?.patchValue(data['codItem'] || '');
        setTimeout(() => {
          this.submit(this.dataForm.value);
        }, 1);
      } else {
        this.initializeApp();
      }
    })
  }

  loadData() {
    this.authService.getUserByToken().subscribe((res: any) => {
      this.isAdmin = res.isAdmin;
      if(!this.isAdmin) {
        this.router.navigateByUrl('/');
        this.toast.error('Error al ingresar a la vista', 'No cuenta con permisos para ingresar a esta vista, solicite permisos e intente nuevamente.', 4000);
      }
    });
  }

  clickEvent(fila: number, action: string, id: any) {
    // console.log(fila, action, id);
    if(action == 'read') {
      this.detailActivity(id);
    }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    this.init();
  }

  init() {
    this.load.loadingShowAlt();
    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}/1`)).subscribe(result => {
      // console.log(result);
      if(result['status']) {
        this.pagination = result['items'];
        this.data = result['items']['data'];
        let rows: any[] = [];

        console.log(result['items']['data']);
        result['items']['data'].forEach((element:any,index:number)=> {
          element['index'] = index +1;
          let items = {id: element['codItem'] /*element['pkyActivity']*/, user_name: element['user']?.name, date: this.xServices.formatDateTime(element['createdAt']), updatedAt_format: this.xServices.formatDateTime(element['updatedAt']), deletedAt_format: this.xServices.formatDateTime(element['deletedAt']), ...element};
          rows.push(items);
        });
        this.dataSource.data = rows;

        if(result['actions']) {
          this.showActionsButtons(result);
        }
        this.load.dismissAlt();
      } else {
        this.load.dismissAlt();
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        // this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismissAlt();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      // console.log(url);
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          // console.log(result['items']);
          this.pagination = result['items'];
          this.data = result['items']['data'];

          let rows: any[] = [];

          result['items']['data'].forEach((element:any,index:number)=> {
            element['index'] = index +1;
            let items = {id: element['codItem'] /*element['pkyActivity']*/, user_name: element['user']?.name, date: this.xServices.formatDateTime(element['createdAt']), updatedAt_format: this.xServices.formatDateTime(element['updatedAt']), deletedAt_format: this.xServices.formatDateTime(element['deletedAt']), ...element};
            rows.push(items);
          });
          this.dataSource.data = rows;

          if(result['actions']) {
            this.showActionsButtons(result);
          }
          this.load.dismiss();
        } else {
          this.load.dismiss();
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']) {
            if(result['errors']) {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
            }
          } else {
            if(result['errors']) {
              this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
            }
          }
        }
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  errorPage() {
    this.router.navigateByUrl('/error/404');
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Inactivar Item',
      text: '¿Desea inactivar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Inactivo', result['message'], 4000);
            } else {
              this.toast.success('Item Inactivo', 'Se inactivo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al inactivar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`post-from-organization/${this.model}/active`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  detailActivity(id: any) {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/read`), { id: id }).subscribe(result => {
      // console.log(result);
      if(result['status']) {
        this.detailActivityItem(result['item']);
      } else {
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
          }
        }
      }
      this.load.dismiss();
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener la actividad', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

  detailActivityItem(item: any) {
    console.log(item);

    Swal.fire({
      title: `Actividad en Nodo "${item['tableName']}"`,
      html: `<p><b>Usuario:</b> ${item['user']?.name || 'Sin usuario'}</p><p><b>Acción del nodo:</b> "${item['action']}"</p><hr>${item['message']}<hr><p><b>Creación:</b> ${this.xServices.formatDateTime(item['createdAt'])}<br><b>Última edición:</b> ${this.xServices.formatDateTime(item['updatedAt'])}</p>`,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      showConfirmButton: false
    })
  }

  submit(data?: any) {
    if(data){ console.log(data) };
    // console.log(this.dataForm.value);
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    this.load.loadingShow();
    let queryParams = new HttpParams({ fromObject: (data || this.dataForm.value) });
    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`), null, queryParams).subscribe(result => {
      // console.log(result);
      if(result['status']) {
        this.pagination = result['items'];
        this.data = result['items']['data'];

        let rows: any[] = [];
        result['items']['data'].forEach((element:any,index:number)=> {
          element['index'] = index +1;
          let items = {id: element['codItem'] /*element['pkyActivity']*/, user_name: element['user']?.name, date: this.xServices.formatDateTime(element['createdAt']), updatedAt_format: this.xServices.formatDateTime(element['updatedAt']), deletedAt_format: this.xServices.formatDateTime(element['deletedAt']), ...element};
          rows.push(items);
        });
        this.dataSource.data = rows;

        if(result['actions']) {
          this.showActionsButtons(result);
        }
        this.load.dismiss();
      } else {
        this.load.dismiss();
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener las actividades', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = [
      { id: 'read', active: result['actions']['read'] },
    ];
  }
}
