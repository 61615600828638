import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsProvider } from 'src/app/services/settings';
import { XtrasService } from 'src/app/services/xtras.service';
import { UserModel } from 'src/app/services/user-model';

import Swal from 'sweetalert2';
import { Observable, Subscription } from 'rxjs';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class AccountPage implements OnInit, OnDestroy {

  user$: Observable<UserType>;

  private unsubscribe: Subscription[] = [];

  title = 'Información Personal';
  
  item: any = null;
  fieldsCredential = {
    name: null,
  }

  imagePreview: any = null;
  fieldsCredentialImage: FormGroup;

  constructor(
    private router: Router,
    private xServices: XtrasService,
    private metaTitle: Title,
    public userModel: UserModel,
    private http: HttpClientProvider,
    private toast: NotificationService,
    private load: LoadingService,
    private alert: AlertService,
    private authService: AuthService,
    fb: FormBuilder,
  ) {
    this.user$ = this.authService.user$;
    this.initializeApp();

    this.fieldsCredentialImage = fb.group({
      image: [null],
    });
  }

  initializeApp() {
    this.http.getRequest(SettingsProvider.getUrl(`my-account`)).subscribe(result => {
      if(result['status']) {
        this.item = result['user'];
        this.fieldsCredential = {
          name: this.item['name'],
        }
      } else {
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
      }
    }, error => {
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      this.router.navigateByUrl('/');
      // this.errorPage();
    });
  }

  showPreview(event: any) {
    const file = event.target.files[0];
    this.fieldsCredentialImage.get('image')?.patchValue(file);
    this.fieldsCredentialImage.get('image')?.updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  submitForm() {
    this.load.loadingShow();
    const dataUserSubscr =  this.http.postRequest(SettingsProvider.getUrl(`edit-my-account`), this.fieldsCredential).subscribe(result => {
      if(result['status']) {
        this.item = result['user'];
        this.fieldsCredential = {
          name: this.item['name'],
        }
        this.authService.getUserByToken().subscribe();
        this.load.dismiss();
        if(result['message']){
          this.toast.success('Información General Actualizada', result['message'], 4000);
        } else {
          this.toast.success('Información General Actualizada', 'La información general fue actualizada exitosamente', 4000);
        }
      } else {
        this.load.dismiss();
        if(result['errors']) {
          this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
        } else {
          this.toast.error('Error al actualizar la información', 'No se pudo actualizar los datos, intente nuevamente.', 4000);
        }
      }
    }, error => {
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      this.router.navigateByUrl('/');
      // this.errorPage();
    });

    this.unsubscribe.push(dataUserSubscr);
  }

  submitFormImage() {
    // console.log(this.fieldsCredentialImage.value);
    let formData = new FormData();
    if(this.fieldsCredentialImage.value.image == null) {
      formData.append('image', '');
    } else {
      formData.append('image', this.fieldsCredentialImage.value.image);
    }
    // console.log(formData);
    
    this.load.loadingShow();
    const dataImageSubscr = this.http.postRequest(SettingsProvider.getUrl(`edit-my-profile-image`), formData).subscribe(result => {
      if(result['status']) {
        this.item = result['user'];
        this.authService.getUserByToken().subscribe();
        this.imagePreview = null;
        this.load.dismiss();
        if(result['message']){
          this.toast.success('Imagen Actualizada', result['message'], 4000);
        } else {
          this.toast.success('Imagen Actualizada', 'La foto de perfil fue actualizado exitosamente', 4000);
        }
      } else {
        this.load.dismiss();
        if(result['errors']) {
          this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
        } else {
          this.toast.error('Error al actualizar la información', 'No se pudo actualizar los datos, intente nuevamente.', 4000);
        }
      }
    }, error => {
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      this.router.navigateByUrl('/');
      // this.errorPage();
    });

    this.unsubscribe.push(dataImageSubscr);
  }

  errorAlert(errors?: any) {
    errors = errors ? errors : 'No se pudo actualizar los datos, intente nuevamente.';
    Swal.fire({
      title: 'Error al actualizar la información',
      html: errors,
      icon: 'error',
      // showCancelButton: false,
      confirmButtonText: 'Entendido',
    });
  }
  
  ngAfterViewInit(){
    
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
    $(document).ready(function(){
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
