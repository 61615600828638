import { Injectable } from '@angular/core';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsProvider {

  static api_url = environment.apiUrl;

  private static endpoints = {
    login: 'api/login',
    register: 'api/register',
    logout: 'api/logout',
  };



  constructor() {}

  static authRegister(): string{
    return this.api_url + this.endpoints.register;
  }

  static authLogin(): string{
    return this.api_url + this.endpoints.login;
  }

  static authLogout(): string{
    return this.api_url + this.endpoints.logout;
  }

  static getUrl(url:string): string{
    return this.api_url + 'api/' + url;
  }

  static url(url:string): string{
    return this.api_url + url;
  }

  static getCustomUrl(url:string): string{
    return url;
  }

}
