import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { ModalAddUsersComponent } from 'src/app/components/modal-addusers/modal-addusers.component';
import { CommonModule } from '@angular/common';

declare var $: any;

@Component({
  selector: 'user-role',
  templateUrl: './role.page.html',
  styleUrls: ['./role.page.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, RouterModule]
})
export class UserRolePage implements OnInit{

  // @ViewChild('dTable', { static: false }) dataTable: any;

  title = 'Permisos';

  item:any = null;
  items: any[] | null = [];
  id = null;

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,
    private metaTitle: Title,
    private userModel: UserModel,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toast: NotificationService,
    private xServices: XtrasService
  ) {
    this.route.params.subscribe((res) => {
      console.log(res);
      this.id = res['id'];
      this.initializeApp();
    });
  }

  initializeApp() {
    console.log(this.id);
    this.initScripts();
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl('user-roles/'+ this.id)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.item = result['findUser'] || null;
        this.items = result['items'] || null;
        this.load.dismiss();
      } else {
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      // this.errorPage();
    });
  }

  initScripts(){
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    // $('.dataTable_custom').DataTable().destroy();
    console.log('cerrado');
  }

  openDialog(type: any): void {
    let users = [
      {
        id: 1,
        name: 'Eduardo'
      },
      {
        id: 1,
        name: 'Juan Cap'
      },
      {
        id: 1,
        name: 'Cosme Fernando'
      },
    ]
    const dialogRef = this.dialog.open(ModalAddUsersComponent, {
      width: '550px',
      data: {users: users, type: type},
      disableClose: false,
      // panelClass: 'content_modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  toggleChange(items: any, i: any , codUser: any, codRole: any) {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl('user-update-role'), { codUser : codUser, codRole : codRole }).subscribe(result => {
      console.log(result);
      if(result['status']) {
        // if(result['checked']) {
          items[i]['checked'] = result['checked'];
        // }
        this.load.dismiss();
      } else {
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      // this.errorPage();
    });
  }
}
