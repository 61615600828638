import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router, RouterModule } from '@angular/router';
import { UserModel } from 'src/app/services/user-model';

declare var $: any;

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.page.html',
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class NotFoundPage implements OnInit {

  error_code = '404';
  error_detail = 'Página no encontrada';
  error_description = 'Al parecer, la página que estaba buscando no existe.';

  constructor(
    private router: Router,
    private metaTitle: Title,
    public userModel: UserModel,
    private params: ActivatedRoute
  ) {
    console.log(this.params.snapshot.params['error']);
    this.error_code = this.params.snapshot.params['error'];
  }
  
  ngAfterViewInit(){
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.error_detail}`);
    $(document).ready(function(){
      console.log('hola mundo')
      // alert('JQuery is working!!');
    });
  }

  ngOnInit() {
    // $(document).ready(function(){
    //   console.log('hola mundo')
    //   alert('JQuery is working!!');
    // });
  }

  goToLogin() {
    this.router.navigateByUrl('/auth/login');
  }
}
