<div class="w-full tl:h-full pb-3 block tl:overflow-hidden">
  <div class="w-full tl:h-full bg-white rounded-xl drop-shadow p-6 tl:px-8 px-6 tl:overflow-hidden">

    <div class="w-full tl:h-full grid grid-rows-[minmax(0,max-content)_minmax(0,1fr)] tl:overflow-hidden">
      <div class="w-full border-b">
        <div class="content_btns flex flex-wrap gap-4 justify-between items-center w-full pb-4">
          <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
            <div class="text-sm text-secondary border rounded-md px-2 py-1 font-medium">Historial de acciones y registro de actividades</div>
          </div>
          
          <div class="w-auto max-w-full flex flex-wrap gap-2 items-center">
            <div class="flex items-center gap-2">
              <label for="type" class="text-secondary block text-sm font-medium m-0">
                Estado:
              </label>
              <input type="text" name="type" id="type" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <button mat-button (click)="formCreate()" class="cy__btn btn__primary">
              <i class="fa-light fa-plus"></i> Crear tarea
            </button>
            <button mat-button (click)="registerActivity()" class="cy__btn btn__second">
              <i class="fa-light fa-plus"></i> Crear nota
            </button>
          </div>
        </div>
      </div>
  
      <div class="block w-full tl:h-full tl:overflow-hidden">
        <div class="w-full tl:h-full pt-5">
  
          <div class="w-full tl:h-full grid grid-cols-1 tl:grid-cols-[minmax(0,380px)_minmax(0,1px)_minmax(0,1fr)] tl:grid-rows-1 tl:overflow-hidden">
            <div class="w-full tl:h-full tl:overflow-hidden">
              <form [formGroup]="dataForm" class="w-full tl:max-h-full tl:overflow-auto p-4">
                <h5 class="text-[#101828] font-semibold">Detalles del Lead</h5>
                <br>
                <div class="flex flex-col gap-4">
                  <div class="w-full">
                    <label for="fullname" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Nombre completo</span>:</label>
                    <input type="fullname" name="fullname" formControlName="fullname" id="fullname" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                  </div>
                  <div class="w-full">
                    <label for="ci_number" class="text-secondary block w-full text-sm font-medium mb-2">C.I.:</label>
                    <input type="text" name="ci_number" formControlName="ci_number" id="ci_number" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                  </div>
                  <div class="w-full">
                    <label for="email" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">E-mail</span>:</label>
                    <input type="email" name="email" formControlName="email" id="email" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                  </div>
                  <div class="w-full">
                    <label for="cellphone" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Celular</span>:</label>
                    <input type="phone" name="cellphone" maxlength="8" formControlName="cellphone" id="cellphone" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                  </div>
                  <div class="w-full">
                    <label for="source" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Fuente</span>:</label>
                    <input type="text" name="source" formControlName="source" id="source" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                  </div>
                  <div class="w-full">
                    <label for="status" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Estado</span>:</label>
                    <input type="text" name="status" formControlName="status" id="status" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                  </div>
                </div>
              </form>
            </div>
    
            <div class="w-full h-[1px] tl:h-[90%] bg-[#D0D5DD] self-center"></div>
    
            <div class="w-full tl:h-full tl:overflow-hidden">
              <div class="w-full tl:h-full tl:overflow-hidden tl:grid tl:grid-rows-[minmax(0,max-content)_minmax(0,1fr)]">
                <div class="p-4 flex flex-wrap justify-between items-center gap-2">
                  <h5 class="text-[#101828] font-semibold m-0">Historial de Leads</h5>

                  <div class="w-auto max-w-full flex flex-wrap gap-2 items-center">
                    <a mat-button class="cy__btn btn__primary" href="tel:59177777777" target="_blank">
                      <i class="fa-light fa-phone"></i> Llamar por Teléfono
                    </a>
                    <a mat-button class="cy__btn btn__second" href="https://api.whatsapp.com/send?phone=59177777777" target="_blank">
                      <i class="fa-light fa-phone"></i> Llamar por Whatsapp
                    </a>
                    <a mat-button class="cy__btn btn__primary" href="mailto:edumejia30@gmail.com" target="_blank">
                      <i class="fa-light fa-phone"></i> Enviar Correo
                    </a>
                  </div>
                </div>
                <div class="w-full tl:h-full tl:overflow-hidden">
                  <div class="block w-full tl:max-h-full tl:overflow-auto p-4">
                    <!-- #FEFFE9 -->
                    <div class="bg-[#F2F4F7] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">15 septiembre 2023 | 15:30</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Juan Solunes</span> ha cambiado el estado del lead de <span class="text-[color:var(--second-color)] font-semibold">pendiente a convertido</span>
                      </p>
                    </div>
      
                    <div class="bg-[#FEFFE9] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">12 julio 2023 | 08:23</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        Se ha detectado un registro de nuevo socio con datos coicidentes al Lead
                      </p>
                      <br>
                      <div class="flex flex-wrap tl:justify-end gap-2">
                        <button mat-button (click)="formCreate()" class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Ver registro</span>
                        </button>
                        <!-- <button mat-button (click)="formCreate()" class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Convertir lead</span>
                        </button> -->
                      </div>
                    </div>
      
                    <div class="bg-[#F2F4F7] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">12 junio 2023 | 08:30</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Juan Solunes</span> ha creado una nota: <span class="text-[color:var(--second-color)] font-semibold">Llamada para crear socio celeste</span>
                      </p>
                    </div>
      
                    <div class="bg-[#F2F4F7] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">11 junio 2023 | 15:30</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Juan Solunes</span> ha cambiado el estado del lead de <span class="text-[color:var(--second-color)] font-semibold">nuevo a pendiente</span>
                      </p>
                    </div>
      
                    <div class="bg-[#FEFFE9] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">10 julio 2023 | 08:23</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Juan Solunes</span> ha enviado un <span class="text-[color:var(--second-color)] font-semibold">Email</span> a <span class="text-[color:var(--second-color)] font-semibold">Eduardo Mejia Silva</span>
                        <br>
                        <span class="text-[color:var(--second-color)] font-semibold">Mensaje:</span> Buenos días Sr Eduardo, tenemos preparado el registro de su suscripción a Socio Celeste, no encantaría recibir su confirmación para continar, Saludos.
                      </p>
                      <br>
                      <div class="flex flex-wrap tl:justify-end gap-2">
                        <button mat-button (click)="formCreate()" class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Ver mail</span>
                        </button>
                        <!-- <button mat-button (click)="formCreate()" class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Convertir lead</span>
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>