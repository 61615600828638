<div class="bg__sidebar_left lg:!hidden hidden fixed top-0 left-0 w-full h-full z-10 bg-[rgba(0,0,0,0.5)]"></div>

<div class="sidebar_left lg:drop-shadow-md block h-full overflow-hidden lg:bg-transparent bg-white drop_shadow lg:relative lg:top-none lg:left-none fixed top-0 left-0 transition-all duration-300 ease-in-out lg:z-[4] z-20 lg:w-full w-[300px] lg:-translate-x-[0] -translate-x-[100%]">
	<div class="w-full block h-full lg:bg-[#f7f7f7]">
		<div class="w-full h-full overflow-hidden lg:bg-white pb-6">
			<div class="content__scroll-sidebar max-h-full h-full w-full overflow-x-hidden overflow-y-auto" data-scrollbar>
				<div class="w-[150px] my-5 max-w-full block mx-auto" routerDirection="root" href="/">
					<!-- <h3 class="font__title text-2xl text-gray-900 font-bold">{{ environment.title }}</h3> -->
					<img src="assets/img/logo.png" [alt]="environment.title" class="mx-auto block py-4">
				</div>
				<ul class="sidebar__menu">
					<ng-template #elementMenu let-item>
						<li class="sidebar__menu-item">
							<div #itemElementMenu class="sidebar__menu-item-content">
								<div class="sidebar__menu-item-content-title">
									<ng-container *ngIf="item.url; else elseNoUrl">
										<ng-container *ngIf="item.external; else elseExternalUrl">
											<a [href]="item.url" [target]="item.target">
												<i *ngIf="item.icon" class="{{ item.icon }}"></i><span class="text-two-hidden">{{ item.title }}</span>
											</a>
											<button *ngIf="item.dropdown ?? false" class="sidebar__menu-item-dropdown" (click)="itemElementMenu.classList.toggle('active');toggleMenu(item)">
												<i class="fal fa-caret-down"></i>
											</button>
										</ng-container>
										<ng-template #elseExternalUrl>
											<a routerLink="{{ item.url }}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target">
												<i *ngIf="item.icon" class="{{ item.icon }}"></i><span class="text-two-hidden">{{ item.title }}</span>
											</a>
											<button *ngIf="item.dropdown ?? false" class="sidebar__menu-item-dropdown" (click)="itemElementMenu.classList.toggle('active');toggleMenu(item)">
												<i class="fal fa-caret-down"></i>
											</button>
										</ng-template>
									</ng-container>
									<ng-template #elseNoUrl>
										<h5 (click)="itemElementMenu.classList.toggle('active');toggleMenu(item)">
											<i *ngIf="item.icon" class="{{ item.icon }}"></i> <span class="text-two-hidden">{{ item.title }}</span>
										</h5>
										<button *ngIf="item.dropdown ?? false" class="sidebar__menu-item-dropdown" (click)="itemElementMenu.classList.toggle('active');toggleMenu(item)">
											<i class="fal fa-caret-down"></i>
										</button>
									</ng-template>
								</div>

								<ng-container *ngIf="xServices.lengthItems(item.submenu)">
									<ng-template #subMenu>
										<ul>
											<ng-container *ngFor="let subItemMenu of item.submenu">
												<ng-container *ngTemplateOutlet="elementMenu; context: { $implicit: subItemMenu }"></ng-container>
											</ng-container>
										</ul>
									</ng-template>
									<ng-container *ngIf="item.dropdown; else elseDropdown">
										<div [@slideToggle]="(item.expandend_submenu ?? false) ? 'expanded' : 'collapsed'" class="sidebar__menu-submenu">
											<ng-container [ngTemplateOutlet]="subMenu"></ng-container>
										</div>
									</ng-container>
									<ng-template #elseDropdown>
										<div class="sidebar__menu-submenu">
											<ng-container [ngTemplateOutlet]="subMenu"></ng-container>
										</div>
									</ng-template>
								</ng-container>
							</div>
						</li>
					</ng-template>

					<ng-container *ngFor="let itemMenu of menu">
						<ng-container *ngTemplateOutlet="elementMenu; context: { $implicit: itemMenu }"></ng-container>
					</ng-container>
				</ul>
			</div>
		</div>
	</div>
</div>
