<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        DISTRIBUCIÓN DE LEADS
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <div class="flex flex-wrap justify-between items-end gap-4">
                <div class="w-[200px]">
                    <label for="channel_id" class="text-secondary block w-full text-sm font-medium mb-2">Fuente:</label>
                    <mat-select id="channel_id" name="channel_id" formControlName="channel_id" placeholder="Seleccione una opción" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterChannels = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingChannels; else elseLoadingChannels">
                            <ng-container *ngIf="!noDataChannels; else elseChannels">
                                <mat-option *ngFor="let item of channels | filter: filterChannels : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                            </ng-container>
                            <ng-template #elseChannels>
                                <mat-option [value]="null">No se encontraron fuentes</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingChannels>
                            <mat-option [value]="null">Cargando fuentes...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
                <button-cy type="button" (onPress)="submitForm(1)" [disabled]="dataForm.invalid" addClass="btn__primary">Asignar automáticamente</button-cy>
            </div>
            <hr>
            <h5 class="text-secondary block w-full text-sm font-medium mb-0">Equipo Comercial:</h5>
            <div class="content__users">
                <ng-container *ngIf="!loadingSellers; else elseSellersLoading">
                    <ng-container *ngIf="!noDataSellers; else elseSellers">
                        <ng-container *ngFor="let item of sellers">
                            <div class="user-custom select-none" [ngClass]="existSeller(item.id) ? 'active' : ''" (click)="checkItem(item.id)">
                                <div class="img-custom">
                                    <img [src]="item['image']" alt="Eduardo Mejia" onerror="this.onerror=null;this.src='assets/img/user.png';">
                                </div>
                                <div class="info-custom">
                                    <h5>{{ xServices.getFullName(item['name'], item['last_name'], item['mother_last_name']) }}</h5>
                                    <p>Equipo Comercial</p>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #elseSellers>
                        <p>No se encontraron vendedores</p>
                    </ng-template>
                </ng-container>
                <ng-template #elseSellersLoading>
                    <p>Cargando vendedores...</p>
                </ng-template>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <button-cy type="button" [disabled]="dataForm.invalid" (onPress)="submitForm()" addClass="btn__second">
                <i class="fa-light fa-check"></i> Distribuir Leads
            </button-cy>
        </div>
    </div>
</div>
