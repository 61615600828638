import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsProvider } from 'src/app/services/settings';
import { UserModel } from 'src/app/services/user-model';

import { FormGroup, FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'role-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MatSelectModule],
})
export class RoleFormPage implements OnInit {

  title = '';
  share = 'Guardar';
  action = '';
  show: boolean = true;

  dataForm = this.fb.group({
    displayName:      new FormControl(null, [Validators.required]),
    active:           new FormControl(null, [Validators.required]),
    permissions:      new FormControl(null as any, [Validators.required]),
  });
  form: FormGroup;
  permissionsList: any[] = [];
  createdAt      = null;
  updatedAt      = null;
  id              = null;

  types = [
    { value: 'general', name: 'General' },
    { value: 'organization', name: 'Organización' },
    { value: 'active', name: 'Activo' },
  ];

  private emitLoadData: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  get getLoadDataObservable() {
    return this.emitLoadData.asObservable();
  }

  set setLoadDataObservableData(data: boolean) {
    this.emitLoadData.next(data);
  }

  type = null;

  organization_id = null;

  permissions:any[] = [];
  permissionsCheck: any[] = [];

  actionsListRW = { actions: ['C', 'R', 'W', 'D'] };


  constructor(
    private router: Router,
    private metaTitle: Title,
    private userModel: UserModel,
    private route: ActivatedRoute,
    private toast: NotificationService,
    private http: HttpClientProvider,
    private load: LoadingService,
    public xServices: XtrasService,
    private fb: FormBuilder,
    private alert: AlertService
  ) {

    this.form = this.fb.group({});
    this.route.params.subscribe((res) => {
      this.loadData();

      // console.log(res);

      this.action = res['action'];
      if(res['action'] == 'edit'&&res['id']||res['action'] == 'read'&&res['id']){
        this.id = res['id'];
        this.getLoadDataObservable.subscribe(data => {
          if(data) {
            console.log('verdadero');
            this.initializeApp();
          } else {
            console.log('falso');
          }
        })
      }
      if(res['action'] == 'edit') {
        this.share = 'Editar';
      } else if(res['action'] == 'create') {
        this.share = 'Crear';
      } else if(res['action'] == 'read') {
        this.dataForm.disable();
        this.share = 'Ver';
      } else {
        // this.redirect(model);
      }

      this.title = this.share +' Rol';

      this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
      this.xServices.sharingObservableData = { title: this.title };
      // console.log(this.title);
    });
  }

  loadData() {
    this.http.getRequest(SettingsProvider.getUrl('model/permission?is_paginate=0')).subscribe(result => {
      // console.log(result);
      if(result['status']) {
        this.permissions = result['items'];
        this.setLoadDataObservableData = true;
        this.addList();
      } else {
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
    });
  }

  initializeApp() {
    this.http.postRequest(SettingsProvider.getUrl(`model/role/read`), { id: this.id }).subscribe(result => {
      console.log(result);
      if(result['status']) {
        let entries = Object.entries(result['item']['permission_ids'])
        entries.map( ([key, val]:any) => {
          console.log(key, val);
          // let action = val == 'RW' ? this.actionsListRW : this.actionsListR;
          // this.addRemovePermission(key, action);
          this.addRemovePermission(key);
          if(val.includes('C')) {
            this.addItemsList(key, 'C');
            (document.getElementById(`${key}-checkbox-C`)  as HTMLInputElement)!.checked = true;
          }
          if(val.includes('R')) {
            this.addItemsList(key, 'R');
            (document.getElementById(`${key}-checkbox-R`)  as HTMLInputElement)!.checked = true;
          }
          if(val.includes('W')) {
            this.addItemsList(key, 'W');
            (document.getElementById(`${key}-checkbox-W`)  as HTMLInputElement)!.checked = true;
          }
          if(val.includes('D')) {
            this.addItemsList(key, 'D');
            (document.getElementById(`${key}-checkbox-D`)  as HTMLInputElement)!.checked = true;
          }
          if(this.action != 'read') {
            document.getElementById(`item_selector-${key}`)?.classList.add('enabled-items');
          } else {
            document.getElementById(`item_selector-${key}`)?.classList.remove('enabled-items');
          }
        });
        this.dataForm.patchValue({
          displayName:          result['item']['displayName'],
          active:               result['item']['active'],
        });
        this.createdAt = result['item']['createdAt'];
        this.updatedAt = result['item']['updatedAt'];
      } else {
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);

        if(result['redirect']) {
          this.router.navigateByUrl('/roles');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      // this.errorPage();
    });
  }

  addList() {
    this.permissions.forEach((action) => {
      this.form.addControl( action['pkyPermission'], this.fb.control(null, [Validators.required]) );
      this.form.disable();
    });
  }

  addRemovePermission(data:any) {
    // console.log(data);
    const item = parseInt(data);
    // console.log(item);
    if(this.permissionsList.includes(item)){
      // console.log('remove');
        document.getElementById(`item_selector-${item}`)?.classList.remove('enabled-items');
        this.permissionsList.forEach((compareItem:any,i:number) => {
          if(compareItem == item){
            this.permissionsList.splice(i,1);
            this.form.get(item.toString())?.disable();
            this.form.get(item.toString())?.patchValue(null);
          }
        });
    } else{
      // console.log('add');
      this.permissionsList.push(item);
      (document.getElementById(`checkbox-${item}`) as HTMLInputElement)!.checked = true;
      document.getElementById(`item_selector-${item}`)?.classList.add('enabled-items');
      if(this.action != 'read') {
        this.form.get(item.toString())?.enable();
        // this.form.get(item.toString()).patchValue(action || this.actionsListRW);
      } else {
        this.form.disable();
        // this.form.get(item.toString()).patchValue(action || this.actionsListRW);
      }
    }
    console.log(this.permissionsList);
  }


  errorPage() {
    this.router.navigateByUrl('/error/404');
  }

  ngAfterViewInit(){
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
  }

  ngOnInit() {
  }

  goToLogin() {
    this.router.navigateByUrl('/auth/login');
  }

  addItemsList(id: any, field: any) {
    console.log(parseInt(id), field);
    if(this.permissionsList.length > 0) {
      console.log(this.permissionsList)
      if(this.permissionsList.includes(parseInt(id))) {
        console.log(id)
        this.dataForm.get('permissions')?.patchValue(this.form.value);
        let actions = '';
        let item_checked:any = this.permissionsCheck.find((e: any) => e.id == id);
        if(item_checked) {
          if(!item_checked['actions'].includes(field)) {
            item_checked['actions'] = item_checked['actions'] + field;
          }
        } else {
          actions = field;
          let item: any = { id, actions };
          this.permissionsCheck.push(item);
        }
      }
      console.log(this.permissionsCheck);
    } else {
      this.dataForm.get('permissions')?.patchValue([]);
    }
  }

  checkboxChange(event: any, id: any, field: any) {
    console.log(event.target.checked, id, field);
    if(this.permissionsList.length > 0) {
      if(this.permissionsList.includes(id)) {
        this.dataForm.get('permissions')?.patchValue(this.form.value);
        if(event.target.checked) {
          let actions = '';
          let item_checked = this.permissionsCheck.find(e => e.id == id);
          if(item_checked) {
            if(!item_checked['actions'].includes(field)) {
              item_checked['actions'] = item_checked['actions'] + field;
            }
          } else {
            actions = field;
            let item = { id, actions };
            this.permissionsCheck.push(item);
          }
        } else {
          let item_checked = this.permissionsCheck.find(e => e.id == id);
          if(item_checked) {
            if(item_checked['actions'].includes(field)) {
              item_checked['actions'] = item_checked['actions'].replace(field, '');
              // document.getElementById(`${id}-checkbox-${field}`)['checked'] = false;
            }
          }
        }
      }
      console.log(this.permissionsCheck);
    } else {
      this.dataForm.get('permissions')?.patchValue([]);
    }
    // if(this.permissionsList.includes(id)){
    //   // console.log('remove');
    //   let data = field;
    //   this.dataForm.get('permissions').patchValue(this.form.value);
    //   if(event.target.checked) {
    //   }
    // }
  }

  submit() {
    this.load.loadingShow();
    if(this.action !== 'read') {
      this.permissionsCheck.forEach((item:any,i:number) => {
        let actions = { actions: (item.actions || '') };
        this.form.get(item.id.toString())?.patchValue(actions);
      });
      if(this.permissionsList.length > 0) {
        this.dataForm.get('permissions')?.patchValue(this.form.value);
      } else {
        this.dataForm.get('permissions')?.patchValue([]);
      }
      // console.log(this.dataForm.get('permissions').value);
      let body = {};
      if(this.id) {
        body = { ...this.dataForm.value, id: this.id };
      } else {
        body = { ...this.dataForm.value };
      }
      console.log(body);
      // return;
      this.http.postRequest(SettingsProvider.getUrl('model/role/'+this.action), body).subscribe(result => {
        console.log(result);
        // if(result['status']) {
        if(result['status']) {
          if(result['message']){
            this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), result['message'], 4000);
          } else {
            this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), 'El item fue '+ (this.action== 'edit' ? 'editado' : 'creado')+' exitosamente', 4000);
          }
          // this.router.navigateByUrl('/role/edit/'+result['item']['pkyRole']);
          this.router.navigateByUrl('/roles');
        } else {
          if(result['message']) {
            if(result['errors']) {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo procesar la información, intente nuevamente.');
            }
          } else {
            if(result['errors']) {
              this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.toast.error('¡Error!', 'No se pudo procesar la información, intente nuevamente.', 4000);
            }
          }
        }
        this.load.dismiss();
      }, error => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

  back() {
    this.router.navigateByUrl('/roles');
  }
}
