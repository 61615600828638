<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        Descargar Leads
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full">
                <label for="filter-modal_created_at" class="text-secondary block w-full text-sm font-medium mb-2">Rango de fechas:</label>
                <!-- <input type="date" name="created_at" maxlength="255" formControlName="created_at" id="filter-modal_created_at" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border"> -->
                <div class="input__date-range">
                    <mat-date-range-input [rangePicker]="picker">
                        <input formControlName="start_date" matStartDate placeholder="Fecha inicio">
                        <input formControlName="end_date" matEndDate placeholder="Fecha fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
            </div>
            <ng-container *ngIf="this.user$ | async as _user">
                <div class="w-full">
                    <label for="user_id" class="text-secondary block w-full text-sm font-medium mb-2">Vendedor:</label>
                    <mat-select id="user_id" name="user_id" formControlName="user_id" placeholder="Todos" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterUsers = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingUsers; else elseLoadingUsers">
                            <ng-container *ngIf="xServices.lengthItems(users); else elseUsers">
                                <mat-option [value]="null" >Todos</mat-option>
                                <mat-option *ngFor="let item of users | filter: filterUsers : 'fullname'" [value]="item['id']" >{{ item['fullname'] }}</mat-option>
                            </ng-container>
                            <ng-template #elseUsers>
                                <mat-option [value]="null">No se encontraron usuarios</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingUsers>
                            <mat-option [value]="null">Cargando usuarios...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
            </ng-container>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-2">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <div class="flex flex-wrap gap-2">
                <button mat-button type="button" (click)="submitForm(null)" class="cy__btn btn__primary">
                    <i class="fa-light fa-close"></i> Limpiar
                </button>
                <button mat-button type="button" (click)="submitForm(dataForm.value)" class="cy__btn btn__second">
                    <i class="fa-light fa-check"></i> Generar Excel
                </button>
            </div>
        </div>
    </div>
</div>
