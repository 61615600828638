import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { HttpClientProvider } from 'src/app/services/http-client';
import { SettingsProvider } from 'src/app/services/settings';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AlertService } from 'src/app/services/alert.service';
import { XtrasService } from 'src/app/services/xtras.service';

@Component({
  selector: 'modal-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatButtonModule],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class ModalClientFormComponent implements OnInit {

  dataForm = this.fb.group({
    first_name:   new FormControl(null, [Validators.required]),
    last_name:    new FormControl(null, [Validators.required]),
    ci_number:    new FormControl(null,),
    email:        new FormControl(null,),
    cellphone:    new FormControl(null, [Validators.required]),
  });

  action:string = 'create';

  id: any = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private alert: AlertService,
    public xServices: XtrasService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<ModalClientFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.action = data?.action ?? 'create';
    if(data?.id && (data?.action == 'edit' || data?.action == 'read')) {
      this.id = data.id;
      setTimeout(() => {
        this.initializeApp();
      }, 1);
    }
  }

  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl('model/customer/read'), { id: this.id }).subscribe((result:any) => {
      console.log(result);
      if(result['status']&&result['item']) {
        this.load.dismiss();
        this.dataForm.patchValue({
          first_name:   result.item?.first_name,
          last_name:    result.item?.last_name,
          ci_number:    result.item?.ci_number,
          email:        result.item?.email,
          cellphone:    result.item?.cellphone,
        });
      } else {
        this.xServices.getError(result, true, '/contacts', 'No se pudo procesar la información, intente nuevamente.');
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm() {
    if(this.action != 'read'){
      let body = {};
      if(this.id) {
        body = { ...this.dataForm.value, id: this.id };
      } else {
        body = { ...this.dataForm.value };
      }
      console.log(this.dataForm.value);
      this.load.loadingShow();
      this.http.postRequest(SettingsProvider.getUrl(`model/customer/${this.action}`), body).subscribe((result:any) => {
        if(result['status']) {
          this.load.dismiss();
          this.toast.success(`Item ${(this.action== 'edit' ? 'editado' : 'creado')}`, (result['message'] ?? `El item fue ${(this.action== 'edit' ? 'editado' : 'creado')} exitosamente`), 4000);
          this.dialogRef.close({ data: result['item'] });
        } else {
          this.xServices.getError(result, true, '/contacts', 'No se pudo procesar la información, intente nuevamente.');
        }
      }, error => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

}