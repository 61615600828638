import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { XtrasService } from 'src/app/services/xtras.service';
import { CommonModule, DatePipe } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';

@Component({
  selector: 'report-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HighchartsChartModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonCapYeiComponent,
  ]
})
export class ReportListPage implements OnInit, OnDestroy {

  title = 'Dashboard';


  data:any = [];

  
  filter_year = [];
  dataMonth = [];
  
  dataPanel:any[] = [];
  dataStadistics:any[] = [];
  dataList:any[] = [];
  dataStadistics2:any[] = [];

  /* --------------CHARR OPTION--------------------- */

  Highcharts: typeof Highcharts = Highcharts; 
  chartConstructor: string = 'chart'; 
  updateFlag: boolean = false; 
  oneToOneFlag: boolean = true; 
  runOutsideAngular: boolean = false;
  typeChart:any = 'column'
  
  chartDataColumLeads:any = [];
  chartDataColumCategoriesLeads = [];

  chartDataColumSales:any = []
  chartDataColumCategoriesSales = []
  
  chartOptionsColum:any[] = [];
  chartOptionsCake:any[] = [];
  
  /* ------------------END-------------------- */

  filterTemplate:string = '';
  loadingTemplate:boolean = false;
  noDataTemplate:boolean = false;
  templates:any[] = [];

  dataForm = new FormGroup({
    start_date:     new FormControl('' as any),
    end_date:       new FormControl('' as any),
    user_id:        new FormControl('' as any), // Lead id
  });

  start_date: Date | null = null;
  end_date: Date | null = null;

  filterSellers:string = '';
  loadingSellers:boolean = false;
  sellers:any[] = [];

  constructor(
    private metaTitle: Title,
    private userModel: UserModel,
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private datePipe: DatePipe,
  ) {
    let formValues = JSON.parse(localStorage.getItem('formReportDashboard') ?? '{}');
    if (formValues && Object.keys(formValues).length > 0) {
      console.log(formValues);
      this.start_date = formValues.start_date_format ? new Date(formValues.start_date_format) : null;
      this.end_date = formValues.end_date_format ? new Date(formValues.end_date_format) : null;
      this.dataForm.patchValue(formValues);
    }
    
    this.dataForm.get('start_date')?.valueChanges.subscribe(value => {
      if (value == null || value == '') {
        this.dataForm.get('end_date')?.clearValidators();
      } else {
        this.dataForm.get('end_date')?.setValidators([Validators.required]);
      }
      this.dataForm.get('end_date')?.updateValueAndValidity();
    });
    this.initializeApp();
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp(){
    this.dataInit();

    this.sellers = [];
    this.loadingSellers = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.sellers = result?.items ?? [];
        this.loadingSellers = false;
      } else {
        this.loadingSellers = false;
        this.xServices.getError(result, false, '/account', 'No se pudo obtener la información, intente nuevamente.');
      }
    }, error => {
      console.log(error);
      this.loadingSellers = false;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  dataInit() {
    let form = {
      ...this.dataForm.value,
      start_date_format: this.start_date,
      end_date_format: this.end_date,
    };
    localStorage.setItem('formReportDashboard', JSON.stringify(form));

    this.dataPanel = [];
    this.dataStadistics = [];
    this.dataList = [];
    this.dataStadistics2 = [];
    this.load.loadingShow();
    let queryParams = new HttpParams({ fromObject: this.dataForm.value });
    this.http.getRequest(SettingsProvider.getUrl(`get-report-ventas`), null, queryParams).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.dataPanel = result?.data?.panel ?? [];
        this.dataStadistics = result?.data?.data_stadistics ?? [];
        this.dataList = result?.data?.data_list ?? [];
        this.dataStadistics2 = result?.data?.data_stadistics2 ?? [];
        this.load.dismiss();
        setTimeout(() => {
          this.processData(result);
        }, 1);
      } else {
        this.xServices.getError(result, true, '/account', 'No se pudo obtener la información, intente nuevamente');
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result: any) {
    let dataStadistics:any[] = [];
    this.dataStadistics.forEach((estadistica: any) => {
      let datos: (number | Highcharts.PointOptionsObject | [string, number | null] | null)[] = (estadistica?.items ?? []).map((item:any) => item.value);
      let categorias = (estadistica?.items ?? []).map((item:any) => item.name);
      let opciones: Highcharts.Options = this.crearOpcionesGrafico('column', estadistica.name, estadistica.subtext, datos, categorias);
      dataStadistics.push({
        id: estadistica['id'],
        name: estadistica['name'],
        status: estadistica['status'],
        total: estadistica['cant_total'],
        options: opciones,
      });
    });
    this.chartOptionsColum = dataStadistics;


    let dataStadistics2:any[] = [];
    this.dataStadistics2.forEach((estadistica: any) => {
      let datos: (number | Highcharts.PointOptionsObject | [string, number | null] | null)[] = [];
      (estadistica?.items ?? []).forEach((item:any) => datos.push({ name: item['name'], y: item.value }));
      let opciones: Highcharts.Options = this.crearOpcionesGrafico('pie', estadistica.name, estadistica.subtext, datos);
      dataStadistics2.push({
        id: estadistica['id'],
        name: estadistica['name'],
        status: estadistica['status'],
        total: estadistica['cant_total'],
        items: estadistica['items'] ?? [],
        options: opciones,
      });
    });

    this.chartOptionsCake = dataStadistics2;
  }

  crearOpcionesGrafico(type: ('pie' | 'column'), nombre: string, subtext: string, datos: (number | Highcharts.PointOptionsObject | [string, number | null] | null)[], categorias: string[] = []): Highcharts.Options {
    console.log('datos', datos);
    switch (type) {
      case 'pie':
        return {
          series: [{
            name: nombre,
            data: datos,
            type: 'pie',
          }],
          title: { text: subtext, align: 'left' },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          }
        };
    
      default:
        return {
          series: [
            {
              name: nombre,
              data: datos,
              type: 'column',
            }
          ],
          title: { text: subtext, align: 'left' },
          xAxis: { 
            categories: categorias, 
          },
          yAxis:{ title: { text: 'Valores' }, },
        };
    }
  }

  ngOnDestroy(): void {
  }

  dateChange(event: any, field: any) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.dataForm.get(field)?.patchValue(date ?? '');
  }

  clearFilter() {
    this.start_date = null;
    this.end_date = null;
    this.dataForm.patchValue({
      start_date:       '',
      end_date:         '',
      user_id:          '',
    });
    localStorage.setItem('formReportDashboard', JSON.stringify({}));
    setTimeout(() => {
      this.dataInit();
    }, 1);
  }
}
