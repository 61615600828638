import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, RouterModule } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/app/environment/environment';
import { Menu } from 'src/app/interfaces/interfaces';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { UserModel } from 'src/app/services/user-model';
import { XtrasService } from 'src/app/services/xtras.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatExpansionModule, RouterModule],
  animations: [
    trigger('slideToggle', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {

  private unsubscribe: Subscription[] = [];

  @Input() products: any;
  user$: Observable<UserType>;

  environment = environment;

  _dashboard_menu: any[] = [];
  _operations_menu: any[] = [];
  _societies_menu: any[] = [];
  _diffusions_menu: any[] = [];
  _admin_menu: any[] = [];
  _params_menu: any[] = [];

  menu: Menu[] = [];

  constructor(
    private router: Router,
    public userModel: UserModel,
    public xServices: XtrasService,
    private authService: AuthService,
  ) {
    this.user$ = this.authService.user$;

  }

  ngOnInit() {
    this.user$.subscribe(user => {
      if(user) {
        this.menu = user.menu ?? [];
      }
    });
  }

  irInicio() {
    this.router.navigateByUrl('/');
  }

  goToPage(url: any) {
    this.router.navigateByUrl('/'+url);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  isLinkActive( url:string ) {
    var actualUrl = this.router.url;
    var lastWord  = url.substr(url.lastIndexOf("/")+1);
    var lastChar  = lastWord.charAt(lastWord.length-1);
    var lastOtherWord = null;
    if(lastWord == 'city' || lastWord == 'cities') {
      lastWord = 'city';
    }
    if( lastChar == 's' ) {
      lastOtherWord = lastWord.substring(0, lastWord.length-1);
    }
    if((actualUrl).toString().includes('/form/') || (actualUrl).toString().includes('/list/')) {
      if(actualUrl.split('/')[3] == lastWord || actualUrl.split('/')[3] == lastOtherWord) return true;
      return false;
    }
    return ((actualUrl).toString()).includes(lastWord) || ((actualUrl).toString()).includes(lastOtherWord!);
  }

  escapeRegExpMatch(s: any) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  isExactMatch(str: any, match: any) {
    return new RegExp(`\\b${this.escapeRegExpMatch(match)}\\b`).test(str);
  }

  toggleMenu(item: Menu) {
    item.expandend_submenu =!item.expandend_submenu;
  }
}
