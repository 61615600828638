import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';

declare var $: any;


import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { ModalLeadFormComponent } from 'src/app/components/lead-form/lead-form.component';

@Component({
  selector: 'modal-nota-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatRadioModule, MatSelectModule, FilterPipe, SearchDirective],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class ModalReunionFormComponent implements OnInit {

  dataForm = this.fb.group({
    parent_id:    new FormControl(null as any, [Validators.required]), //* Lead id
    customer_id:  new FormControl(null as any, [Validators.required]), //* id Cliente
    fullname:     new FormControl(null, [Validators.required]),
    type:         new FormControl(null, [Validators.required]),
    detail:       new FormControl(null, [Validators.required]),
  });

  action:string = 'create';

  types = [
    { id: 'email', name: 'Correo electrónico' },
    { id: 'whatsapp', name: 'WhatsApp' },
    { id: 'phonecall', name: 'Llamada' },
    { id: 'update', name: 'Actualización' },
    { id: 'lead-update', name: 'Actualización de Lead' },
    { id: 'integration', name: 'Integración' },
    { id: 'other', name: 'Otros' },
  ];

  filterTypes!: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalLeadFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.dataForm.get('fullname')?.patchValue(data?.fullname ?? null);
    this.dataForm.get('parent_id')?.patchValue(data?.parent_id ?? null);
    this.dataForm.get('customer_id')?.patchValue(data?.customer_id ?? null);
  }

  ngOnInit() {
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm() {
    console.log(this.dataForm.value);
    // this.dialogRef.close({data: this.dataForm.value});
  }

}