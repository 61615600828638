import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SettingsProvider } from 'src/app/services/settings';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'modal-reminder-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
  ],
})
export class ModalLeadReminderFormComponent implements OnInit {

  dataForm = this.fb.group({
    lead_id:        new FormControl(null, [Validators.required]), // Lead id
    name:           new FormControl(null as any, [Validators.required]),
    date:           new FormControl(null as any, [Validators.required]),
    time:           new FormControl(null as any, [Validators.required]),
    type:           new FormControl(null as any, [Validators.required]),
    detail:         new FormControl(null as any, [Validators.required]),
    reminder_type:  new FormControl(null as any),
  });

  types = [
    { id: 'phonecall', name: 'Llamada' },
    { id: 'whatsapp', name: 'WhatsApp' },
    { id: 'email', name: 'E-mail' },
    { id: 'in-person-visit', name: 'Visita presencial' },
    { id: 'virtual-meeting', name: 'Reunión virtual' },
    // { id: 'other', name: 'Otro' },
  ];

  loadingReminderTypes:boolean = false;
  filterReminderTypes: string = '';
  reminder_types = [];

  action:string = 'create';

  filterTypes!: string;

  date: Date | null = null;

  loadingLeads:boolean = false;
  filterLeads!: string;
  leads:any[] = [];
  customerId:any = null;
  contactName:any = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalLeadReminderFormComponent>,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private toast: NotificationService,
    private http: HttpClientProvider,
    private load: LoadingService,
    public xServices: XtrasService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    console.log(data);
    this.customerId = data?.customer_id ?? null;
    this.contactName = data?.customer_name ?? null;
    this.getReminderTypes();
    this.dataForm.get('lead_id')?.setValue(data['lead_id'] || null);
    if(!data?.lead_id&&data?.customer_id) {
      this.initializeApp();
    }
  }

  initializeApp() {
    this.loadingLeads = true;
    let data = {
      is_paginate: 0,
      customer_id: this.customerId
    }
    let queryParams = new HttpParams({ fromObject: data });
    this.http.getRequest(SettingsProvider.getUrl(`model/lead`), null,  queryParams).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.leads = result['items'] ?? [];
        this.loadingLeads = false;
      } else {
        this.loadingLeads = false;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      this.loadingLeads = false;
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  getReminderTypes() {
    this.loadingReminderTypes = true;
    this.http.getRequest(SettingsProvider.getUrl(`get-reminder-interval`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.reminder_types = result['data'] ?? [];
        this.loadingReminderTypes = false;
      } else {
        this.loadingReminderTypes = false;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      this.loadingReminderTypes = false;
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(data?:any): void {
    this.dialogRef.close((data ?? null));
  }

  submitForm() {
    console.log(this.dataForm.value);
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl('add-lead-reminder'), this.dataForm.value).subscribe(result => {
      this.load.dismiss();
      if(result['status']) {
        this.toast.success('¡Éxito!', (result['message'] ?? 'Item creado correctamente.'));
        this.dismiss({'reloadData': true});
      } else {
        this.xServices.getError(result, true, '/account');
      }
    }, error => {
      this.load.dismiss();
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  dateChange(event: any) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.dataForm.get('date')?.patchValue(date);
    console.log(this.dataForm.get('date')?.value);
  }

}