<div class="w-full block">
  <div class="w-full block table_responsive p-6">
    <div class="w-full content_btns flex flex-wrap gap-4 justify-between items-start w-auto pb-4">
      <h5 class="text-slate-800 font-bold text-lg inline-block relative m-0 text-center">
        {{ title }}
      </h5>

      <form [formGroup]="dataForm" (submit)="initializeApp()" class="max-w-full flex flex-wrap items-center gap-4">
        <div class="w-[600px] max-w-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4 items-center gap-2">
          <span></span>
          <div class="w-full relative input__date type_full border border-[#F1F1F1]">
            <mat-date-range-input [rangePicker]="picker" class="input__date-item !bg-white">
              <input matStartDate readonly [(ngModel)]="start_date" (ngModelChange)="dateChange($event, 'start_date')" [ngModelOptions]="{standalone: true}">
              <input matEndDate readonly [(ngModel)]="end_date" (ngModelChange)="dateChange($event, 'end_date')" [ngModelOptions]="{standalone: true}">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
        </div>
        <button-cy type="submit" addClass="btn__second">
          <i class="fa-light fa-filter"></i> Filtrar
        </button-cy>
        <button-cy (onPress)="clearFilter()" addClass="btn__primary" icon="fa-light fa-rotate-right" label="Limpiar filtros" [iconOnly]="true"></button-cy>
      </form>
    </div>

    <div class="block w-full pt-5">
      <div class="w-[1200px] mx-auto block max-w-full">
        <ng-container *ngIf="xServices.lengthItems(notifications); else noData">
          <a *ngFor="let item of notifications" (click)="toUrlProcess(item.id, item['url'])" class="grid sm:grid-cols-[minmax(0,50px)_minmax(0,1fr)_minmax(0,50px)] grid-cols-[minmax(0,40px)_minmax(0,1fr)_minmax(0,32px)] gap-4 items-center transition-all rounded bg-gray-50 mb-4 p-4 rounded-xl {{ item['url'] && 'cursor-pointer' }}">
            <div class="w-full block">
              <div class="w-full block relative before:block before:pb-[100%]">
                <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                  <i *ngIf="item.is_read" title="Leído" class="fa-solid fa-bell sm:text-[2rem] text-xl text-cyan-400"></i>
                  <i *ngIf="!item.is_read" title="No leído" class="fa-solid fa-bell-on sm:text-[2rem] text-xl text-emerald-400"></i>
                </span>
              </div>
            </div>
            <div class="w-full block">
              <div class="w-full block">
                <h5 class="block w-full text-base font-bold text-gray-600 m-0">{{ item['name'] }}</h5>
                <div class="block w-full text-base" [innerHtml]="item['message']"></div>
              </div>
              <div class="w-full grid sm:grid-cols-2 grid-cols-1 gap-2 items-center">
                <p class="text-xs text-gray-400 ">{{ item['date'] ?? '-' }} - {{ item['time'] ? item['time'] : '00:00' }}</p>
              </div>
            </div>
            <div class="w-full block">
              <div (click)="$event.stopPropagation(); deleteItem(item)" class="w-full block relative before:block before:pb-[100%]">
                <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                  <i title="Borrar" class="fa-solid fa-trash sm:text-xl text-lg text-red-400"></i>
                </span>
              </div>
            </div>
          </a>
        </ng-container>
        <ng-template #noData>
          <p>No se encontraron notificaciones</p>
        </ng-template>
      </div>
    </div>
  </div>
</div>