<div class="w-full block">

  <div class="content_btns flex flex-wrap gap-4 justify-between w-full pt-2 pb-4">
    <!-- <a (click)="openDialog('select')" href="javascript:void(0)" class="font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white">
      <i class="fa-light fa-user-plus"></i> Agregar Usuarios
    </a> -->
    <button (click)="openDialog('input')" class="font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white">
      <i class="fa-light fa-user-plus"></i> Invitar Usuarios
    </button>
  </div>

  <div class="grid grid-cols-1 gap-4">

    <div class="w-full block">
      <div class="w-full block">
        <div class="w-full grid grid-cols-1 grid-rows-[minmax(0,1fr)] gap-8 p-6 bg-white rounded-xl drop-shadow">
          <div class="grid grid-cols-1 grid-rows-[minmax(0,min-content)_minmax(0,1fr)] w-full ">
            <div class="h-auto flex flex-wrap justify-between gap-4 w-full border-b border-b-gray-300 pb-4">
              <div class="w-auto grid grid-cols-[minmax(0,40px)_minmax(0,1fr)] gap-4 items-center" *ngIf="item">
                <div class="w-full block relative before:block before:pb-[100%]">
                  <img src="/assets/img/user.png" alt="User Image" class="w-[100%] h-[100%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 object-center object-cover">
                </div>
                <span class="w-full block">
                  <span class="w-full block text-sm text-ellipsis overflow-hidden max-w-full whitespace-nowrap">{{ item['name'] }}</span>
                  <span class="w-full block text-xs text-gray-400 font-medium text-ellipsis overflow-hidden max-w-full whitespace-nowrap">{{ item['email'] }}</span>
                </span>
              </div>
    
              <div class="w-auto flex flex-wrap gap-4">
                <button class="bg-gray-200 text-gray-600 py-2 px-4 rounded font__text font-semibold text-sm cursor-default">Roles de Usuario</button>
                <!-- <button class="bg-gray-200 hover:bg-gray-300 text-gray-600 py-2 px-4 rounded font__text font-semibold text-sm">...</button> -->
                <button routerLink="/users" class="bg-gray-200 hover:bg-gray-300 text-gray-600 py-2 px-4 rounded font__text font-semibold text-sm">
                  <i class="fa-regular fa-xmark"></i>
                </button>
              </div>
            </div>
    
            <div class="p-4 w-full h-auto grid grid-cols-[minmax(0,100%)] grid-rows-[minmax(0,min-content)_minmax(0,1fr)] gap-6" *ngFor="let elem of items; let i = index">
              <div class="w-full block">
                <div class="w-full grid grid-cols-[minmax(0,1fr)_minmax(0,55px)] gap-4 items-center">
                  <div class="w-full block">
                    <h4 class="text-ellipsis whitespace-nowrap overflow-hidden font-bold font__title text-xl text-[color:var(--primary-color)] m-0">{{ elem['displayName'] || elem['name'] }}</h4>
                  </div>
                  <div class="w-full block">
                    <div (click)="toggleChange(items, i, id, elem['pkyRole'])" class="cursor-pointer checkbox_toggle block w-full relative max-w-[55px] mx-auto overflow-hidden">
                      <!-- No es necesario un input -->
                      <input type="checkbox" [checked]="elem['checked']" class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-[2]">
                      <div class="checkbox_toggle-content w-full rounded-full h-[25px] bg-gray-100">
                        <span [ngClass]="{'checked': elem['checked']}" class="checkbox_toggle-check absolute top-1/2 left-0 -translate-y-1/2 w-[20px] h-[20px] transition duration-300 rounded-full bg-gray-300"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full h-auto flex flex-col gap-6">

                <div class="w-full grid grid-cols-[minmax(0,1fr)] items-center" *ngFor="let permission of elem['permissions']">
                  <div class="w-full block">
                    <h5 class="w-full block text-ellipsis whitespace-nowrap overflow-hidden text-sm font__text font-semibold">{{ permission['displayName'] || permission['name'] }}</h5>
                    <p *ngIf="permission['description']" class="w-full text-two-hidden text-xs font__text text-gray-500">{{ permission['description'] }}</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
