<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        CREAR NOTA
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <ng-container *ngIf="this.user$ | async as _user">
                <div *ngIf="_user.role_id == 1 || _user.role_id == 3" class="w-full">
                    <label for="fullname" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Ejecutivo de ventas</span>:</label>
                    <div class="block m-0 w-full bg-[#e5faff] rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border min-h-[42px]">{{ saleName ?? '-' }}</div>
                </div>
            </ng-container>

            <div *ngIf="!data?.parent_id" class="w-full">
                <label for="parent_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Lead</span>:</label>
                <mat-select id="parent_id" name="parent_id" formControlName="parent_id" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterLeads = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingLeads; else elseLoadingLeads">
                        <ng-container *ngIf="xServices.lengthItems(leads); else elseLeads">
                            <mat-option *ngFor="let item of leads | filter: filterLeads : 'detail'" [value]="item['id']" >ID:{{ item['id'] }} - {{ item['detail'] }} - {{ item.channel?.name ?? '-' }}</mat-option>
                        </ng-container>
                        <ng-template #elseLeads>
                            <mat-option [value]="null">No se encontraron leads</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingLeads>
                        <mat-option [value]="null">Cargando leads...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="w-full">
                <label for="activity_type_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Categoría</span>:</label>
                <mat-select id="activity_type_id" name="activity_type_id" formControlName="activity_type_id" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterTypes = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingTypes; else elseLoadingTypes">
                        <ng-container *ngIf="xServices.lengthItems(types); else elseTypes">
                            <mat-option *ngFor="let item of types | filter: filterTypes : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                        </ng-container>
                        <ng-template #elseTypes>
                            <mat-option [value]="null">No se encontraron tipos</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingTypes>
                        <mat-option [value]="null">Cargando tipos...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="w-full">
                <label for="detail" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Comentario</span>:</label>
                <textarea type="text" name="detail" rows="4" formControlName="detail" id="detail" maxlength="600" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border"></textarea>
            </div>
            <!-- <div>
                <hr>
            </div>
            <div>
                <label for="date" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Crear tarea</span>:</label>
                <div class="grid grid-cols-1 md:grid-cols-[minmax(0,1fr)_minmax(0,max-content)] gap-4">
                    <div>
                        <mat-select id="type" name="type" formControlName="type" placeholder="Seleccione una opción" class="custom__mat-select">
                            <div class="filter_option">
                                <input placeholder="Buscar" appSearch (appSearch)="filterTypes = $event" class="!outline-none !ring-0 !border-0">
                            </div>
                            <mat-option *ngFor="let item of types | filter: filterTypes : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                        </mat-select>
                    </div>
                    <div>
                        <input type="text" name="time" formControlName="time" id="time" maxlength="600" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                    </div>
                </div>
            </div> -->
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <button mat-button type="button" [disabled]="dataForm.invalid" (click)="submitForm()" class="cy__btn btn__second">
                <i class="fa-light fa-check"></i> Crear nota
            </button>
        </div>
    </div>
</div>
