import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column, DynamicFormFieldModel } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DynamicformFieldComponent } from 'src/app/components/dynamic-form-field/dynamic-form-field.component';

declare var $: any;

@Component({
  selector: 'model-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, DatatableComponent, PaginatorComponent, MatDialogModule, MatButtonModule, DynamicformFieldComponent]
})
export class ModelListPage implements OnInit, OnDestroy {

  title = 'Model';

  titleSingular: string | null = null;
  titlePlural: string | null = null;

  pagination = null;
  data:any = [];

  organizations:any = [];
  codOrganization = new FormControl(null);
  model = '';

  isAdmin:boolean = false;

  noData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[];

  actions:any[] = [
    { id: 'read', label: 'Ver', only_icon: true, icon: 'fa-light fa-eye', class: 'btn-action info', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'edit', label: 'Editar', only_icon: true, icon: 'fa-light fa-edit', class: 'btn-action success', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'remove', label: 'Eliminar', only_icon: true, icon: 'fa-light fa-trash', class: 'btn-action danger', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
  ];

  visibilityBtns:any[] = [];
  create:boolean = true;

  dataForm!: FormGroup;
  formFields!: DynamicFormFieldModel[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClientProvider,
    private load: LoadingService,
    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private alert: AlertService,
    private fb: FormBuilder,
  ) {
    this.route.queryParams.subscribe((data:any) => {
      this.title = data&&data['page'] ? data['page'] : 'Model';
    });
    this.route.params.subscribe((data:any) => {
      this.model = data['model'];
      this.initializeApp();
    });
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    console.log(this.model, fila, action, id);
    if(action == 'remove') {
      this.removeItem((`"ID: ${item['id']}"`), id);
    } else {
      this.router.navigate([`/model/${this.model}`, action, id]);
    }
  }
  
  setTitle(title: string) {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${title}`);
    this.xServices.sharingObservableData = { title: title };
  }

  ngOnInit(): void {
  }

  initializeApp() {
    this.formFields = [];
    this.dataForm = this.fb.group({});

    this.init();
  }

  init() {
    this.pagination = null;
    this.columns = [];
    this.data = [];
    this.dataSource.data = [];
    this.load.loadingShow();
    this.noData = false;
    this.http.getRequest(SettingsProvider.getUrl(`model-list/${this.model}`), null, this.dataForm.value).subscribe(result => {
      if(result['status']) {
        this.processData(result);
        this.load.dismiss();
      } else {
        this.load.dismiss();
        this.noData = true;
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {

    if(result['node']) {
      this.titleSingular   = result['node']['singular']  || null;
      this.titlePlural     = result['node']['plural']    || null;
      this.setTitle(this.titlePlural ?? 'Model');
    }

    var listColumn: Column[] = [];
    var indexInit:number = 2;
    result['thead'].forEach((element:any,index:number) => {
      listColumn.push({id: element['name'], label: element['label'], hideOrder: ((indexInit + index) +1)});
    });
    listColumn.unshift({id:'index',label:'#',hideOrder:0,width:100});
    listColumn.push({id:'action_buttons',label:'Acciones',hideOrder:1});
    this.columns = listColumn;

    if(result['filters'] && result['filters'].length > 0) {
      var listFields: DynamicFormFieldModel[] = [];
      result['filters'].forEach((element:any) => {
        let options:any[] = [];
        Object.entries(element['options']).forEach(([key, value]:any) => {
          options.push({ id: key, name: value });
        });
        listFields.push({
          type: element['type'] ?? 'text',
          name: element['name'],
          label: element['label'],
          placeholder: element['placeholder'] || null,
          show: true,
          validators: element['required'] ? [Validators.required] : [],
          col: element['col'],
          select_options: options ?? [],
        });
      });

      this.formFields = listFields;
      
      this.formFields.forEach(formItem => {
        const formControl = this.fb.control(formItem.value, formItem.validators);
        this.dataForm.addControl(formItem.name, formControl);
      });
    }


    this.pagination = result['result'];
    this.data = result['result']['data'];
    let rows:any[] = [];

    // Una forma de mejorar la fluidez del código sería utilizar la sintaxis de desestructuración en map "({...element},index:number)"
    // para asignar las propiedades del objeto a una variable en lugar de crear un nuevo objeto en cada iteración que normalmente sería
    // así "(element:any,index:number)"
    console.log(result['result']['data']);
    result['result']['data'].map(({...element},index:number)=> {
      let items = { index: index + 1 };
      Object.entries(element).forEach(([key, value]:any) => {
        items = { ...items, ...{[key]: (value || '-')} }
      });
      rows.push(items);
    });

    this.dataSource.data = rows;
    console.log(rows);

    if(rows.length > 0) {
      this.noData = false;
      this.pagination = result['result'];
    } else {
      this.noData = true;
    }

    if(result['actions']) {
      this.showActionsButtons(result);
    }
  }

  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          this.processData(result);
        } else {
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']){
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`node/${this.model}/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = [
      { id: 'read', active: result['actions']['read'] },
      { id: 'edit', active: result['actions']['edit'] },
      { id: 'remove', active: result['actions']['delete'] },
    ];
    this.create = result['actions']['create'];
  }
}
