<div class="user-custom">
    <div class="img-custom">
        <img [src]="img ?? 'assets/img/user.png'" alt="Missing image" onerror="this.onerror=null;this.src='assets/img/user.png';" />
    </div>
    <div class="info-custom">
        <h5>{{name}}</h5>
        <p *ngIf="email">{{email}}</p>
        <p class="phone" *ngIf="desc">{{desc}}</p>
    </div>
</div>
