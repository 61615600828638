import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsProvider } from 'src/app/services/settings';
import { UserModel } from 'src/app/services/user-model';

import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DatatableComponent } from "../../../components/datatable/datatable.component";
import { Column } from 'src/app/interfaces/interfaces';

declare var $: any;

@Component({
    selector: 'socio-form',
    templateUrl: './form.page.html',
    styleUrls: ['./form.page.scss'],
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MatSelectModule, MatDialogModule, DatatableComponent]
})
export class SocioFormPage implements OnInit {

  title = 'EDUARDO MEJIA';

  share = '';
  action = '';
  show: boolean = true;
  item = null;

  tab1 = true;
  tab2 = false;
  tab3 = false;

  dataForm:any = this.fb.group({
    name:                 new FormControl(null, [Validators.required]),
    last_name:            new FormControl(null, [Validators.required]),
    mother_last_name:     new FormControl(null, [Validators.required]),
    ci:               new FormControl(null, [Validators.required]),
    email:                new FormControl(null, [Validators.required]),
    city_id:              new FormControl(null, [Validators.required]),
    address:              new FormControl(null),
    cellphone:            new FormControl(null, [Validators.required]),
    birthday:             new FormControl(null as string | null, [Validators.required]),
    genero:                new FormControl(null as any[] | null, [Validators.required]),
  });


  clients:any   = [];
  roles         = [];
  createdAt     = null;
  updatedAt     = null;
  id            = null;
  isSuperAdmin  = false;

  type = null;

  dataSource:any = []

  dataFalse:any = [
    {
      id: "1",
      date: "31-07-2022",
      pay: "Combo socio dorado + abono",
      total_pay: "483",
      type_socio: "286",
      abono_perferincial: "135",
    },
    {
      id: "2",
      date: "31-07-2022",
      pay: "Combo socio dorado + abono",
      total_pay: "483",
      type_socio: "286",
      abono_perferincial: "135",
    }
  ];

  columns:Column[]=[
    {id:'id',label:'#',hideOrder:0,width:60},
    {id:'date',label:'Fecha',hideOrder:2,html:true},
    {id:'pay',label:'Pago',hideOrder:3},
    {id:'total_pay',label:'Monto total',hideOrder:4,html:true},
    {id:'type_socio',label:'Socio Centenario',hideOrder:4},
    {id:'abono_perferincial',label:'Abono Preferencial',hideOrder:5},
    /* {id:'action_buttons',label:'Acciones',hideOrder:1}, */
    /* {id:'active',label:'Tipo de Contacto',hideOrder:2}, */
  ];
  noData: boolean = false;
  loadingData: boolean = false;

  organization_id = null;

  cities = [
    { id: 1, name: 'La Paz' }
  ];

  loadingClients:boolean = false;
  loadingRoles:boolean = false;

  filterTerm!: string;

  constructor(
    private router: Router,
    private metaTitle: Title,
    private userModel: UserModel,
    private route: ActivatedRoute,
    private toast: NotificationService,
    private http: HttpClientProvider,
    private load: LoadingService,
    public xServices: XtrasService,
    private authService: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
    public dialog: MatDialog,
  ) {
    this.route.params.subscribe((res) => {
      this.processData(this.dataFalse)

      this.loadData();
      console.log(res);
      
      this.authService.getUserByToken().subscribe((res: any) => {
        this.isSuperAdmin = res.pkyUser == 1 ? true : false;
      });
      this.type = res['action'];

      this.action = res['action'];
      if(res['action'] == 'edit'&&res['id']||res['action'] == 'view'&&res['id']){
        this.id = res['id'];
        this.initializeApp();
      }


      

      /* this.title = this.share +' Usuario'; */

      this.metaTitle.setTitle(`${this.userModel.getTitle()} | Socios`);
      this.xServices.sharingObservableData = { title: "Socios" };
    });
    
  }

  loadData() {
    
    this.loadingClients = true;
    this.http.getRequest(SettingsProvider.getUrl('model/client/0?active=1&child=1')).subscribe(result => {
      console.log(result);
      if(result['status']) {
        let items: any[] = [];

        result['items'].forEach((element:any,index:number)=> {
          element['index'] = index +1;
          let dataItem = {id: element['pkyClient'], name: (element['concat_name'])};
          items.push(dataItem);
        });
        this.clients = items;

        this.loadingClients = false;
      } else {
        this.loadingClients = false;
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.loadingClients = false;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
    });

    this.loadingRoles = true;
    this.http.getRequest(SettingsProvider.getUrl('model/role/0?active=1&child=1')).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.roles = result['items'];
        this.loadingRoles = false;
      } else {
        this.loadingRoles = false;
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.loadingRoles = false;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
    });
  }

  initializeApp() {
    this.load.loadingShow();
    this.dataForm.patchValue({
      name:                   "Eduardo"  || null,
      last_name:              "Mejia"  || null,
      mother_last_name:       "-"  || null,
      ci:                     "7264512"  || null,
      email:                  "edumejia30gmail.com"  || null,
      city_id:                "La Paz"  || null,
      address:                "b/Las Palmas 4556687"  || null,
      cellphone:              "77452312"  || null,
      birthday:               " 24/02/1982"  || null,
      genero:                 "Masculino"  || null,
    });


    this.load.dismiss();
    
  }

  ngAfterViewInit(){
    /* this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`); */
  }

  ngOnInit() {
  }

  back() {
    this.router.navigateByUrl('/socios');
  }

  clickTabs(tab:string){

    if(tab === 'tab1'){
      this.tab1 = true;
      this.tab2 = false;
      this.tab3 = false;
    }
    if(tab === 'tab2'){
      this.tab1 = false;
      this.tab2 = true;
      this.tab3 = false;
    }
    if(tab === 'tab3'){
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = true;
    }

  }

  processData(result:any) {
    let rows: any[] = [];
    result.forEach((element:any,index:number)=> {
      element['index'] = index +1;

      let _total_pay = { total_pay: `${ element['total_pay']} Bs.` }
      let _type_socio = { type_socio: `${ element['type_socio']}Bs.` }
      let _abono_perferincial = { abono_perferincial: `${ element['abono_perferincial']}Bs.` }

      let items = {...element, ..._total_pay, ..._type_socio, ..._abono_perferincial};
      
      rows.push(items);
    });

    this.dataSource = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
    } else {
      this.noData = true;
    }

    // if(result['actions']) {
    //   this.showActionsButtons(result);
    // }
  }


}
