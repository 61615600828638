<div class="pt-5 lg:px-5 px-0 pb-[5rem] w-full container mx-auto">
  <div class="lg:grid lg:grid-cols-[minmax(0,100%)] flex md:flex-col flex-wrap gap-4 w-full">
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <div class="grid grid-cols-12 justify-between">
        <div class="sm:col-span-6 col-span-12">
          <div class="flex flex-wrap gap-4 items-center mb-4">
            <h5 class="text-slate-800 font-semibold text-lg inline-block relative m-0 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">
              {{ title }}
            </h5>
            <a (click)="back()" class="flex gap-1 items-center justify-center cursor-pointer">
              <i class="fa-regular fa-circle-left"></i> <span class="md:block hidden leading-none">Volver</span>
            </a>
          </div>
          <p>(<b><span class="required"></span></b> ) Indica que el campo es obligatorio.</p>
        </div>
        <div class="sm:col-span-6 col-span-12 text-right">
          <button *ngIf="action != 'create'" [routerLink]="['/audits']" [queryParams]="{ node: model, codItem: id }" type="button" class="w-auto text-third border border-third bg-white hover:bg-third hover:text-white focus:ring-4 focus:ring-third font-semibold rounded-lg text-sm px-4 py-2 focus:outline-none">
            <i class="fa fa-history" aria-hidden="true"></i> Historial
          </button>
        </div>
      </div>

      <form class="account-settings-form grid grid-cols-1" (ngSubmit)="submit()" [formGroup]="dataForm">
        <div class="w-full mt-2 grid grid-cols-12 gap-4" *ngIf="formFields && formFields.length > 0">
          <ng-container *ngFor="let field of formFields">
            <app-dynamic-form-field [formItem]="field" [type]="field.type" [action]="action" (changeValue)="(field.parent != null && field.parent != undefined) && changeValue(field, $event)" [submitted]="submitted"></app-dynamic-form-field>
          </ng-container>
        </div>

        <div class="flex flex-wrap items-center justify-between mt-5">
          <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-wrap gap-5" *ngIf="action != 'create'">
              <div class="sm:col-span-6 col-span-12" *ngIf="createdAt">
                <p class="text-sm">Fecha creación: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(createdAt) }}</span></p>
              </div>
              <div class="sm:col-span-6 col-span-12" *ngIf="updatedAt">
                <p class="text-sm">Fecha edición: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(updatedAt) }}</span></p>
              </div>
            </div>
          </div>
          <div class="lg:col-span-6 col-span-12 text-right">
            <ng-container *ngIf="action !== 'read'">
              <div class="text-right block w-full mt-5">
                <button mat-button type="submit" class="cy__btn btn__second">
                  <i class="fa-light fa-check"></i> {{ share }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
