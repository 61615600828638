import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { HttpClientProvider } from 'src/app/services/http-client';
import { SettingsProvider } from 'src/app/services/settings';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalClientFormComponent } from '../client-form/client-form.component';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { DebounceDirective } from 'src/app/directives/debounce.directive';
import { XtrasService } from 'src/app/services/xtras.service';

@Component({
  selector: 'modal-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    DebounceDirective,
  ],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class ModalLeadFormComponent implements OnInit {

  dataForm = this.fb.group({
    customer_id:      new FormControl(null),
    user_id:          new FormControl(null),
    product_id:       new FormControl(null),
    product_item_id:  new FormControl(null),
    detail:           new FormControl(null),
    channel_id:       new FormControl(null),
    category_id:      new FormControl(1 as any),
    type:             new FormControl('new'),
  });

  action:string = 'create';
  actionText:string = 'Crear';

  customers:any[] = [];
  filterCustomers: string = '';

  loadingProducts: boolean = false;
  noDataProducts: boolean = false;
  products:any[] = [];
  filterProducts: string = '';
  
  loadingSubProducts: boolean = false;
  noDataSubProducts: boolean = false;
  subProducts:any[] = [];
  filterSubProducts: string = '';

  loadingChannels: boolean = false;
  noDataChannels: boolean = false;
  channels:any[] = [];
  filterChannels: string = '';

  loadingUsers: boolean = false;
  noDataUsers: boolean = false;
  users:any[] = [];
  filterUsers: string = '';

  loadingCategories: boolean = false;
  noDataCategories: boolean = false;
  categories:any[] = [];

  user$: Observable<UserType>;

  customer:any = null;

  search: string  = '';
  
  customerName:any = null;
  
  id:any        = null;
  createdAt     = null;
  updatedAt     = null;

  type:any = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private alert: AlertService,
    public dialogRef: MatDialogRef<ModalLeadFormComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public xServices: XtrasService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.user$ = this.authService.user$;
    this.action = data?.action ?? 'create';
    if(this.action === 'create') {
        this.dataForm.get('customer_id')?.addValidators(Validators.required);
        this.dataForm.get('customer_id')?.updateValueAndValidity();
        this.dataForm.get('product_id')?.addValidators(Validators.required);
        this.dataForm.get('product_id')?.updateValueAndValidity();
        this.dataForm.get('product_item_id')?.addValidators(Validators.required);
        this.dataForm.get('product_item_id')?.updateValueAndValidity();
        this.dataForm.get('detail')?.addValidators(Validators.required);
        this.dataForm.get('detail')?.updateValueAndValidity();
        this.dataForm.get('channel_id')?.addValidators(Validators.required);
        this.dataForm.get('channel_id')?.updateValueAndValidity();
        this.dataForm.get('category_id')?.addValidators(Validators.required);
        this.dataForm.get('category_id')?.updateValueAndValidity();
        this.dataForm.get('type')?.addValidators(Validators.required);
        this.dataForm.get('type')?.updateValueAndValidity();
    }
    this.id = data?.id ?? null;
    if(this.id && (this.action == 'read' || this.action == 'edit')) {
      this.initializeApp();
    }
    this.type = data?.type ?? 'new';
    this.dataForm.get('type')?.patchValue(data?.type ?? 'new');
    this.loadData();
    this.dataForm.get('product_id')?.valueChanges.subscribe(data => {
      this.dataForm.get('product_item_id')?.patchValue(null);
      if(data) {
        this.dataSubProducts(data);
      }
    });
  }

  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/lead/read`), { id: this.id }).subscribe((result:any) => {
      console.log(result);
      if(result['status']&&result.item) {
        this.load.dismiss();
        if(result['item']) {
          this.dataForm.patchValue({
            customer_id:        result.item?.customer_id ?? null,
            user_id:            result.item?.user_id ?? null,
            product_id:         result.item?.product_id ?? null,
            product_item_id:    result.item?.product_item_id ?? null,
            detail:             result.item?.detail ?? null,
            channel_id:         result.item?.channel_id ?? null,
            category_id:        result.item?.category_id ?? null,
            type:               result.item?.type ?? null,
          });

          this.customerName = result.item?.customerName;
  
          this.createdAt = result.item?.created_at ?? null;
          this.updatedAt = result.item?.updated_at ?? null;

          if(this.action == 'read') {
            setTimeout(() => {
              this.dataForm.disable();
            }, 1);
          }
        }
      } else {
        this.xServices.getError(result, true, '/email-templates');
      }
    }, (error) => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit() {
    this.actionText = this.xServices.getAction(this.action);
  }

  loadData() {
    this.loadingProducts = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/product?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingProducts = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataProducts = false;
        this.products = result['items'];
      } else {
        this.noDataProducts = true;
      }
    }, error => {
      console.log(error);
      this.loadingProducts = false;
      this.noDataProducts = true;
    });

    this.loadingChannels = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/channel?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingChannels = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataChannels = false;
        this.channels = result['items'];
      } else {
        this.noDataChannels = true;
      }
      this.authService.user$.subscribe((data?: UserType) => {
        if(data) {
          this.dataForm.get('user_id')?.patchValue(data.id);
        }
      });
    }, error => {
      console.log(error);
      this.loadingChannels = false;
      this.noDataChannels = true;
    });

    this.loadingUsers = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingUsers = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataUsers = false;
        this.users = result['items'];
      } else {
        this.noDataUsers = true;
      }
    }, error => {
      console.log(error);
      this.loadingUsers = false;
      this.noDataUsers = true;
    });

    this.loadingCategories = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/category?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingCategories = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataCategories = false;
        this.categories = result['items'];
      } else {
        this.noDataCategories = true;
      }
    }, error => {
      console.log(error);
      this.loadingCategories = false;
      this.noDataCategories = true;
    });
  }

  dataSubProducts(id: any) {
    this.subProducts = [];
    this.loadingSubProducts = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/product-item?is_paginate=0&parent_id=${id}`)).subscribe(result => {
      console.log(result);
      this.loadingSubProducts = false;
      if(result['items']&&result['items'].length > 0) {
        this.dataForm.get('product_item_id')?.addValidators([Validators.required]);
        this.noDataSubProducts = false;
        this.subProducts = result['items'];
      } else {
        this.noDataSubProducts = true;
        this.dataForm.get('product_item_id')?.clearValidators();
      }
      this.dataForm.get('product_item_id')?.updateValueAndValidity();
    }, error => {
      console.log(error);
      this.loadingSubProducts = false;
      this.noDataSubProducts = true;
    });
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  async submitForm() {
    if(this.action != 'read'){
      let body = {};
      if(this.id) {
        body = { ...this.dataForm.value, id: this.id };
      } else {
        body = { ...this.dataForm.value };
      }

      body = this.filterEmptyFields(body);
      this.load.loadingShow();
      let formData = await this.xServices.getFormData(body);
      
      this.http.postRequest(SettingsProvider.getUrl(`model/lead/${this.action}`), formData).subscribe((result:any) => {
        if(result['status']) {
          this.toast.success(`Item ${(this.action== 'edit' ? 'editado' : 'creado')}`, (result['message'] ?? `El item fue ${(this.action== 'edit' ? 'editado' : 'creado')} exitosamente`), 4000);
          this.dialogRef.close({ reloadData: true });
        } else {
          this.xServices.getError(result, true, `/leads/${this.type}`, 'No se pudo procesar la información, intente nuevamente.');
        }
        this.load.dismiss();
      }, error => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

  filterEmptyFields(formData: { [key: string]: any }) {
    return Object.fromEntries(
      Object.entries(formData).filter(
        ([_, value]) => value !== null && value !== undefined && value !== ''
      )
    );
  }

  toStatus(value: string) {
    this.dataForm.get('category_id')?.patchValue(value);
  }

  createClient() {
    const dialogRef = this.dialog.open(ModalClientFormComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  changeCustomer(value:any) {
    console.log(value);
    this.customers = [];
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`search-customer?search=${value}`)).subscribe((result:any) => {
      console.log(result);
      if(result['status']) {
        this.customers = result['data'] ?? [];
      } else {
        this.xServices.getError(result, true, '/', 'No se pudo procesar la información, intente nuevamente.')
      }
      this.load.dismiss();
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

}