import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthInterface, AuthUserModel } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { BehaviorSubject, Observable, map, of } from 'rxjs';

export type UserType = AuthUserModel | null;

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private _user = new BehaviorSubject<UserType>(null);
	user$ = this._user.asObservable();

	get currentUserValue(): UserType {
		return this._user.value;
	}
	set currentUserValue(user: UserType) {
		this._user.next(user);
	}

	headers:any = null;
	
	constructor(
		private httpClient: HttpClient,
		private router: Router,
	) {
	}
	
	login(body:any){
		console.log(body)
		return this.httpClient.post(SettingsProvider.getUrl('solu-login'), body);
	}

	getAuth(){
		let auth:any = localStorage.getItem('auth') || '';
		if(auth) {
			let _auth:AuthInterface = JSON.parse(auth);
			return _auth;
		} else {
			return null;
		}
	}
	
	getToken(){
		let auth:any = localStorage.getItem('auth') || '';
		return this._getToken(auth) || null;
	}
	
	_getToken(auth:AuthInterface){
		if(auth) {
			let _auth:any = auth;
			if(JSON.parse(_auth)?.login) {
				return JSON.parse(_auth).access_token;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	logout(type?:any) {
		localStorage.clear();
		if(type&&type == 'expired') {
			// alert('Tu sesión a expirado.');
		}
		if(!this.getToken()) {
			this.router.navigate(['auth/']);
		}
	}

	successfullOAuthAuthorization(login:any, token:any, refreshtoken:any, expires_in:number) {
		let expireDate = new Date().getTime() + (1000 * expires_in);
		const auth:AuthInterface = { login: login, access_token: token, refresh_token: refreshtoken, expires_in: expireDate };
		localStorage.setItem('auth', JSON.stringify(auth));
	}

	oauthAuthorize(body:any): Observable<any>{
		return this.httpClient.post<AuthInterface>(SettingsProvider.getUrl('oauth-authorize'), body);
	}

	oauthorizeRedirectFromCrm(body:any): Observable<any>{
		return this.httpClient.post<AuthInterface>(SettingsProvider.getUrl('oauth-authorize-redirect-from-crm'), body);
	}

	getUserByToken(): Observable<UserType> {
		const auth = this.getAuth();
		if (!auth || !auth.access_token) {
			return of(null);
		}
		return this._getUserByToken(auth.access_token).pipe(
			map((data: any) => {
				// console.log(data);
				let itemData  = { ...data['user'], isAdmin: data['isAdmin'], menu: data['menu'], website: { current_organization: data['actual_organization'], organizations: data['organizations'], menu: data['menu'], applications: data['applications'], submenu: data['submenu'] || [] } }
				if(itemData&&itemData.actual_organization) {

					// Setear los colores según sistema
					// let root = document.documentElement;
					// root.style.setProperty('--primary-color', (itemData.actual_organization['primaryColor']||'#D0142C'));
					// root.style.setProperty('--second-color', (itemData.actual_organization['secondaryColor']||'#34C759'));
					// root.style.setProperty('--third-color', (itemData.actual_organization['tertiaryColor']||'#007EB6'));
				}
				return this.userData(itemData);
			})
		);
	}

	_getUserByToken(token: string): Observable<AuthUserModel> {
		// console.log(token);
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
		return this.httpClient.get<AuthUserModel>(SettingsProvider.getUrl('my-account'), {
            headers: httpHeaders,
        });
	}

	userData(user: UserType) {
		if (user) {
		  	this.currentUserValue = user;
		} else {
		  	this.logout();
		}
		return user;
	}
}
