import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { UserModel } from 'src/app/services/user-model';

import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { SettingsProvider } from 'src/app/services/settings';

declare var $: any;

@Component({
  selector: 'mailing-msg-modal',
  templateUrl: './msg-modal.page.html',
  styleUrls: ['./msg-modal.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule, MatSelectModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    LottieModule,
  ],
})
export class MailingMsgModalPage implements OnInit {

  title = 'Realizando difusión...';
  share = '';
  loading: boolean = true;
  content: string = 'Esto puede demorar unos minutos, puede cerrar esta ventana mientras espera';
  item = null;

  optionsAnimation: AnimationOptions = {
    path: 'assets/animations/sending.json',
    loop: true,
  };

  id:any = null;

  constructor(
    public xServices: XtrasService,
    private http: HttpClientProvider,
    public alert: AlertService,
    public dialogRef: MatDialogRef<MailingMsgModalPage>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.id = data.id ?? null;
    if(this.id) {
      this.http.getRequest(SettingsProvider.getUrl(`send-broadcast/${this.id}`)).subscribe(result => {
        console.log(result);
        if(result['status']) {
          this.optionsAnimation = {
            path: 'assets/animations/success.json',
            loop: false,
          };
          this.title = '¡Difusión exitosa!';
          this.content = result['message'] ?? 'Difusión realizada correctamente';
          this.loading = false;
        } else {
          this.optionsAnimation = {
            path: 'assets/animations/error.json',
            loop: false,
          };
          this.title = '¡Hubo un error!';
          this.content = result['message'] ?? 'Difusión realizada correctamente';
          this.loading = false;
        }
      }, error => {
        console.log(error);
        this.optionsAnimation = {
          path: 'assets/animations/error.json',
          loop: false,
        };
        this.title = '¡Hubo un error!';
        this.content = 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.';
        this.loading = false;
      });
    } else {
      this.optionsAnimation = {
        path: 'assets/animations/error.json',
        loop: false,
      };
      this.title = '¡Hubo un error!';
      this.content = 'Debe enviar el ID';
      this.loading = false;
    }
  }

  ngAfterViewInit(){
  }

  ngOnInit() {
    
  }

  dismiss(data?: any): void {
    this.dialogRef.close((data ?? null));
  }

}
