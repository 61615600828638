<div class="pt-5 lg:px-5 px-0 pb-[5rem] w-full container mx-auto">
  <div class="lg:grid lg:grid-cols-[minmax(0,100%)] flex md:flex-col flex-wrap gap-4 w-full">
    <section class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <div class="block">
        <div class="sm:col-span-6 col-span-12">
          <div class="flex flex-wrap gap-4 items-center justify-between mb-4">
            <div class="flex gap-4 items-center flex-wrap" >
              <h5 class="text-slate-800 font-bold text-lg inline-block relative m-0 pb-1 w-max">
                {{ title }}
              </h5>
              <span class="etq-socio etq-celeste" >Socio Celeste</span>
              <a (click)="back()" class="flex gap-1 items-center justify-center cursor-pointer">
                <i class="fa-regular fa-circle-left"></i> <span class="block leading-none">Volver</span>
              </a>
            </div>
            <!-- <div class="sm:col-span-6 col-span-12 text-right">
              <button type="button" class="w-auto text-third border border-third bg-white hover:bg-third hover:text-white focus:ring-4 focus:ring-third font-semibold rounded-lg text-sm px-4 py-2 focus:outline-none">
                <i class="fa fa-history" aria-hidden="true"></i> Historial
              </button>
            </div> -->

          </div>
          <p>Perfil del Socio </p>
        </div>
      </div>

      <form class="account-settings-form grid grid-cols-1" [formGroup]="dataForm">
        <div class="w-full mt-2 grid grid-cols-12 gap-4">

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Nombres</span>:</label>
            <input type="text" name="name" maxlength="255" formControlName="name" id="name" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="last_name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Apellido paterno</span>:</label>
            <input type="text" name="last_name" maxlength="255" formControlName="last_name" id="last_name" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="mother_last_name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Apellido materno</span>:</label>
            <input type="text" name="mother_last_name" maxlength="255" formControlName="mother_last_name" id="mother_last_name" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>
          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="ci" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">C.I</span>:</label>
            <input type="text" name="ci" maxlength="255" formControlName="ci" id="ci" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="email" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Correo electrónico</span>:</label>
            <input type="email" name="email" maxlength="255" formControlName="email" id="email" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="city_id" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Ciudad</span>:</label>
            <input type="text" name="city_id" maxlength="255" formControlName="city_id" id="city_id" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
            <!-- <select class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0" id="city_id" name="city_id" formControlName="city_id">
              <option value="">Seleccione una opción</option>
              <option *ngFor="let city of cities" [value]="city['id']">{{ city['name'] }}</option>
            </select> -->
          </div>
          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="address" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Dirección</span>:</label>
            <input type="text" name="address" maxlength="255" formControlName="address" id="address" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>
          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="cellphone" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Teléfono</span>:</label>
            <input type="phone" name="cellphone" maxlength="255" formControlName="cellphone" id="cellphone" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>
          
          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="birthday" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Fecha de nacimiento</span>:</label>
            <input type="text" name="birthday" maxlength="255" formControlName="birthday" id="birthday" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>
          
          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="genero" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Sexo</span>:</label>
            <input type="genero" name="genero" maxlength="255" formControlName="genero" id="genero" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
          </div>


        </div>

      </form>


    </section>
    <section class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">

      <div class="flex gap-1 items-center tabs-btn mb-4">
        <button class="{{tab1? 'active-tab' : ''}}" (click)="clickTabs('tab1')">Pagos pendientes</button>
        <button class="{{tab2? 'active-tab' : ''}}" (click)="clickTabs('tab2')">Pagos realizados</button>
        <button class="{{tab3? 'active-tab' : ''}}" (click)="clickTabs('tab3')">Pagos referidos</button>
      </div>

      <div *ngIf="tab1">
        <h4 class="title-tabs">Pagos pendientes</h4>

        <div class="block w-full pt-5">
          <app-cap-datatable [dataSource]="dataSource" [columnsdef]="columns" [loadingData]="loadingData" [noData]="noData"></app-cap-datatable>
        </div>

        
      </div>
      <div *ngIf="tab2">
        <h4 class="title-tabs">Pagos realizados</h4>
        <div class="block w-full pt-5">
          <app-cap-datatable [dataSource]="dataSource" [columnsdef]="columns" [loadingData]="loadingData" [noData]="noData"></app-cap-datatable>
        </div>
      </div>
      <div *ngIf="tab3">
        <h4 class="title-tabs">Pagos referidos</h4>
        <app-cap-datatable [dataSource]="dataSource" [columnsdef]="columns" [loadingData]="loadingData" [noData]="noData"></app-cap-datatable>
      </div>
      
    </section>

  </div>
</div>
