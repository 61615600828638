import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule, DatePipe } from '@angular/common';
// import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { DatatableComponent } from 'src/app/components/datatable-custom/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ModalLeadFormComponent } from 'src/app/components/lead-form/lead-form.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalLeadDistribuiteComponent } from 'src/app/components/distribuite/distribuite.component';
import { HttpParams } from '@angular/common/http';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { FilterModalComponent } from '../compontents/filter-modal/filter-modal.component';
import { ModalLeadReminderFormComponent } from '../../assigned-lead/compontents/reminder-create/form.component';
import { ModalRegisterActivityComponent } from '../../assigned-lead/compontents/register-activity/form.component';
import { ModalClientFormComponent } from 'src/app/components/client-form/client-form.component';
import { ReportModalComponent } from '../compontents/report/report.component';
import { ReportLeadsModalComponent } from '../compontents/report-leads/report.component';

declare var $: any;

@Component({
  selector: 'lead-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatatableComponent,
    PaginatorComponent,
    MatDialogModule,
    MatButtonModule,
    ButtonCapYeiComponent,
  ]
})
export class LeadListPage implements OnInit, OnDestroy {

  title = 'Leads';

  pagination = null;
  data:any[] = [];

  organizations:any = [];
  codOrganization = new FormControl(null);
  model = 'lead';

  isAdmin:boolean = false;

  noData: boolean = true;
  loadingData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[];

  visibilityBtns = {
    create: false,
    read: false,
    edit: false,
    remove: false,
  };
  type:any = null;

  user$: Observable<UserType>;

  role_id:any = null;
  user_id:any = null;

  formFilter:any = null;
  formReport:any = null;
  formReportLeads:any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClientProvider,
    private load: LoadingService,
    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private dialog: MatDialog,
    private alert: AlertService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.user$ = this.authService.user$;
    this.user$.subscribe(data => {
      this.role_id = data?.role_id;
      if(this.role_id == 1 || this.role_id == 3) {
        this.columns = [
          {id:'index',label:'#',hideOrder:0,width:100},
          // {id:'id',label:'ID',hideOrder:0,width:100},
          {id:'customer_ci_number',label:'C.I.',hideOrder:1,width:100},
          {id:'customer_item',label:'Nombre',hideOrder:2},
          {id:'product_name',label:'Producto',hideOrder:3},
          {id:'year',label:'Gestión',hideOrder:4,width:80},
          {id:'discharge_date',label:'Fecha de alta',hideOrder:5,width:120},
          {id:'cellphone',label:'Celular',hideOrder:6},
          {id:'email',label:'E-mail',hideOrder:7},
          {id:'owner_item',label:'Lead Owner',hideOrder:8},
          {id:'whatsapp',label:'WhatsApp',hideOrder:9},
          {id:'status',label:'Estado',hideOrder:2},
          {id:'detail',label:'Detalle',hideOrder:5},
          {id:'action_buttons',label:'Acciones',hideOrder:1,width:270},
        ];
      } else {
        this.columns = [
          {id:'index',label:'#',hideOrder:0,width:100},
          // {id:'id',label:'ID',hideOrder:0,width:100},
          {id:'customer_ci_number',label:'C.I.',hideOrder:1,width:100},
          {id:'customer_item',label:'Nombre',hideOrder:2},
          {id:'product_name',label:'Producto',hideOrder:3},
          {id:'year',label:'Gestión',hideOrder:4,width:80},
          {id:'discharge_date',label:'Fecha de alta',hideOrder:5,width:120},
          {id:'cellphone',label:'Celular',hideOrder:6},
          {id:'email',label:'E-mail',hideOrder:7},
          {id:'whatsapp',label:'WhatsApp',hideOrder:9},
          {id:'status',label:'Estado',hideOrder:2},
          {id:'detail',label:'Detalle',hideOrder:5},
          {id:'action_buttons',label:'Acciones',hideOrder:1,width:270},
        ];
      }
      this.user_id = data?.id;
      this.route.params.subscribe((data: any) => {
        if(data.type) {
          this.type = data.type ?? null;
          let form = JSON.parse(localStorage.getItem(`formLeads-${this.type ?? ''}`) ?? '{}') ?? null;
          this.formFilter = (form && Object.keys(form).length > 0) ? form : null;

          let formReport = JSON.parse(localStorage.getItem(`formReport-${this.type ?? ''}`) ?? '{}') ?? null;
          this.formReport = (formReport && Object.keys(formReport).length > 0) ? formReport : null;

          let formReportLeads = JSON.parse(localStorage.getItem(`formReportLeads-${this.type ?? ''}`) ?? '{}') ?? null;
          this.formReportLeads = (formReportLeads && Object.keys(formReportLeads).length > 0) ? formReportLeads : null;
          
          setTimeout(() => {
            this.initializeApp();
          }, 1);
        } else {

          let form = JSON.parse(localStorage.getItem(`formLeads-${this.type ?? ''}`) ?? '{}') ?? null;
          this.formFilter = (form && Object.keys(form).length > 0) ? form : null;

          let formReport = JSON.parse(localStorage.getItem(`formReport-${this.type ?? ''}`) ?? '{}') ?? null;
          this.formReport = (formReport && Object.keys(formReport).length > 0) ? formReport : null;

          let formReportLeads = JSON.parse(localStorage.getItem(`formReportLeads-${this.type ?? ''}`) ?? '{}') ?? null;
          this.formReportLeads = (formReportLeads && Object.keys(formReportLeads).length > 0) ? formReportLeads : null;

          setTimeout(() => {
            this.initializeApp();
          }, 1);
        }
      });
    });
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    if(action == 'read') {
      this.router.navigateByUrl(`/activity/lead/${item.customer_id}/${id}`);
    } else if(action == 'edit') {
      this.formAction('edit', id);
    }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    this.loadingData = true;
    this.noData = false;
    let data:any = {};
    
    if(this.type) {
      data = { type: this.type }
    }
    if(this.formFilter) {
      data = { ...data, ...this.formFilter};
      if(data?.product_item_ids != '') {
        data.product_item_ids = JSON.stringify(data?.product_item_ids);
      }
      if(data?.category_ids != '') {
        data.category_ids = JSON.stringify(data?.category_ids);
      }
    }
    
    let queryParams = new HttpParams({ fromObject: data });
    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`), null, queryParams).subscribe(result => {
      // console.log(result);
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      let items = {
        ...element,
        customer_item: (element?.customer&&element?.customer?.name) ? element?.customer.name : null,
        customer_ci_number: (element?.customer&&element?.customer?.ci_number) ? element?.customer.ci_number : null,
        source: (element?.channel&&element?.channel?.name) ? element?.channel?.name : null,
        product_name: element?.product_name ?? null,
        detail: element?.detail ?? null,
        email: (element?.customer&&element?.customer?.email) ? element?.customer?.email : null,
        cellphone: (element?.customer&&element?.customer?.cellphone) ? element?.customer?.cellphone : null,
        whatsapp: (element?.customer&&element?.customer?.cellphone) ? (`https://wa.me/591${element?.customer?.cellphone}`) : null,
        date: element['created_at'] ? this.xServices.formatDate(element['created_at']) : null,
        discharge_date: element['date'] ?? null,
        status: element?.category ? element?.category?.name : null,
        owner_item: (element?.user&&element?.user?.name) ? element?.user?.name : null,
      };
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      // if() {
        this.pagination = result['items'];
      // }
    } else {
      this.noData = true;
    }

    if(result['actions']) {
      this.showActionsButtons(result);
    }
  }

  statusData(status?: string) {
    if(!status) return null
    let statusLabel;
    switch (status) {
      case 'recent':
        statusLabel = 'Nuevo';
        break;
      case 'converted':
        statusLabel = 'Convertido';
        break;
      case 'pending':
        statusLabel = 'Pendiente';
        break;
      case 'retired':
        statusLabel = 'Retirado';
        break;
      default:
        statusLabel = 'Nuevo';
        break;
    }
    return `<div class="status status__${status}">${statusLabel}</div>`;
  }

  userData(name?: string, img?: string, email?: string, phone?: string) {
    if(name) {
      return `<div class="user-custom">
        ${img
          ? `<div class="img-custom">
            <img #itemImage src="${img}" alt="${name}" (error)="itemImage.srcset = 'assets/img/user.png'">
          </div>`
          : ''
        }
        <div class="info-custom">
          <h5>${name}</h5>
          ${email? `<p>${email}</p>` : ''}
          ${phone? `<p class="phone">${phone}</p>` : ''}
        </div>
      </div>`;
    } else {
      return `<div class="not__asign">Sin asignar</div>`;
    }
  }

  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          this.processData(result);
        } else {
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']){
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/active`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = {
      create: result['actions']['create'],
      read: result['actions']['read'],
      edit: result['actions']['edit'],
      remove: result['actions']['delete'],
    };
  }

  formAction(action: string, id?: any) {
    let type = null;
    if(action == 'create') {
      type = this.type ?? null;
    }
    const dialogRef = this.dialog.open(ModalLeadFormComponent, {
      width: '550px',
      maxWidth: '100%',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: {
        classComponent: 'full__hsize',
        action: action,
        id: id ?? null,
        type: type,
      }
      // panelClass: 'content_modal'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }

  distribuiteLeads() {
    const dialogRef = this.dialog.open(ModalLeadDistribuiteComponent, {
      width: '750px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      disableClose: false,
      autoFocus: false,
      // panelClass: 'content_modal'
      data: {
        type: this.type
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  filterModal() {
    const dialogRef = this.dialog.open(FilterModalComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      data: {
        form: this.formFilter ?? null,
        type: this.type ?? null,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result) {
        this.formFilter = result['data'] ?? null;
        this.initializeApp();
      }
    });
  }

  reportModal() {
    const dialogRef = this.dialog.open(ReportModalComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      data: {
        form: this.formReport ?? null,
        type: this.type ?? null,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result) {
        this.formReport = result['data'] ?? null;
        if(result['data']) {
          this.generateReport();
        }
      }
    });
  }

  reportLeadsModal() {
    const dialogRef = this.dialog.open(ReportLeadsModalComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      data: {
        form: this.formReportLeads ?? null,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result) {
        this.formReportLeads = result['data'] ?? null;
        if(result['data']) {
          this.generateLeadsReport();
        }
      }
    });
  }

  cleanFilters() {
    this.formFilter = null;
    this.initializeApp();
  }

  formLeadReminderCreate(customer: any, id: any, customerId: any) {
    const dialogRef = this.dialog.open(ModalLeadReminderFormComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: {
        classComponent: 'full__hsize',
        lead_id: id ?? null,
        customer_id: customerId ?? null,
        customer_name: customer?.name ?? null,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }

  registerActivity(user: any, id: any, customerId: any) {
    const dialogRef = this.dialog.open(ModalRegisterActivityComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      // height: '100%',
      disableClose: false,
      autoFocus: false,
      // position: {
      //   top: '0px',
      //   bottom: '0px',
      //   right: '0px',
      // },
      data: {
        fullname: user?.name ?? null,
        parent_id: id ?? null, // *Lead
        customer_id: customerId ?? null, // *Contact
        // classComponent: 'full__hsize'
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }

  editCustomer(id: any) {
    const dialogRef = this.dialog.open(ModalClientFormComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      data: {
        action: 'edit',
        id: id ?? null,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result&&result['data']) {
        this.initializeApp();
      }
    });
  }

  generateReport() {
    let form = this.formReport;
    if(form['start_date']) {
      form['start_date'] = this.datePipe.transform(form['start_date'], 'yyyy-MM-dd');
    }
    if(form['end_date']) {
      form['end_date'] = this.datePipe.transform(form['end_date'], 'yyyy-MM-dd');
    }
    let queryParams = new HttpParams({ fromObject: form ?? {} });
    this.load.loadingShowAlt();
    this.http.getRequest(SettingsProvider.getUrl(`reports/lead-status`), 'blob', queryParams).subscribe(result => {
      console.log(result);
      this.load.dismissAlt();
      if(result instanceof Blob) {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = (currentDate.getMonth() + 1) > 9 ? currentDate.getMonth() + 1: `0${currentDate.getMonth() + 1}`;
        let day = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
        let hour = currentDate.getHours();
        let minute = currentDate.getMinutes();
        
        let name: string = `Reporte_${year}${month}${day}_${hour}${minute}`;

        const blob = new Blob([result], { type: (result.type ?? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', name);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      }
    }, error => {
      this.load.dismissAlt();
      console.log(error);
      this.toast.error('Error al generar el reporte', 'No se pudo generar el reporte, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  generateLeadsReport() {
    let form = this.formReportLeads;
    if(form['start_date']) {
      form['start_date'] = this.datePipe.transform(form['start_date'], 'yyyy-MM-dd');
    }
    if(form['end_date']) {
      form['end_date'] = this.datePipe.transform(form['end_date'], 'yyyy-MM-dd');
    }
    form['type'] = this.type ?? '';
    console.log(form);
    let queryParams = new HttpParams({ fromObject: form ?? {} });
    this.load.loadingShowAlt();
    this.http.getRequest(SettingsProvider.getUrl(`reports/leads`), 'blob', queryParams).subscribe(result => {
      console.log(result);
      this.load.dismissAlt();
      if(result instanceof Blob) {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = (currentDate.getMonth() + 1) > 9 ? currentDate.getMonth() + 1: `0${currentDate.getMonth() + 1}`;
        let day = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
        let hour = currentDate.getHours();
        let minute = currentDate.getMinutes();
        
        let name: string = `Reporte de Leads_${year}${month}${day}_${hour}${minute}`;

        const blob = new Blob([result], { type: (result.type ?? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', name);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      }
    }, error => {
      this.load.dismissAlt();
      console.log(error);
      this.toast.error('Error al generar el reporte', 'No se pudo generar el reporte, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }
}
