import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule, DOCUMENT } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ModalRegisterActivityComponent } from '../compontents/register-activity/form.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalLeadReminderFormComponent } from '../compontents/reminder-create/form.component';
import {MatExpansionModule} from '@angular/material/expansion'
import { ModalNotaFormComponent } from '../compontents/nota/form.component';
import { ModalReunionFormComponent } from '../compontents/reunion/form.component';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
declare var $: any;

@Component({
  selector: 'activity-type',
  templateUrl: './type.page.html',
  styleUrls: ['./type.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatatableComponent,
    PaginatorComponent,
    MatDialogModule,
    MatButtonModule,
    MatExpansionModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
  ]
})
export class ActivityTypePage implements OnInit, OnDestroy {

  title = 'Actividades';

  dataForm = this.fb.group({
    id:           new FormControl(null, [Validators.required]),
    category_id:  new FormControl(null, [Validators.required]),
  });

  type = 'customer';
  model = 'get-contact-detail';

  item:any = null;
  history:any[] = [];
  user:any = null;
  lead:any = null;
  id:any = null;
  lead_id:any = null;

  loadingTypes:boolean = false;
  noDataTypes: boolean = false;
  filterTypes: string = '';
  types:any[] = [];

  @HostBinding('class') class = 'dContents'; // * Clase que se añade a este componente selector

  currentUrl = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    private http: HttpClientProvider,
    private load: LoadingService,
    public xServices: XtrasService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) document: any
  ) {
    $('.content_scroll-site').addClass('not__scroll'); // * Clase que se añade al main para no tener scroll
    this.route.params.subscribe((data?: any) => {
      this.currentUrl = document.location.href;
      this.lead_id = data?.lead_id ?? null;
      if(data&&data?.id) {
        this.type = data.type;
        if(this.type == 'contact') {
          this.model = 'get-contact-detail';
          this.title = 'Actividades del Contacto';
        } else if(this.type == 'lead') {
          this.model = 'get-lead-detail';
          this.title = 'Actividades del Lead';
        }
        this.id = data.id;
        setTimeout(() => {
          this.initializeApp();
        }, 1);
      }
    });
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.history = [];
    this.item = null;
    this.user = null;
    if(this.type == 'contact') {
      this.load.loadingShow();
      this.http.getRequest(SettingsProvider.getUrl(`${this.model}/${this.id}?redirect=${this.currentUrl}`)).subscribe(result => {
        this.load.dismiss();
        if(result['status']&&result['data']) {
          this.processData(result);
        } else {
          this.xServices.getError(result, true, '/account');
        }
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      });
    } else if(this.type == 'lead') {
      this.load.loadingShow();
      this.http.getRequest(SettingsProvider.getUrl(`${this.model}/${this.lead_id}?redirect=${this.currentUrl}`)).subscribe(result => {
        this.load.dismiss();
        if(result['status']&&result['data']) {
          this.processData(result);
        } else {
          this.xServices.getError(result, true, '/account');
        }
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      });
    }

    this.loadingTypes = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/category?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingTypes = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataTypes = false;
        this.types = result['items'];
      } else {
        this.noDataTypes = true;
      }
    }, error => {
      console.log(error);
      this.loadingTypes = false;
      this.noDataTypes = true;
    });
  }

  processData(result: any) {
    this.item = result['data']['customer'] ?? null;
    let history = result['data']['history'] ?? [];
    this.history = history.reduce((acc:any, curr:any) => {
      curr.items.forEach((item:any) => {
        item.type_history = curr.type;
      });
      return acc.concat(curr.items);
    }, []);
    
    this.history.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    this.user = result['data']['user'] ?? null;
    this.lead = result['data']['lead'] ?? null;
    if(this.lead&&this.lead['category_id']) {
      console.log('LEAD:', this.lead);
      this.dataForm.get('id')?.patchValue(this.lead['id']);
      this.dataForm.get('category_id')?.patchValue(this.lead['category_id']);
    }
  }

  ngOnDestroy(): void {
    $('.content_scroll-site').removeClass('not__scroll');
  }

  formCreate() {
    const dialogRef = this.dialog.open(ModalLeadReminderFormComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: {
        classComponent: 'full__hsize',
        lead_id: this.lead_id ?? null,
        customer_id: this.id ?? null,
        customer_name: this.item?.name ?? null,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }

  registerActivity() {
    const dialogRef = this.dialog.open(ModalRegisterActivityComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      // height: '100%',
      disableClose: false,
      autoFocus: false,
      // position: {
      //   top: '0px',
      //   bottom: '0px',
      //   right: '0px',
      // },
      data: {
        fullname: this.user?.name ?? null,
        parent_id: this.lead_id ?? null, // *Lead
        customer_id: this.id ?? null, // *Contact
        // classComponent: 'full__hsize'
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }

  nota() {
    const dialogRef = this.dialog.open(ModalNotaFormComponent, {
      width: '400px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '380px',
      disableClose: false,
      autoFocus: false,
      data: {
        fullname:'Eduardo Mejia Silva',
        parent_id: 1,
        customer_id: 1
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  reunion() {
    const dialogRef = this.dialog.open(ModalReunionFormComponent, {
      width: '300px',
      height: '300px',
      disableClose: false,
      autoFocus: false,
      data: {
        fullname: 'Eduardo Mejia Silva',
        parent_id: 1,
        customer_id: 1
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  statusData(status?: string) {
    if(!status) return '-'
    let statusLabel;
    switch (status) {
      case 'pending':
        statusLabel = 'Nuevo';
        break;
      case 'thinking':
        statusLabel = 'Analizando';
        break;
      case 'not_interested':
        statusLabel = 'No interesado';
        break;
      default:
        statusLabel = 'No renovará/Convertido';
        break;
    }
    return statusLabel;
  }

  back() {
    if(this.type == 'contact') {
      this.router.navigateByUrl('/contacts');
    } else if(this.type == 'lead') {
      if(this.lead&&this.lead['type']) {
        this.router.navigateByUrl(`/leads/${this.lead['type']}`);
      } else {
        this.router.navigateByUrl('/leads/new');
      }
    } else {
      this.router.navigateByUrl('/account');
    }
  }

  changeStatus(value:any) {
    this.dataForm.get('category_id')?.patchValue(value);
    console.log(this.dataForm.value);
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`post-lead-status`), this.dataForm.value).subscribe(result => {
      this.load.dismiss();
      if(result['status']) {
        this.initializeApp();
      } else {
        this.xServices.getError(result, true, '/account');
      }
    }, error => {
      this.load.dismiss();
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }
}
