<div class="w-full block">
  <div class="w-full block table_responsive p-6">
    <div class="w-full content_btns flex flex-wrap gap-4 justify-between items-start w-auto pb-4">
      <h5 class="text-slate-800 font-bold text-lg inline-block relative m-0 text-center">
        {{ title }}
      </h5>
    
      <form [formGroup]="dataForm" (submit)="dataInit()" class="max-w-full flex flex-wrap items-center gap-4">
        <div class="w-[600px] max-w-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4 items-center gap-2">
          <!-- <div class="w-full relative input__date type_full border border-[#F1F1F1]">
            <div class="input__date type__only">
              <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="date" (ngModelChange)="dateChange($event)" [ngModelOptions]="{standalone: true}">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div> -->

          <div class="w-full relative input__date type_full border border-[#F1F1F1]">
            <mat-date-range-input [rangePicker]="picker" class="input__date-item !bg-white">
              <input matStartDate readonly [(ngModel)]="start_date" (ngModelChange)="dateChange($event, 'start_date')" [ngModelOptions]="{standalone: true}">
              <input matEndDate readonly [(ngModel)]="end_date" (ngModelChange)="dateChange($event, 'end_date')" [ngModelOptions]="{standalone: true}">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
  
          <mat-select id="user_id" name="user_id" formControlName="user_id" placeholder="Por vendedor" class="custom__mat-select bg__white">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterSellers = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingSellers; else elseLoadingSellers">
              <ng-container *ngIf="xServices.lengthItems(sellers); else elseSellers">
                <mat-option *ngFor="let item of sellers | filter: filterSellers : 'fullname'" [value]="item['id']" >{{ item['fullname'] }}</mat-option>
              </ng-container>
              <ng-template #elseSellers>
                <mat-option [value]="null">No se encontraron vendedores</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingSellers>
              <mat-option [value]="null">Cargando vendedores...</mat-option>
            </ng-template>
          </mat-select>
        </div>
        <button-cy type="submit" addClass="btn__second">
          <i class="fa-light fa-filter"></i> Filtrar
        </button-cy>
        <button-cy (onPress)="clearFilter()" addClass="btn__primary" icon="fa-light fa-rotate-right" label="Limpiar filtros" [iconOnly]="true"></button-cy>
      </form>
    </div>

  
    <section class="grid-metas">
      
      <article *ngFor="let item of dataMetas">
        <div class="bg-grid">
          <h4 class="title-list">Meta {{item['name']}}</h4>
          <div class="flex items-center justify-center p-4 gap-4 flex-wrap">
            <div class="prg-text prg-text--medui">
              <span class="{{item['status']? '': 'txt-p-gray' }}">{{ (item['cant_sale_current']? item['cant_sale_current'] : 0) | number:'.0-2':'es-ES' }}/{{ (item['cant_sale_meta']? item['cant_sale_meta'] : 0) | number:'.0-2':'es-ES' }}</span>
              <!-- <ng-container [ngSwitch]="item['status']">
                <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
              </ng-container> -->

            </div>
            <ngx-gauge 
              [type]="gaugeType" 
              [value]="item['percent']"  
              [append]="gaugeAppendText"
              [foregroundColor]="gaugeColor"
              [backgroundColor]="gaugeColorBackground"
              [animate]="true"
              [duration]="1500"
              [size] = "130"
              [cap]="gaugeCap"
              [thick]="18"
            ></ngx-gauge>
          </div>
        </div>

        <div class="item-sales bg-grid mt-4">
          <h4 class="title-list mb-3">Ventas {{item['name']}}</h4>
          <div class="prg-text prg-text--big">
            <span>{{ item['total_money'] | number:'.0-2':'es-ES' }}</span> <strong>USD </strong>
            <!-- <ng-container [ngSwitch]="item['status']">
              <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
              <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
              <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
              <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
            </ng-container> -->
          </div>

          <ul class="ls--pall" *ngFor="let subItem of item['items'] ">
            <li class="grid grid-cols-[minmax(0,1fr)_minmax(0,80px)] gap-1 items-center justify-between">
              <div class="etq-dashboard">
                <span>
                  <div class="truncate" title="{{subItem['name_product']}}">{{subItem['name_product']}}</div>
                </span>
              </div>
              <div class="prg-text w-[80px]">
                <span>{{(subItem['cant'] ?? 0) | number:'.0-2':'es-ES'}}</span>
                <!-- <ng-container [ngSwitch]="subItem['status']">
                  <i class="fa fa-arrow-up txt-p-green" aria-hidden="true" *ngSwitchCase="'high'"></i>
                  <i class="fa fa-exchange txt-p-yellow" aria-hidden="true" *ngSwitchCase="'half'"></i>
                  <i class="fa fa-arrow-down txt-p-red" aria-hidden="true" *ngSwitchCase="'low'"></i>
                  <i class="fa fa-ban txt-p-gray" aria-hidden="true" *ngSwitchDefault></i>
                </ng-container> -->
              </div>
            </li>
          </ul>
        </div>
      </article>

    </section>
  </div>
</div>