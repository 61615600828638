<div class="w-full h-full block">
  <div class="w-full h-full grid grid-rows-[minmax(0,auto)_minmax(0,1fr)] p-6 md:px-8 px-1 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-center w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <div class="text-sm text-secondary border rounded-md px-2 py-1 font-medium">Informacion de la empresa</div>
      </div>
      
      <div class="w-auto max-w-full flex flex-wrap gap-2 items-center">
        <button mat-button (click)="addContact()" class="cy__btn btn__second">
          <i class="fa-light fa-plus"></i> Añadir Contacto
        </button>
      </div>
    </div>


    <div class="block w-full h-full pt-5">
      <div class="w-full h-full grid md:grid-cols-[minmax(0,380px)_minmax(0,1px)_minmax(0,1fr)] grid-cols-[minmax(0,100)_minmax(0,100)] ">
        <div class="w-full max-h-full overflow-auto p-4">

          <div class="border-b border-gray-200 pb-4 mb-4" >
            
            <h5 class="text-[#101828] font-semibold">Información</h5>
            <br>
            <div *ngIf="item" class="user-custom user-custom--big">
              <div class="img-custom default_img">
                <img src="assets/img/logo.png" alt="users">
              </div>
              <div class="info-custom">
                <h5>{{ item.name }}</h5>
                <p>Empresa</p>
              </div>
            </div>

          </div>
 

        </div>


        <div class="w-full hidden md:block h-[90%] bg-[#D0D5DD] self-center"></div>

        <div class="w-full h-full grid grid-rows-[minmax(0,auto)_minmax(0,1fr)]">
          <div class="p-4">
            <h5 class="text-[#101828] font-semibold m-0">Contatos de la empresa</h5>
          </div>
          <div class="block w-full max-h-full overflow-auto p-4">
            <div class="w-full">
              <!-- #FEFFE9 -->

              <div class="block w-full pt-5">
                <app-cap-datatable 
                  [dataSource]="dataSource" 
                  [columnsdef]="columns"
                  [loadingData]="loadingData" 
                  [noData]="noData">
                </app-cap-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>