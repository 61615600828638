import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { XtrasService } from "src/app/services/xtras.service";

@Component({
    selector: 'app-paginator',
    template: `
        <div class="w-auto max-w-full grid grid-cols-12 gap-2" *ngIf="pagination['from']&&pagination['last_page'] != 1">
            <div class="col-span-12 w-full flex items-center justify-center md:justify-start">
                <div class="w-auto text-sm">
                    {{ ((pagination['to'] ? pagination['to'] : 0) | number:'.0-2':'es-ES') +' de '+ ((pagination['total'] ?? 0) | number:'.0-2':'es-ES') }} Elementos
                </div>
            </div>
            <div class="col-span-12 flex items-center justify-center md:justify-start" *ngIf="xServices.lengthItems(pagination['links'])">
                <ul class="pagination_table-out">
                    <ng-container *ngFor="let pag of pagination['links']; let first = first; let last = last">
                        <ng-container *ngIf="first||last; else elsePag">
                            <ng-container *ngIf="first;">
                                <li class="paginate_button page-item previous">
                                    <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                                        <i class="fa-regular fa-left-long"></i>
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="last;">
                                <li class="paginate_button page-item next">
                                    <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                                        <i class="fa-regular fa-right-long"></i>
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                        <ng-template #elsePag>
                            <li class="paginate_button page-item">
                                <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                                    {{ pag['label'] }}
                                </a>
                            </li>
                        </ng-template>
                    </ng-container>
                </ul>
            </div>
        </div>
    `,
    standalone: true,
    imports: [CommonModule]
})
export class PaginatorComponent {

    @Input() pagination!: any;
    @Output() toPage = new EventEmitter<any>();

    constructor(public xServices: XtrasService) {

    }

    goToPaginate(arg0: any) {
        this.toPage.emit(arg0);
    }
}