import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'modal-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatRadioModule, MatSelectModule, FilterPipe, SearchDirective, MatSlideToggleModule],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class FilterModalComponent implements OnInit {

  dataForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
    ) {
    this.dataForm = new FormGroup({
      name:    new FormControl(''),
      cellphone:     new FormControl(''),
      ci_number:     new FormControl(''),
      created_at:     new FormControl(''),
    }) as FormGroup;
    if(data&&data.form) {
      this.dataForm.patchValue({
        name: data.form?.name ?? '',
        cellphone:  data.form?.cellphone ?? '',
        ci_number:  data.form?.ci_number ?? '',
        created_at:  data.form?.created_at ?? '',
      });
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm(data: any) {
    localStorage.setItem(`formContacts`, JSON.stringify(data ?? {}));
    this.dialogRef.close({data: data});
  }
}