import { ToastrModule } from 'ngx-toastr';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './services/interceptor.service';
import { HttpClientProvider } from './services/http-client';
import { AuthService } from './services/auth.service';
import { finalize } from 'rxjs';
import { UserModel } from './services/user-model';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

registerLocaleData(localeEs, 'es-ES');

function appInitializer(userService: AuthService) {
  return () => {
    return new Promise<void>((resolve) => {
      userService.getUserByToken().pipe(
        finalize(() => resolve())
      ).subscribe();
    });
  };
}

export function playerFactory() {
  return player
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializer,
        multi: true,
        deps: [AuthService],
    },
    DatePipe,
    importProvidersFrom(MatNativeDateModule),
    importProvidersFrom(MatDatepickerModule),
    importProvidersFrom(HttpClientProvider),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(AuthService),
    importProvidersFrom(UserModel),
    importProvidersFrom(LottieModule.forRoot({ player: playerFactory })),
    importProvidersFrom(ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      newestOnTop: false,
      preventDuplicates: true
    })),
    provideRouter(routes),
    provideAnimations()
  ]
};
