
import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';



@Component({
	selector: 'app-user-custom',
	styleUrls: [`./user-custom.component.scss`],
	templateUrl: `./user-custom.component.html`,
	standalone: true,
	imports: [CommonModule,]
})
export class UserCustomComponent implements  OnInit {

	@Input() img?: string; 
  	@Input() name!: string;
	@Input() email?: string; 
  	@Input() desc?: string;
	
	constructor(
		/* toastrService: ToastrService,
		toastPackage: ToastPackage, */
	) {
		
	}
	ngOnInit() {
		/* console.log(this.img_name, this.name_user, this.email, this.phone) */
	}

	

	
}