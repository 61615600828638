import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion'
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import Swal from 'sweetalert2';
import { ModalLeadReminderFormComponent } from '../../assigned-lead/compontents/reminder-create/form.component';
import { ModalRegisterActivityComponent } from '../../assigned-lead/compontents/register-activity/form.component';


@Component({
  selector: 'detail-business',
  templateUrl: './detail-business.page.html',
  styleUrls: ['./detail-business.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, DatatableComponent, PaginatorComponent, MatDialogModule, MatButtonModule, MatExpansionModule]
})
export class BusinessDetailPage implements OnInit, OnDestroy {

  title = 'Actividades';

  pagination = null;
  data:any = [];

  noData: boolean = false;

  dataForm = this.fb.group({
    fullname:      new FormControl('Eduardo Mejia Silva', [Validators.required]),
    ci_number:     new FormControl('7892601', [Validators.required]),
    email:         new FormControl('edumejia30@gmail.com', [Validators.required]),
    cellphone:     new FormControl('77777777', [Validators.required]),
    source:        new FormControl('Redes Sociales', [Validators.required]),
    status:        new FormControl('Convertido', [Validators.required]),
  });

  isAdmin:boolean = false;


  /* ========================================== */

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    /* {id:'check__list',label:'',hideOrder:0,width:70,check__list:true}, */
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'name',label:'Nombre',hideOrder:2,html:true},
    {id:'cellphone',label:'Télefono',hideOrder:3},
    {id:'created_at',label:'Fecha de Registro',hideOrder:5},
  ];

  create:boolean = true;
  loadingData: boolean = false;

  id:any = null;
  item:any = null;

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,
    private fb: FormBuilder,
    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private dialog: MatDialog,
    private alert: AlertService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((data:any) => {
      this.id = data.id ?? null;
      this.initializeApp();
    });
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/business-group/read`), { id: this.id }).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.load.dismiss();
        this.item = result['item'] ?? null;
      } else {
        this.load.dismiss();
        this.xServices.getError(result, true, '/business');
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });


    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    
    this.noData = false;
    this.loadingData = true;

    this.http.getRequest(SettingsProvider.getUrl(`model/business-group-contact?parent_id=${this.id}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        this.xServices.getError(result, false, '/business');
      }
    }, error => {
      console.log(error);
      // this.load.dismissAlt();
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      let _cellphone = { cellphone: `${ (element?.customer&&element?.customer?.cellphone) ? element?.customer?.cellphone : '-' }` }
      let _user = { name: element?.customer ? this.userTable(undefined, ((element?.customer&&element?.customer?.name) ? element?.customer?.name : null), ((element?.customer&&element?.customer?.email) ? element?.customer?.email : null)) : '-'}


      let items = {
        ...element,
        ..._cellphone,
        ..._user,
        created_at: this.xServices.formatDate(result['created_at']),
      };
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      this.pagination = result['items'];
    } else {
      this.noData = true;
    }

  }
  
  ngOnDestroy(): void {
  }

  formCreate() {
    const dialogRef = this.dialog.open(ModalLeadReminderFormComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: {
        classComponent: 'full__hsize'
      }
      // panelClass: 'content_modal'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  addContact() {
    const dialogRef = this.dialog.open(ModalRegisterActivityComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: { fullname: 'Eduardo Mejia Silva', parent_id: 1, customer_id: 1, classComponent: 'full__hsize' },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    if(action == 'remove') {
      this.removeItem((`"${item['name']}"`), id);
    } else if(action == 'active') {
      this.activeItem((`"${item['name']}"`), id);
    }
  }

  userTable(name_img:any, name_user:string, email?:string, phone?:string){
    return `<div class="user-custom">
      <div class="img-custom">
      ${ name_img? `<img src="${name_img}" alt="img">` : '<img src="assets/img/user.png" alt="img">'}
      </div>
      <div class="info-custom">
        <h5>${name_user}</h5>
        ${email? `<p>${email}</p>` : ``}
        ${phone? `<p class="phone">${phone}</p>` : ``}
      </div>
    </div>`;
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/business-group-contact/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

   activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/business-group-contact/active`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  listCheck(data: any) {
    console.log(data);
  }

  
}
