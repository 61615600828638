import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsProvider } from 'src/app/services/settings';
import { XtrasService } from 'src/app/services/xtras.service';
import { UserModel } from 'src/app/services/user-model';

import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-account-security',
  templateUrl: './account-security.page.html',
  styleUrls: ['./account-security.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class AccountSecurityPage implements OnInit, OnDestroy {

  private unsubscribe: Subscription[] = [];

  @Output() send = new EventEmitter<string>()

  title = 'Seguridad';

  item = null;
  fieldsCredentialPassword = {
    verify_password: null,
    password: null,
    password_confirmation: null
  }

  fieldsCredentialEmailPhone = {
    email: null,
    cellphone: null
  }

  password_old = null;
  
  constructor(
    private router: Router,
    private xServices: XtrasService,
    private metaTitle: Title,
    private userModel: UserModel,
    private http: HttpClientProvider,
    private toast: NotificationService,
    private load: LoadingService,
    private alert: AlertService,
    private authService: AuthService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.http.getRequest(SettingsProvider.getUrl(`my-account`)).subscribe((result:any) => {
      if(result['status']) {
        this.item = result['user'];
        this.fieldsCredentialEmailPhone = {
          email: result['user']['email'],
          cellphone: result['user']['cellphone']
        }
        if(result['user']['password']) {
          this.password_old = result['user']['password'];
        }
      } else {
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
      }
    }, error => {
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      this.router.navigateByUrl('/');
      // this.errorPage();
    });
  }

  submitFormPassword() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`change-password`), this.fieldsCredentialPassword).subscribe(result => {
      if(result['status']) {
        this.load.dismiss();
        if(result['message']){
          this.toast.success('Contraseña Actualizada', result['message'], 4000);
        } else {
          this.toast.success('Contraseña Actualizada', 'La contraseña fue actualizada exitosamente', 4000);
        }
        // console.log(this.item);
        this.router.navigateByUrl('/');
      } else {
        this.load.dismiss();
        if(result['errors']) {
          this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
        } else {
          this.toast.error('Error al actualizar la contraseña', 'No se pudo actualizar los datos, intente nuevamente.', 4000);
        }
      }
    }, error => {
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      this.router.navigateByUrl('/');
      // this.errorPage();
    });
  }

  submitFormEmailPhone() {
    this.load.loadingShow();
    const dataUserSubscr = this.http.postRequest(SettingsProvider.getUrl(`change-security-info`), this.fieldsCredentialEmailPhone).subscribe(result => {
      if(result['status']) {
        this.authService.getUserByToken().subscribe();
        this.load.dismiss();
        if(result['message']){
          this.toast.success('Información General Actualizada', result['message'], 4000);
        } else {
          this.toast.success('Información General Actualizada', 'La información general fue actualizada exitosamente', 4000);
        }
        this.router.navigateByUrl('/');
        // console.log(this.item);
      } else {
        this.load.dismiss();
        if(result['errors']) {
          this.errorAlert(result['errors']);
        } else {
          this.toast.error('Error al actualizar la información', 'No se pudo actualizar los datos, intente nuevamente.', 4000);
        }
      }
    }, error => {
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      this.router.navigateByUrl('/');
      // this.errorPage();
    });

    this.unsubscribe.push(dataUserSubscr);
  }

  errorAlert(errors?:any) {
    errors = errors ? errors : 'No se pudo actualizar los datos, intente nuevamente.';
    Swal.fire({
      title: 'Error al actualizar la información',
      html: errors,
      icon: 'error',
      // showCancelButton: false,
      confirmButtonText: 'Entendido',
    });
  }
  
  ngAfterViewInit(){
    
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
    $(document).ready(function(){
      // alert('JQuery is working!!');
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
