import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { SearchDirective } from 'src/app/directives/search.directive';
import { FilterPipe } from 'src/app/pipes/filter';
import { XtrasService } from 'src/app/services/xtras.service';
import { ButtonCapYeiComponent } from '../button/button.component';
import { AlertService } from 'src/app/services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'modal-lead-distribuite',
  templateUrl: './distribuite.component.html',
  styleUrls: ['./distribuite.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    ButtonCapYeiComponent,
  ],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }],

})
export class ModalLeadDistribuiteComponent implements OnInit {

  dataForm = this.fb.group({
    channel_id:   new FormControl(null, [Validators.required]),
    user_ids:     new FormControl([]),
    automatic:    new FormControl(0),
  });

  loadingChannels: boolean = false;
  noDataChannels: boolean = false;
  channels:any[] = [];
  filterChannels: string = '';

  loadingSellers: boolean = false;
  noDataSellers: boolean = false;
  sellers:any[] = [];
  filterSellers: string = '';

  sellersSelected: any[] = [];

  type = 'new';

  constructor(
    private fb: FormBuilder,
    public xServices: XtrasService,
    private alert: AlertService,
    private http: HttpClientProvider,
    private toast: NotificationService,
    private load: LoadingService,
    public dialogRef: MatDialogRef<ModalLeadDistribuiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.type = data.type ?? 'new';
    this.initializeApp();
  }

  ngOnInit() {
  }

  initializeApp() {
    this.loadingChannels = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/channel?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingChannels = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataChannels = false;
        this.channels = result['items'];
      } else {
        this.noDataChannels = true;
      }
    }, error => {
      console.log(error);
      this.loadingChannels = false;
      this.noDataChannels = true;
    });

    this.loadingSellers = true;
    //* "sellers are role 5"
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingSellers = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataSellers = false;
        this.sellers = result['items'];
      } else {
        this.noDataSellers = true;
      }
    }, error => {
      console.log(error);
      this.loadingSellers = false;
      this.noDataSellers = true;
    });
  }

  dismiss(data?:any): void {
    this.dialogRef.close((data ?? null));
  }

  submitForm(auto: number = 0) {
    this.dataForm.get('automatic')?.patchValue(auto);
    if(!auto&&this.dataForm.get('user_ids')?.value?.length == 0) {
      this.alert.alertCapYei('Distribuir Leads', 'info', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'Debe seleccionar al menos un vendedor.');
      return;
    }
    console.log(this.dataForm.value);
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl('post-lead-automatic'), this.dataForm.value).subscribe(result => {
      this.load.dismiss();
      if(result['status']) {
        this.toast.success('¡Éxito!', (result['message'] ?? 'Item creado correctamente.'));
        this.dismiss({'reloadData': true});
      } else {
        this.xServices.getError(result, true, `/leads/${this.type}`);
      }
    }, error => {
      this.load.dismiss();
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
    
  }

  checkItem(id: any) {
    if(!id) return;
    let index = this.sellersSelected.indexOf(id);
    if (index !== -1) {
      // Si el id ya existe en el array, lo elimina
      this.sellersSelected.splice(index, 1);
    } else {
      // Si el id no existe en el array, lo añade
      this.sellersSelected.push(id);
    }
  }

  existSeller(_id: any) {
    return this.sellersSelected.some((id:any) => id == _id);
  }

}