import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { ModalLeadFormComponent } from 'src/app/components/lead-form/lead-form.component';
// import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { DatatableComponent } from 'src/app/components/datatable-custom/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { HttpParams } from '@angular/common/http';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';

@Component({
  selector: 'assigned-lead-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatatableComponent,
    PaginatorComponent,
    MatDialogModule,
    MatButtonModule,
    ButtonCapYeiComponent,
  ]
})
export class AssignedLeadListPage implements OnInit, OnDestroy {

  title = 'Leads Asignados';

  pagination = null;
  data:any = [];

  model = 'lead';

  noData: boolean = false;
  loadingData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:0,width:100},
    {id:'customer_item',label:'Nombre',hideOrder:1},
    {id:'cellphone',label:'Celular',hideOrder:2},
    {id:'source',label:'Fuente',hideOrder:3},
    {id:'status',label:'Estado',hideOrder:5},
    {id:'next_action',label:'Sig. acción',hideOrder:4},
    {id:'action_buttons',label:'Acciones',hideOrder:1},
  ];

  visibilityBtns = {
    create: false,
    read: false,
    edit: false,
    remove: false,
  };
  form = {};

  public buttonsNextAction: Array<any> = [];

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,

    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private dialog: MatDialog,
    private alert: AlertService,
    private authService: AuthService,
  ) {
    this.authService.user$.subscribe((data?: UserType) => {
      if(data) {
        this.form = { user_id: data.id };
        this.initializeApp();
      }
    });
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    console.log(id, item);
    if(action == 'read') {
      // this.router.navigate([`/${this.model}`, action, id]);
      // this.router.navigate([`/assigned-lead`, id]);
      this.router.navigateByUrl(`/activity/lead/${item.customer_id}/${id}`);
    }

  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    this.noData = false;
    this.loadingData = true;
    let queryParams = new HttpParams({ fromObject: this.form });
    console.log(queryParams);
    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`), null, queryParams).subscribe((result: any) => {
      console.log(result);
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;

      let items = {
        ...element,
        customer_item: (element?.customer&&element?.customer?.name) ? element?.customer.name : null,
        cellphone: (element?.customer && element?.customer?.cellphone) ? element?.customer?.cellphone : null,
        source: (element?.channel&&element?.channel?.name) ? element?.channel?.name : null,
        date: element['created_at'] ? this.xServices.formatDate(element['created_at']) : null,
        status: this.statusData(element['status']),
        next_action: null,
      };
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      // if() {
        this.pagination = result['items'];
      // }
    } else {
      this.noData = true;
    }

    if(result['actions']) {
      this.showActionsButtons(result);
    }
  }

  nextAction(action: any) {
    // console.log(action);
    this.load.loadingShow();
    setTimeout(() => {
      this.load.dismiss();
      window.open(action, "_blank");
    }, 1000);
  }

  userData(name?: string, img?: string, email?: string, phone?: string) {
    if(name) {
      return `<div class="user-custom">
        <div class="img-custom">
        ${ img ? `<img src="${img}" alt="${name}">` : `<img src="assets/img/user.png" alt="${name}">`}
        </div>
        <div class="info-custom">
          <h5>${name}</h5>
          ${email? `<p>${email}</p>` : ''}
          ${phone? `<p class="phone">${phone}</p>` : ''}
        </div>
      </div>`;
    } else {
      return `<div class="not__asign">Sin asignar</div>`;
    }
  }

  statusData(status?: string) {
    if(!status) return null
    let statusLabel;
    switch (status) {
      case 'pending':
        statusLabel = 'Nuevo';
        break;
      case 'thinking':
        statusLabel = 'Analizando';
        break;
      case 'not_interested':
        statusLabel = 'No interesado';
        break;
      default:
        statusLabel = 'No renovará/Convertido';
        break;
    }
    return `<div class="status status__${status}">${statusLabel}</div>`;
  }

  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          this.processData(result);
        } else {
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']){
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/active`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = {
      create: result['actions']['create'],
      read: result['actions']['read'],
      edit: result['actions']['edit'],
      remove: result['actions']['delete'],
    };
  }

  formCreate() {
    const dialogRef = this.dialog.open(ModalLeadFormComponent, {
      width: '550px',
      maxWidth: '100%',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: {
        classComponent: 'full__hsize'
      }
      // panelClass: 'content_modal'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }

  getDataAction(element: any) {
    if (element.dataset.action) {
      return element.dataset.action;
    } else if (element.parentElement) {
      this.getDataAction(element.parentElement);
    }
    return null;
  }
}