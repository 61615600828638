import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SearchDirective } from 'src/app/directives/search.directive';
import { FilterPipe } from 'src/app/pipes/filter';
import { HttpClientProvider } from 'src/app/services/http-client';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsProvider } from 'src/app/services/settings';

@Component({
  selector: 'modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule, FilterPipe, SearchDirective, MatFormFieldModule, MatDialogModule, MatButtonModule],
})
export class ModalBroadcastFormComponent implements OnInit {

  title = 'Difusión';

  action: string = 'create';
  actionText:string = 'Crear';

  dataForm:any = this.fb.group({
    type:                 new FormControl(null, [Validators.required]),
    template_email_id:    new FormControl(null),
    template_message_id:  new FormControl(null),
    list_group_id:        new FormControl(null,  [Validators.required]),
  });

  filterTemplate:string = '';
  loadingTemplate:boolean = false;
  noDataTemplate:boolean = false;
  types:any[] = [
    { id: 'email', name: 'E-mail'},
    { id: 'whatsapp', name: 'WhatsApp'},
    { id: 'sms', name: 'SMS'},
  ];

  filterTemplateEmail:string = '';
  loadingTemplateEmail:boolean = false;
  noDataTemplateEmail:boolean = false;
  templateEmails:any[] = [];

  filterTemplateMessage:string = '';
  loadingTemplateMessage:boolean = false;
  noDataTemplateMessage:boolean = false;
  templateMessages:any[] = [];

  filterListContact:string = '';
  loadingListContact:boolean = false;
  noDataListContact:boolean = false;
  listContact:any[] = [];

  typeSelected:any = null;
  id:any        = null;
  createdAt     = null;
  updatedAt     = null;
  
  constructor(
    private load: LoadingService,
    public xServices: XtrasService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<ModalBroadcastFormComponent>,
    private http: HttpClientProvider,
    private toast: NotificationService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = data?.action ?? 'create';
    this.id = data?.id ?? null;
    if(this.id && (this.action == 'read' || this.action == 'edit')) {
      this.initializeApp();
    }
    this.dataForm.get('type')?.valueChanges.subscribe((elm: any) => {
      console.log(elm);
      this.validateField(elm);
    });
  }
  
  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/broadcast/read`), { id: this.id }).subscribe((result:any) => {
      console.log(result);
      if(result['status']) {
        this.load.dismiss();
        if(result['item']) {
          this.dataForm.patchValue({
            type:                 result.item?.type ?? null,
            template_email_id:    result.item?.template_email_id ?? null,
            template_message_id:  result.item?.template_message_id ?? null,
            list_group_id:        result.item?.list_group_id ?? null,
          });
  
          this.createdAt = result.item?.created_at ?? null;
          this.updatedAt = result.item?.updated_at ?? null;

          if(this.action == 'read') {
            setTimeout(() => {
              this.dataForm.disable();
            }, 1);
          }
        }
      } else {
        this.xServices.getError(result, true, '/email-templates');
      }
    }, (error) => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  validateField(data: any) {
    this.typeSelected = data;
    this.dataForm.get('template_message_id')?.patchValue(null);
    this.dataForm.get('template_email_id')?.patchValue(null);
    switch (data) {
      case 'email':
        this.dataForm.get('template_message_id')?.clearValidators();
        this.dataForm.get('template_message_id')?.updateValueAndValidity();
        
        this.dataForm.get('template_email_id')?.setValidators([Validators.required]);
        this.dataForm.get('template_email_id')?.updateValueAndValidity();
        break;
        
      case 'whatsapp':
        this.dataForm.get('template_email_id')?.clearValidators();
        this.dataForm.get('template_email_id')?.updateValueAndValidity();
        
        this.dataForm.get('template_message_id')?.clearValidators();
        this.dataForm.get('template_message_id')?.updateValueAndValidity();
        break;

      case 'sms':
        this.dataForm.get('template_email_id')?.clearValidators();
        this.dataForm.get('template_email_id')?.updateValueAndValidity();
        
        this.dataForm.get('template_message_id')?.clearValidators();
        this.dataForm.get('template_message_id')?.updateValueAndValidity();
        break;
    
      default:
        this.dataForm.get('template_email_id')?.clearValidators();
        this.dataForm.get('template_email_id')?.updateValueAndValidity();
        this.dataForm.get('template_message_id')?.clearValidators();
        this.dataForm.get('template_message_id')?.updateValueAndValidity();
        break;
    }
  }

  ngOnInit() {
    this.actionText = this.xServices.getAction(this.action);
    this.init();
  }

  init() {
    this.loadingTemplateEmail = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/template-email?is_paginate=0`)).subscribe((result:any) => {
      console.log(result);
      this.loadingTemplateEmail = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataTemplateEmail = false;
        this.templateEmails = result['items'];
      } else {
        this.noDataTemplateEmail = true;
      }
    }, error => {
      console.log(error);
      this.loadingTemplateEmail = false;
      this.noDataTemplateEmail = true;
    });

    this.loadingTemplateMessage = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/template-message?is_paginate=0`)).subscribe((result:any) => {
      console.log(result);
      this.loadingTemplateMessage = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataTemplateMessage = false;
        this.templateMessages = result['items'];
      } else {
        this.noDataTemplateMessage = true;
      }
    }, error => {
      console.log(error);
      this.loadingTemplateMessage = false;
      this.noDataTemplateMessage = true;
    });

    this.loadingListContact = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/list-group?is_paginate=0`)).subscribe((result:any) => {
      console.log(result);
      this.loadingListContact = false;
      if(result['items']&&result['items'].length > 0) {
        this.noDataListContact = false;
        this.listContact = result['items'];
      } else {
        this.noDataListContact = true;
      }
    }, error => {
      console.log(error);
      this.loadingListContact = false;
      this.noDataListContact = true;
    });
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  async submitForm() {
    if(this.action != 'read'){
      let body = {};
      if(this.id) {
        body = { ...this.dataForm.value, id: this.id };
      } else {
        body = { ...this.dataForm.value };
      }
      this.load.loadingShow();
      let formData = await this.xServices.getFormData(body);

      this.http.postRequest(SettingsProvider.getUrl(`model/broadcast/${this.action}`), formData).subscribe((result:any) => {
        console.log(result);
        if(result['status']) {
          this.load.dismiss();
          this.dialogRef.close({ reloadData: true });
        } else {
          this.xServices.getError(result, true, '/broadcast');
        }
      }, (error) => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

}
