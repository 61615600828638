import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SettingsProvider } from './settings';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';


// ** EL TOKEN SE ENVÍA INICIALMENTE EN EL "SERVICIO TOKEN-INTERCEPTOR.SERVICE.TS" ----- *


@Injectable({
  providedIn: 'root',
})
export class HttpClientProvider {

  timeout_value: number = 40000;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    public http: HttpClient,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    console.log('Hello HttpClientProvider Provider');
  }

  getRequest(endpoint: string, responseTyp?: any, params?: any): Observable<any> {
    this.isLoadingSubject.next(true);
    let data:any;
    if(responseTyp) {
      if(params) {
        data = {params: params, responseType: responseTyp}
      } else {
        data = {responseType: responseTyp}
      }
    } else {
      if(params) {
        data = {params: params}
      }
    }
    return this.http.get(endpoint, data /*, {headers: headers}).timeout(this.timeout_value*/).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  postRequest(endpoint: string, body: any) : Observable<any>{
    this.isLoadingSubject.next(true);
    return this.http.post(endpoint, body).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  customRequest(endpoint: string, method: 'POST' | 'GET', body?:any, params?:HttpParams) : Observable<any> {
    this.isLoadingSubject.next(true);
    let data:any;
    if(params) {
      data = {params: params}
    }
    if(method == 'POST') {
      if(body) {
        return this.http.post(endpoint, body, data).pipe(
          timeout(10000),
          catchError(error => {
            this.isLoadingSubject.next(false);
            if (!window.navigator.onLine) {
              // Si no hay conexión a Internet
              console.error('No hay conexión a Internet');
              return throwError('Error, no hay conexión a Internet');
            } else {
              // Otros errores
              return throwError(error);
            }
          }),
          finalize(() => this.isLoadingSubject.next(false))
        );
      } else {
        this.isLoadingSubject.next(false);
        return throwError('Error, Necesita pasar el BODY en este método');
      }
    } else {
      return this.http.get(endpoint, data).pipe(
        timeout(10000),
        catchError(error => {
          this.isLoadingSubject.next(false);
          if (!window.navigator.onLine) {
            // Si no hay conexión a Internet
            console.error('No hay conexión a Internet');
            return throwError('Error, no hay conexión a Internet');
          } else {
            // Otros errores
            return throwError(error);
          }
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    }
  }

  multipleGetRequest(endpoints: any) : Observable<any>{
    this.isLoadingSubject.next(true);
    let responses = [];
    let response1 = this.http.get(SettingsProvider.getUrl(endpoints[0]))
    let response2 = this.http.get(SettingsProvider.getUrl(endpoints[1]))
    let response3 = null;
    if(endpoints.length > 2) {
      response3 = this.http.get(SettingsProvider.getUrl(endpoints[2]))
    }

    if(endpoints.length > 2) {
      responses = [response1, response2, response3];
    } else {
      responses = [response1, response2];
    }

    return forkJoin(responses).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

}
