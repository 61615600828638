<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>
      
      <div *ngIf="visibilityBtns.create" class="w-auto max-w-full flex flex-wrap gap-2">
        <button mat-button (click)="openForm('create')" class="cy__btn btn__primary">
          <i class="fa-light fa-plus"></i> Nuevo Empresa
        </button>
      </div>
    </div>

    <div class="block w-full pt-5">
      <datatable-capyei
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [loadingData]="loadingData"
        [noData]="noData"
        [customTemplate]="customTemplate">
        <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
          <ng-container [ngSwitch]="column.id">
            <ng-container *ngSwitchCase="'status'">
              <span class="rounded-full py-1 px-2 text-xs" [ngClass]="item ? 'bg-green-500/5 text-green-500' : 'bg-red-500/5 text-red-500'">{{ item ? 'Activo' : 'Inactivo' }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'action_buttons'">
              <button-cy (onPress)="clickEvent(i, 'view-list', row['id'], row)" label="Contactos" addClass="btn-action warning" icon="fa-light fa-list-ul" [iconOnly]="true" />
              <button-cy *ngIf="visibilityBtns.read" (onPress)="clickEvent(i, 'read', row['id'], row)" label="Ver" addClass="btn-action info" icon="fa-light fa-eye" [iconOnly]="true" />
              <button-cy *ngIf="visibilityBtns.edit" (onPress)="clickEvent(i, 'edit', row['id'], row)" label="Editar" addClass="btn-action success" icon="fa-light fa-edit" [iconOnly]="true" />
              <button-cy *ngIf="visibilityBtns.remove" (onPress)="clickEvent(i, 'remove', row['id'], row)" label="Eliminar" addClass="btn-action danger" icon="fa-light fa-trash" [iconOnly]="true" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="two_line-hidden">{{ item ?? '-' }}</span>
            </ng-container>
          </ng-container>
        </ng-template>
      </datatable-capyei>
    </div>
  </div>
</div>