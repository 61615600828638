import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { XtrasService } from 'src/app/services/xtras.service';
import { Observable } from 'rxjs';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'modal-report',
  templateUrl: './report.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
  ],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  ]
})
export class ReportLeadsModalComponent implements OnInit {

  dataForm: FormGroup;

  loadingUsers:boolean = false;
  filterUsers: string = '';
  users:any[] = [];

  user$: Observable<UserType>;

  type = '';

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private http: HttpClientProvider,
    public xServices: XtrasService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ReportLeadsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
    ) {
    this.user$ = this.authService.user$;
    this.dataForm = new FormGroup({
      start_date:         new FormControl(''),
      end_date:         new FormControl(''),
      user_id:            new FormControl(''),
    }) as FormGroup;
    if(data&&data.form) {
      this.dataForm.patchValue({
        start_date:  data.form?.start_date ?? '',
        end_date:  data.form?.end_date ?? '',
        user_id:  data.form?.user_id ?? '',
      });
    }
    this.initializeApp();
  }

  initializeApp() {
    this.loadingUsers = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingUsers = false;
      if(result['items']&&result['items'].length > 0) {
        this.users = result['items'];
      } else {
      }
    }, error => {
      console.log(error);
      this.loadingUsers = false;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm(data:any) {
    if(this.type) {
      localStorage.setItem(`formReportLeads-${this.type}`, JSON.stringify(data ?? {}));
    }
    this.dialogRef.close({data: data});
  }
}