import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {
	
	url = '/auth';
	
	constructor(private authService:AuthService,private router:Router){
		console.log('init here');
	}
	
	canActivate(){
		if(this.authService.getToken()){
			return true;
		} else {
			console.log(this.authService.getToken());
			return this.router.parseUrl(this.url);
		  	// return false;
		}
	}
	
}
