import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';

import { XtrasService } from 'src/app/services/xtras.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import {UserCustomComponent} from 'src/app/components/user-custom/user-custom.component';
import {MatProgressBarModule} from '@angular/material/progress-bar'; 
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpParams } from '@angular/common/http';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';



declare var $: any;

@Component({
  selector: 'sales-force-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatatableComponent,
    UserCustomComponent,
    MatProgressBarModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    ButtonCapYeiComponent,
  ]
})
export class SalesForceListPage implements OnInit, OnDestroy {

  title = 'Fuerza de Ventas';
  data:any = [];

  salesForce:any[] = [];
  comunications:any[] = [];

  loadingData: boolean = false;

  filterTemplate:string = '';
  loadingTemplate:boolean = false;
  noDataTemplate:boolean = false;
  templates:any[] = [];

  range = new FormGroup({
    start_date: new FormControl('' as any),
    end_date: new FormControl('' as any),
  });

  start_date: Date | null = null;
  end_date: Date | null = null;

  constructor(
    private metaTitle: Title,
    private userModel: UserModel,
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private router: Router,
    private alert: AlertService,
    private toast: NotificationService,
    private datePipe: DatePipe,
  ) {
    let formValues = JSON.parse(localStorage.getItem('formReportSalesForce') ?? '{}');
    if (formValues && Object.keys(formValues).length > 0) {
      this.start_date = formValues.start_date_format ? new Date(formValues.start_date_format) : null;
      this.end_date = formValues.end_date_format ? new Date(formValues.end_date_format) : null;
      this.range.patchValue(formValues);
    }

    this.initializeApp();
  }

  initializeApp() {
    let form = {
      ...this.range.value,
      start_date_format: this.start_date,
      end_date_format: this.end_date,
    };
    localStorage.setItem('formReportSalesForce', JSON.stringify(form));

    this.salesForce = [];
    this.comunications = [];
    this.load.loadingShow();
    let queryParams = new HttpParams({ fromObject: this.range.value });
    this.http.getRequest(SettingsProvider.getUrl(`get-report-sale-force`), null, queryParams).subscribe((result: any) => {
      console.log(result);
      if(result['status']) {
        this.salesForce = result?.data ? (result.data['salesForce'] ?? []) : [];
        this.comunications = result?.data?.comunications ?? [];
        this.load.dismiss();
      } else {
        this.load.dismiss();
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  ngOnDestroy(): void {
  }

  dateChange(event: any, field: any) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.range.get(field)?.patchValue(date);
  }

  clearFilter() {
    this.start_date = null;
    this.end_date = null;
    this.range.patchValue({
      start_date:         '',
      end_date:           '',
    });
    localStorage.setItem('formReportSalesForce', JSON.stringify({}));
    setTimeout(() => {
      this.initializeApp();
    }, 1);
  }
}
