<div mat-dialog-title class="!m-0 w-full block before:!hidden">
  <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300 uppercase">
    {{ actionText }} {{ title }}
  </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
  <div class="block w-full p-6">
    <form class="account-settings-form" (ngSubmit)="submitForm()" [formGroup]="dataForm">
      <div class="w-full mt-2 grid grid-cols-1 gap-4">

        <div class="w-full">
          <label for="type" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Tipo de Template</span>:</label>
          <mat-select id="type" name="type" formControlName="type" placeholder="Seleccione una opción" class="custom__mat-select">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterTemplate = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingTemplate; else elseLoadingTemplate">
              <ng-container *ngIf="!noDataTemplate; else elseTemplate">
                <mat-option *ngFor="let item of types | filter: filterTemplate : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
              </ng-container>
              <ng-template #elseTemplate>
                <mat-option [value]="null">No se encontraron tipos de templates</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingTemplate>
              <mat-option [value]="null">Cargando tipos de templates...</mat-option>
            </ng-template>
          </mat-select>
        </div>

        <div *ngIf="typeSelected == 'email'" class="w-full">
          <div class="w-full">
            <label for="template_email_id" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Template</span>:</label>
            <mat-select id="template_email_id" name="template_email_id" formControlName="template_email_id" placeholder="Seleccione una opción" class="custom__mat-select">
              <div class="filter_option">
                <input placeholder="Buscar" appSearch (appSearch)="filterTemplateEmail = $event" class="!outline-none !ring-0 !border-0">
              </div>
              <ng-container *ngIf="!loadingTemplateEmail; else elseLoadingTemplateEmail">
                <ng-container *ngIf="!noDataTemplateEmail; else elseTemplateEmail">
                  <mat-option *ngFor="let item of templateEmails | filter: filterTemplateEmail : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                </ng-container>
                <ng-template #elseTemplateEmail>
                  <mat-option [value]="null">No se encontraron templates de emails</mat-option>
                </ng-template>
              </ng-container>
              <ng-template #elseLoadingTemplateEmail>
                <mat-option [value]="null">Cargando templates de emails...</mat-option>
              </ng-template>
            </mat-select>
          </div>
        </div>

        <div *ngIf="typeSelected == 'whatsapp' || typeSelected == 'sms'" class="w-full">
          <div class="w-full">
            <label for="template_message_id" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Template</span>:</label>
            <mat-select id="template_message_id" name="template_message_id" formControlName="template_message_id" placeholder="Seleccione una opción" class="custom__mat-select">
              <div class="filter_option">
                <input placeholder="Buscar" appSearch (appSearch)="filterTemplateMessage = $event" class="!outline-none !ring-0 !border-0">
              </div>
              <ng-container *ngIf="!loadingTemplateMessage; else elseLoadingTemplateMessage">
                <ng-container *ngIf="!noDataTemplateMessage; else elseTemplateMessage">
                  <mat-option *ngFor="let item of templateMessages | filter: filterTemplateMessage : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                </ng-container>
                <ng-template #elseTemplateMessage>
                  <mat-option [value]="null">No se encontraron templates de mensajes</mat-option>
                </ng-template>
              </ng-container>
              <ng-template #elseLoadingTemplateMessage>
                <mat-option [value]="null">Cargando templates de mensajes...</mat-option>
              </ng-template>
            </mat-select>
          </div>
        </div>

        <div class="w-full">
          <label for="list_group_id" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Lista de contactos</span>:</label>
          <mat-select id="list_group_id" name="list_group_id" formControlName="list_group_id" placeholder="Seleccione una opción" class="custom__mat-select">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterListContact = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingListContact; else elseLoadingListContact">
              <ng-container *ngIf="!noDataListContact; else elseListContact">
                <mat-option *ngFor="let item of listContact | filter: filterListContact : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
              </ng-container>
              <ng-template #elseListContact>
                <mat-option [value]="null">No se encontraron tipos de ListContacts</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingListContact>
              <mat-option [value]="null">Cargando tipos de ListContacts...</mat-option>
            </ng-template>
          </mat-select>
        </div>

        <div *ngIf="action != 'create' && createdAt" class="flex flex-wrap justify-between gap-2 mt-5 w-full">
          <div class="sm:col-span-6 col-span-12">
            <p class="text-sm">creación: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(createdAt) }}</span></p>
          </div>
          <div class="sm:col-span-6 col-span-12" *ngIf="updatedAt">
            <p class="text-sm">edición: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(updatedAt) }}</span></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
  <div class="w-full flex flex-wrap justify-between actions_modal">
    <div class="block"></div>
    <div class="flex flex-wrap justify-between gap-4">
      <ng-container *ngIf="action != 'read'; else elseActionButton">
        <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

        <button mat-button type="button" (click)="submitForm()" [disabled]="action == 'read'||dataForm.invalid" class="cy__btn btn__second">
          <i class="fa-light fa-check"></i> {{ actionText }} {{ title }}
        </button>
      </ng-container>
      <ng-template #elseActionButton>
        <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cerrar</button>
      </ng-template>
    </div>
  </div>
</div>
