import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Column, DialogData } from 'src/app/interfaces/interfaces';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientProvider } from 'src/app/services/http-client';
import { SettingsProvider } from 'src/app/services/settings';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AlertService } from 'src/app/services/alert.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, map, startWith } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { DatatableComponent } from 'src/app/components/datatable-custom/datatable.component';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'modal-client-lists-filter-selector',
  templateUrl: './client-lists-filter-selector.component.html',
  styleUrls: ['./client-lists-filter-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule, 
    MatDialogModule, 
    MatButtonModule, 
    MatSelectModule, 
    FilterPipe, 
    SearchDirective, 
    MatSlideToggleModule, 
    MatChipsModule, 
    MatIconModule, 
    MatAutocompleteModule,
    DatatableComponent,
    ButtonCapYeiComponent,
  ],
})
export class ModalClientListsFilterSelectorComponent implements OnInit {

  dataForm: FormGroup;

  api = '';
  action:string = 'create';

  id:any = null;

  noDataProducts: boolean = false;
  loadingProducts: boolean = false;
  filterProducts: string = '';
  products:any[] = [];

  noDataSubproducts: boolean = false;
  loadingSubproducts: boolean = false;
  filterSubproducts: string = '';
  subproducts:any[] = [];

  noDataYears: boolean = false;
  loadingYears: boolean = false;
  filterYears: string = '';
  years:any[] = [];

  item: any = null;
  filterTypes: string = '';
  noDataTypes: boolean = false;
  loadingTypes: boolean = false;
  types:any[] = [
    { id: null, name: 'Selecciona una opción' },
    { id: 'product', name: 'Producto' },
    { id: 'product-item', name: 'Subproducto' },
    { id: 'year', name: 'Gestión' },
    { id: 'on_date', name: 'Al día' },
  ];
  typeSelected:any = null;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  nameCtrl = new FormControl();
  filteredNames?: Observable<any[]>;
  names: any[] = [];
  selectedNames: any[] = [];
  @ViewChild('nameInput') nameInput: ElementRef<HTMLInputElement> | any;

  hasError:boolean = false;

  dataDynamic:any[] = [];
  dataStatic:any[] = [];

  public dataSourceDynamic = new MatTableDataSource();
  public dataSourceStatic = new MatTableDataSource();

  columnsDynamic:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:1},
    {id:'type',label:'Tipo',hideOrder:2},
    {id:'name',label:'Filtro',hideOrder:2},
    {id:'action_buttons',label:'Eliminar',hideOrder:1,width:100},
  ];
  columnsStatic:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:1},
    {id:'name',label:'Nombre',hideOrder:2},
    {id:'action_buttons',label:'Eliminar',hideOrder:1,width:100},
  ];

  constructor(
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private alert: AlertService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<ModalClientListsFilterSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    console.log(data);
    this.dataForm = new FormGroup({
      parent_id:     new FormControl(null, [Validators.required]),
    }) as FormGroup;
    this.action = data?.action ?? 'create';
    if(data?.id) {
      this.id = data?.id;
      this.dataForm.get('parent_id')?.patchValue(data.id);
      if(data?.item && data?.item?.type) {
        if(data?.item?.type == 'dynamic') {
          this.api = SettingsProvider.getUrl(`model/list-group-filter/${this.action}`);
          this.dataForm.addControl('type', new FormControl(null, [Validators.required]));
          this.dataForm.addControl('product_id', new FormControl(null));
          this.dataForm.addControl('product_item_id', new FormControl(null));
          this.dataForm.addControl('year_id', new FormControl(null));
          this.dataForm.addControl('on_date', new FormControl(null));
          setTimeout(() => {
            this.dataForm.get('type')?.valueChanges.subscribe((data: any) => {
              console.log(data);
              this.validateField(data);
            });
            this.item = data?.item ?? null;
            this.initializeDynamic();
          }, 1);
        } else {
          this.api = SettingsProvider.getUrl('multiple-customer-list');
          this.dataForm.addControl('customer_ids', new FormControl([], [Validators.required, Validators.min(1)]));
          setTimeout(() => {
            this.item = data?.item ?? null;
            this.initializeStatic();
          }, 1);
        }
      }
    }
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
  }

  processDataDynamic(result:any) {
    this.dataDynamic = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      let items = {
        ...element,
        id: element['id'] ?? null,
        type: this.getType(element['type']),
        name: element['name'] ?? null,
      };
      rows.push(items);
    });
    this.dataSourceDynamic.data = rows;
  }

  processDataStatic(result:any) {
    this.dataStatic = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      let items = {
        ...element,
        id: element['id'] ?? 0,
        name: (element['customer']&&element['customer']['name']) ? element['customer']['name'] : null,
      };
      rows.push(items);
    });
    this.dataSourceStatic.data = rows;
  }

  validateField(data: any) {
    this.typeSelected = data;
    switch (data) {
      case 'product':
        this.dataForm.get('product_item_id')?.clearValidators();
        this.dataForm.get('product_item_id')?.updateValueAndValidity();
        this.dataForm.get('year_id')?.clearValidators();
        this.dataForm.get('year_id')?.updateValueAndValidity();
        this.dataForm.get('on_date')?.clearValidators();
        this.dataForm.get('on_date')?.updateValueAndValidity();
        
        this.dataForm.get('product_id')?.setValidators([Validators.required]);
        this.dataForm.get('product_id')?.updateValueAndValidity();
        break;
        
      case 'product-item':
        this.dataForm.get('product_id')?.clearValidators();
        this.dataForm.get('product_id')?.updateValueAndValidity();
        this.dataForm.get('year_id')?.clearValidators();
        this.dataForm.get('year_id')?.updateValueAndValidity();
        this.dataForm.get('on_date')?.clearValidators();
        this.dataForm.get('on_date')?.updateValueAndValidity();
        
        this.dataForm.get('product_item_id')?.setValidators([Validators.required]);
        this.dataForm.get('product_item_id')?.updateValueAndValidity();
        break;

      case 'year':
        this.dataForm.get('product_id')?.clearValidators();
        this.dataForm.get('product_id')?.updateValueAndValidity();
        this.dataForm.get('product_item_id')?.clearValidators();
        this.dataForm.get('product_item_id')?.updateValueAndValidity();
        this.dataForm.get('on_date')?.clearValidators();
        this.dataForm.get('on_date')?.updateValueAndValidity();
        
        this.dataForm.get('year_id')?.setValidators([Validators.required]);
        this.dataForm.get('year_id')?.updateValueAndValidity();
        break;

      case 'on_date':
        this.dataForm.get('product_id')?.clearValidators();
        this.dataForm.get('product_id')?.updateValueAndValidity();
        this.dataForm.get('product_item_id')?.clearValidators();
        this.dataForm.get('product_item_id')?.updateValueAndValidity();
        this.dataForm.get('year_id')?.clearValidators();
        this.dataForm.get('year_id')?.updateValueAndValidity();
        
        this.dataForm.get('on_date')?.setValidators([Validators.required]);
        this.dataForm.get('on_date')?.updateValueAndValidity();
        break;
    
      default:
        this.dataForm.get('product_id')?.clearValidators();
        this.dataForm.get('product_id')?.updateValueAndValidity();
        this.dataForm.get('product_item_id')?.clearValidators();
        this.dataForm.get('product_item_id')?.updateValueAndValidity();
        this.dataForm.get('year_id')?.clearValidators();
        this.dataForm.get('year_id')?.updateValueAndValidity();
        this.dataForm.get('on_date')?.clearValidators();
        this.dataForm.get('on_date')?.updateValueAndValidity();
        break;
    }
  }

  ngOnInit() {
  }

  initializeDynamic() {
    this.loadingProducts = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/product?is_paginate=0`)).subscribe((result:any) => {
      if(result['status']) {
        this.loadingProducts = false;
        if(result?.items && result.items?.length > 0) {
          this.products = result.items;
          this.noDataProducts = false;
        } else {
          this.noDataProducts = true;
        }
      } else {
        this.loadingProducts = false;
        this.noDataProducts = true;
      }
    }, error => {
      console.log(error);
      this.loadingProducts = false;
      this.noDataProducts = true;
    });

    this.loadingSubproducts = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/product-item?is_paginate=0`)).subscribe((result:any) => {
      if(result['status']) {
        this.loadingSubproducts = false;
        if(result?.items && result.items?.length > 0) {
          this.subproducts = result.items;
          this.noDataSubproducts = false;
        } else {
          this.noDataSubproducts = true;
        }
      } else {
        this.loadingSubproducts = false;
        this.noDataSubproducts = true;
      }
    }, error => {
      console.log(error);
      this.loadingSubproducts = false;
      this.noDataSubproducts = true;
    });

    this.loadingYears = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/year?is_paginate=0`)).subscribe((result:any) => {
      if(result['status']) {
        this.loadingYears = false;
        if(result?.items && result.items?.length > 0) {
          this.years = result.items;
          this.noDataYears = false;
        } else {
          this.noDataYears = true;
        }
      } else {
        this.loadingYears = false;
        this.noDataYears = true;
      }
    }, error => {
      console.log(error);
      this.loadingYears = false;
      this.noDataYears = true;
    });

    this.tableDataDynamic();
  }

  tableDataDynamic() {
    this.dataDynamic = [];
    this.dataSourceDynamic.data = [];
    this.http.getRequest(SettingsProvider.getUrl(`model/list-group-filter?parent_id=${this.id}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.processDataDynamic(result);
      }
    }, error => {
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  initializeStatic() {
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`model/customer?is_paginate=0`)).subscribe(result => {
      this.load.dismiss();
      if(result['items']&&result['items'].length > 0) {
        this.names = result['items'];
        this.filteredNames = this.nameCtrl.valueChanges.pipe(
          startWith(null),
          map((elm: any | null) => (elm ? this._filter(elm) : this.names.slice())),
        );
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
    });

    this.tableDataStatic();
  }

  tableDataStatic() {
    this.load.loadingShow();
    this.dataStatic = [];
    this.dataSourceStatic.data = [];
    this.http.getRequest(SettingsProvider.getUrl(`model/list-group-contact?parent_id=${this.id}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.processDataStatic(result);
        this.load.dismiss();
      } else {
        this.load.dismiss();
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  validateForm(): boolean {
    if(this.dataForm.valid) {
      this.hasError = false;
      return true;
    } else {
      this.hasError = true;
      return false;
    }
  }

  submitForm() {
    if(this.validateForm()) {
      this.http.postRequest(this.api, this.dataForm.value).subscribe((result:any) => {
        if(result['status']) {
          if(result['message']){
            this.toast.success('Filtros añadidos', result['message'], 4000);
          } else {
            this.toast.success('Filtros añadidos', 'El filtro fue añadido exitosamente', 4000);
          }
          if(this.item.type == 'dynamic') {
            this.tableDataDynamic();
          } else {
            this.tableDataStatic();
          }
        } else {
          if(result['message']) {
            if(result['errors']) {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo procesar la información, intente nuevamente.');
            }
          } else {
            if(result['errors']) {
              this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.toast.error('¡Error!', 'No se pudo procesar la información, intente nuevamente.', 4000);
            }
          }
        }
        this.load.dismiss();
      }, error => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al añadir filtros en el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const index = this.names.findIndex(user => user.name === value.trim());
      if (index >= 0 && !this.selectedNames.includes(this.names[index])) {
        this.selectedNames.push(this.names[index]);
        this.dataForm.get('customer_ids')?.patchValue((this.selectedNames.map(item => item.id) ?? []));
      }
    }

    if (input) {
      input.value = '';
    }

    this.nameCtrl.setValue(null);
  }

  remove(index: number): void {
    this.selectedNames.splice(index, 1);
    this.dataForm.get('customer_ids')?.patchValue((this.selectedNames.map(item => item.id) ?? []));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const item = event.option.value;
    const index = this.selectedNames.indexOf(item);

    if (index >= 0) {
      // Si el usuario ya está seleccionado, lo eliminamos de la lista.
      this.selectedNames.splice(index, 1);
    } else {
      // Si el usuario no está seleccionado, lo añadimos a la lista.
      this.selectedNames.push(item);
    }
    this.dataForm.get('customer_ids')?.patchValue((this.selectedNames.map(item => item.id) ?? []));

    this.nameInput.nativeElement.value = '';
    this.nameCtrl.setValue(null);
  }

  private _filter(value: any): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
    return this.names.filter((item:any) => item.name.toLowerCase().includes(filterValue));
  }

  removeItem(name: string, id: any, type: 'dynamic' | 'static', model: string) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`${model}/${id}`), {}).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            if(type == 'dynamic') {
              this.tableDataDynamic();
            } else {
              this.tableDataStatic();
            }
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      }
    });
  }

  getType(type: string):string {
    switch (type) {
      case 'product':
        return 'Producto';

      case 'product-item':
        return 'Subproducto';

      case 'year':
        return 'Gestión';

      case 'on_date':
        return 'Al día';

      default:
        return '-';
    }
  }
}