<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <form class="w-full pb-2" (ngSubmit)="init()" [formGroup]="dataForm" *ngIf="dataForm">
          <div class="w-full mt-2 grid grid-cols-12 gap-4" *ngIf="formFields && formFields.length > 0">
            <ng-container *ngFor="let field of formFields">
              <app-dynamic-form-field [formItem]="field" [type]="field.type" action="create"></app-dynamic-form-field>
            </ng-container>
            <button type="submit" class="col-auto w-fit font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-secondary hover:bg-primary text-white">
              <i class="fa-light fa-search"></i> Filtrar
            </button>
          </div>
        </form>
        <hr>
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>
      
      <div *ngIf="create" class="w-auto max-w-full flex flex-wrap gap-2">
        <a mat-button [routerLink]="['/model/'+ model, 'create']" class="cy__btn btn__primary">
          <i class="fa-light fa-plus"></i> Crear {{ titleSingular }}
        </a>
      </div>
    </div>

    <div class="block w-full pt-5">
      <app-cap-datatable
        *ngIf="columns.length > 0"
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [actionsBtns]="actions"
        [visibilityBtns]="visibilityBtns"
        [noData]="noData"
      ></app-cap-datatable>
    </div>
  </div>
</div>