import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SearchDirective } from 'src/app/directives/search.directive';
import { FilterPipe } from 'src/app/pipes/filter';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { NotificationService } from 'src/app/services/notification.service';
import { EditorModule } from '@tinymce/tinymce-angular';
import { environment } from 'src/app/environment/environment';

@Component({
  selector: 'modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    EditorModule,
  ],
})
export class ModalTemplateFormComponent implements OnInit {

  title = 'Mensajería - TEMPLATE';

  action: string = 'create';
  actionText:string = 'Crear';

  dataForm:any = this.fb.group({
    name:           new FormControl(null, [Validators.required]),
    title:          new FormControl(null, [Validators.required]),
    content:        new FormControl(null, [Validators.required]),
  });

  id:any        = null;
  createdAt     = null;
  updatedAt     = null;

  environment = environment;
  
  constructor(
    private load: LoadingService,
    public xServices: XtrasService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<ModalTemplateFormComponent>,
    private http: HttpClientProvider,
    private toast: NotificationService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = data?.action ?? 'create';
    this.id = data?.id ?? null;
    if(this.id && (this.action == 'read' || this.action == 'edit')) {
      this.initializeApp();
    }
  }
  
  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/template-message/read`), { id: this.id }).subscribe((result:any) => {
      console.log(result);
      if(result['status']) {
        this.load.dismiss();
        if(result['item']) {
          this.dataForm.patchValue({
            name:                 result.item?.name ?? null,
            title:                result.item?.title ?? null,
            content:              result.item?.content ?? null,
          });
  
          this.createdAt = result.item?.created_at ?? null;
          this.updatedAt = result.item?.updated_at ?? null;

          if(this.action == 'read') {
            setTimeout(() => {
              this.dataForm.disable();
            }, 1);
          }
        }
      } else {
        this.xServices.getError(result, true, '/email-templates');
      }
    }, (error) => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit() {
    this.actionText = this.xServices.getAction(this.action);
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  async submitForm() {
    if(this.action != 'read'){
      let body = {};
      if(this.id) {
        body = { ...this.dataForm.value, id: this.id };
      } else {
        body = { ...this.dataForm.value };
      }
      this.load.loadingShow();
      let formData = await this.xServices.getFormData(body);

      this.http.postRequest(SettingsProvider.getUrl(`model/template-message/${this.action}`), formData).subscribe((result:any) => {
        console.log(result);
        if(result['status']) {
          this.load.dismiss();
          this.dialogRef.close({ reloadData: true });
        } else {
          this.xServices.getError(result, true, '/email-templates');
        }
      }, (error) => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

}
