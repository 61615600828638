import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SettingsProvider } from 'src/app/services/settings';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { XtrasService } from 'src/app/services/xtras.service';
import { Observable } from 'rxjs';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'modal-filter-modal',
  templateUrl: './filter-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatSlideToggleModule,
    MatDatepickerModule,
    DatePipe,
  ],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class FilterModalComponent implements OnInit {

  dataForm: FormGroup;

  loadingStatus:boolean = false;
  filterStatus!: string;
  // status:any[] = [];
  status = [
    { id: 'pending', name: 'Pendiente' },
    { id: 'done', name: 'Cerrado' },
    { id: 'cancelled', name: 'Cancelado' },
  ];

  loadingUsers:boolean = false;
  filterUsers: string = '';
  users:any[] = [];

  user$: Observable<UserType>;

  start_date: any = null;
  end_date: any = null;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private http: HttpClientProvider,
    public xServices: XtrasService,
    private authService: AuthService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
    ) {
    this.user$ = this.authService.user$;
    this.dataForm = new FormGroup({
      start_date:         new FormControl(''),
      end_date:           new FormControl(''),
      status:             new FormControl(''),
      user_id:            new FormControl(''),
    }) as FormGroup;
    if(data&&data.form) {
      if(data.form?.start_date_nf&&data.form?.start_date_nf!='') {
        this.start_date = data.form.start_date_nf;
      }
      if(data.form?.end_date_nf&&data.form?.end_date_nf!='') {
        this.end_date = data.form.end_date_nf;
      }
      this.dataForm.patchValue({
        start_date:   data.form?.start_date ?? '',
        end_date:     data.form?.end_date ?? '',
        status:       data.form?.status ?? '',
        user_id:      data.form?.user_id ?? '',
      });
    }
    this.initializeApp()
  }

  initializeApp() {
    this.loadingUsers = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingUsers = false;
      if(result['items']&&result['items'].length > 0) {
        this.users = result['items'];
      } else {
      }
    }, error => {
      console.log(error);
      this.loadingUsers = false;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submitForm(data:any) {
    let _data: any = null;
    if(data) {
      _data = { ...data, start_date_nf: (this.start_date ?? ''), end_date_nf: (this.end_date ?? '') };
    }
    this.dialogRef.close({data: _data});
  }

  dateChange(event: any, field: any) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.dataForm.get(field)?.patchValue(date ?? '');
  }
}