import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ModalRegisterActivityComponent } from '../compontents/register-activity/form.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalLeadReminderFormComponent } from '../compontents/reminder-create/form.component';
declare var $: any;

@Component({
  selector: 'lead-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, DatatableComponent, PaginatorComponent, MatDialogModule, MatButtonModule],
})
export class AssignedLeadDetailPage implements OnInit, OnDestroy {

  title = 'Actividades';

  pagination = null;
  data:any = [];

  dataForm = this.fb.group({
    fullname:      new FormControl('Eduardo Mejia Silva', [Validators.required]),
    ci_number:     new FormControl('7892601', [Validators.required]),
    email:         new FormControl('edumejia30@gmail.com', [Validators.required]),
    cellphone:     new FormControl('77777777', [Validators.required]),
    source:        new FormControl('Redes Sociales', [Validators.required]),
    status:        new FormControl('Convertido', [Validators.required]),
  });
  // model = 'lead';
  model = 'user';

  isAdmin:boolean = false;

  @HostBinding('class') class = 'dContents'; // * Clase que se añade a este componente selector

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,
    private fb: FormBuilder,
    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private dialog: MatDialog,
    private alert: AlertService,
  ) {
    $('.content_scroll-site').addClass('not__scroll'); // * Clase que se añade al main para no tener scroll
    this.initializeApp();
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp(id?: any) {
    this.pagination = null;
    this.data = [];

    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        
      } else {
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnDestroy(): void {
    $('.content_scroll-site').removeClass('not__scroll');
  }

  formCreate() {
    const dialogRef = this.dialog.open(ModalLeadReminderFormComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data:{classComponent: 'full__hsize'}
      // panelClass: 'content_modal'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  registerActivity() {
    const dialogRef = this.dialog.open(ModalRegisterActivityComponent, {
      width: '550px',
      maxWidth: 'calc(100vw - (1rem * 2))',
      height: '100%',
      disableClose: false,
      autoFocus: false,
      position: {
        top: '0px',
        bottom: '0px',
        right: '0px',
      },
      data: { fullname: 'Eduardo Mejia Silva', parent_id: 1, customer_id: 1, classComponent: 'full__hsize' },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }
}
