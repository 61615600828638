<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>

      <div *ngIf="create" class="w-auto max-w-full block">
        <button (click)="openForm('create')" mat-button class="cy__btn btn__second">
          <i class="fa-light fa-plus"></i> Crear Template
        </button>
      </div>
        
    </div>


    <div class="block w-full pt-5">
      <app-cap-datatable
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [actionsBtns]="actions"
        [visibilityBtns]="visibilityBtns"
        [loadingData]="loadingData"
        [noData]="noData"
      ></app-cap-datatable>
    </div>
  </div>
</div>