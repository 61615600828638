<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        AGENDAR REUNION
    </h1>
</div>

<div  class="w-full bg-white !p-4 !m-0 modal-reu">
    <div class="block w-full" >
        <P class="text-center text-[#0a3563]">Para crear una reunión debes tener enlazado tu calendario</P>
    </div>
    <a mat-button href="https://calendar.google.com/" target="_blank" class="cy__btn btn__second">
        <i class="fa fa-calendar-plus"></i> Ir a calendario
    </a>
</div>
<!-- <div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <button mat-button type="button" (click)="submitForm()" class="cy__btn btn__second">
                <i class="fa-light fa-check"></i> Registrar Nota
            </button>
        </div>
    </div>
</div> -->
