<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-full max-w-full flex flex-wrap gap-4 items-start justify-between">
        <div class="w-auto inline-block">
          <h4 class="w-full block m-0 mb-4 text-slate-800 text-base font-bold">Filtrar por</h4>
          <form [formGroup]="dataForm" (ngSubmit)="submit()" class="w-full flex flex-wrap md:flex-row flex-col gap-4">
            <div class="w-auto block">
              <label for="node" class="block w-full text-slate-800 text-sm font-semibold mb-2">Nodo:</label>
              <select class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0" id="node" name="node" formControlName="node">
                <ng-container *ngIf="xServices.lengthItems(nodes); else elseNodes">
                  <option value="">Seleccione una opción</option>
                  <option [value]="''">Todos</option>
                  <option *ngFor="let node of nodes" [value]="node['value']">{{ node['name'] }}</option>
                </ng-container>
                <ng-template #elseNodes>
                  <option value="">Cargando Organizaciones...</option>
                </ng-template>
              </select>
            </div>
            <div class="w-auto block">
              <label for="codItem" class="block w-full text-slate-800 text-sm font-semibold mb-2">ID:</label>
              <input type="text" pattern="[^0-9]" class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0" id="codItem" name="codItem" formControlName="codItem" />
            </div>
            <div class="w-auto block self-end">
              <button type="submit" class="font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-secondary hover:bg-primary text-white">
                Filtrar
              </button>
            </div>
          </form>
        </div>

        <div class="w-auto max-w-full grid grid-cols-12 gap-2" *ngIf="pagination">
          <div class="col-span-12 w-full flex items-center justify-center md:justify-start">
            <div class="w-auto text-sm">
              {{ ((pagination['to'] || 0) | number:'.0-2':'es-ES') +' de '+ pagination['total'] }} Elementos
            </div>
          </div>
          <div class="col-span-12 flex items-center justify-center md:justify-start" *ngIf="xServices.lengthItems(pagination['links'])">
            <ul class="pagination_table-out">
              <ng-container *ngFor="let pag of pagination['links']; let first = first; let last = last">
                <ng-container *ngIf="first||last; else elsePag">
                  <ng-container *ngIf="first;">
                    <li class="paginate_button page-item previous">
                      <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                        <i class="fa-regular fa-left-long"></i>
                      </a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="last;">
                    <li class="paginate_button page-item next">
                      <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                        <i class="fa-regular fa-right-long"></i>
                      </a>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-template #elsePag>
                  <li class="paginate_button page-item">
                    <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                      {{ pag['label'] }}
                    </a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="block w-full pt-5">
      <app-cap-datatable [dataSource]="dataSource" [columnsdef]="columns" [actionsBtns]="actions" [visibilityBtns]="visibilityBtns"></app-cap-datatable>
    </div>
  </div>
</div>