<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>
      
      <div class="w-auto max-w-full flex flex-wrap gap-2">
        <button *ngIf="visibilityBtns.create" mat-button (click)="formCreate()" class="cy__btn btn__second">
          <i class="fa-light fa-plus"></i> Nuevo Lead
        </button>
      </div>
    </div>

    <div class="block w-full pt-5">
      <datatable-capyei
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [loadingData]="loadingData"
        [noData]="noData"
        [customTemplate]="customTemplate">
        <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
          <ng-container [ngSwitch]="column.id">
            <ng-container *ngSwitchCase="'customer_item'">
              <span [innerHTML]="userData(item, row['customer']['image'], row['customer']['email'])"></span>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <span *ngIf="item" [innerHTML]="statusData(item)"></span>
              <span *ngIf="!item">-</span>
            </ng-container>
            <ng-container *ngSwitchCase="'source'">
              <div *ngIf="item" class="society not_bullet">{{ item }}</div>
              <span *ngIf="!item" class="two_line-hidden">-</span>
            </ng-container>
            <ng-container *ngSwitchCase="'action_buttons'">
              <a *ngIf="visibilityBtns.read" routerLink="/activity/lead/{{ row['customer_id'] }}/{{ row.id }}">
                <button-cy label="Ver" addClass="btn-action info" icon="fa-light fa-eye" [iconOnly]="true" />
              </a>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="two_line-hidden">{{ item ?? '-' }}</span>
            </ng-container>
          </ng-container>
        </ng-template>
      </datatable-capyei>
    </div>
  </div>
</div>