<div class="dataTable__cap">
    
    <div *ngIf="search" class="filterHeader">
        <mat-form-field appearance="fill">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target)" placeholder="Buscar..." class="focus:ring-transparent">
        </mat-form-field>
    </div>
    
    <mat-table #dataTable [dataSource]="dataSource" matSort multiTemplateDataRows>
        
        <!-- Toggle Button Start -->
        <ng-container matColumnDef="trigger">
            <mat-header-cell *matHeaderCellDef fxFlex="70px">
                <mat-icon>menu</mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = dataIndex;" fxFlex="70px">
                <span  (click)="expandedElement[row.id] = !expandedElement[row.id]" 
                [ngClass]="[expandedElement[row.id] ?  'active' : 'inActive']"  class="btnToggleExpand"></span>
            </mat-cell>
        </ng-container>
        <!-- Toggle Button End -->
        
        
        <ng-container [matColumnDef]="column.id" *ngFor="let column of visibleColumns">
            <!-- Header cell -->
            <ng-container *ngIf="column.id == 'action_buttons'; else elseActionsColumn">
                <mat-header-cell *matHeaderCellDef [fxFlex]="column.width + 'px'" fxLayoutAlign="end" mat-sort-header>{{ column.label }}</mat-header-cell>
            </ng-container>
            <ng-template #elseActionsColumn>
                <ng-container *ngIf="column.check__list; else elseCheckboxList1">
                    <mat-header-cell *matHeaderCellDef [fxFlex]="column.width + 'px'">
                        <!-- <mat-checkbox class="example-margin"
                            [checked]="allComplete"
                            [indeterminate]="someComplete()"
                            (change)="setAll($event.checked)"
                        >
                        </mat-checkbox> -->
                    </mat-header-cell>
                </ng-container>
                <ng-template #elseCheckboxList1>
                    <mat-header-cell *matHeaderCellDef [fxFlex]="column.width + 'px'" mat-sort-header>{{ column.label }}</mat-header-cell>
                </ng-template>
            </ng-template>
            
            
            <ng-container *ngIf="column.id !== 'action_buttons'; else elseActionsColumn2">
                <mat-cell *matCellDef="let row;let i = dataIndex" [fxFlex]="column.width + 'px'">
                    <ng-container *ngIf="column.id == 'active'; else elseActiveBoolean">
                        <ng-container *ngIf="row['active'] == 0||!row['active']; else elseActive">
                            <span class="bg-red-500/5 text-red-500 rounded-full py-1 px-2 text-xs">Inactivo</span>
                        </ng-container>
                        <ng-template #elseActive>
                            <span class="bg-green-500/5 text-green-500 rounded-full py-1 px-2 text-xs">Activo</span>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseActiveBoolean>
                        <ng-container *ngIf="column.id == 'check__list' && column.check__list; else elseCheckboxList2">
                            <mat-checkbox class="example-margin"
                                (change)="updateAllComplete(row[column.id], row)"
                            ></mat-checkbox>
                        </ng-container>
                        <ng-template #elseCheckboxList2>
                            <ng-container *ngIf="column.html; else elseHiddenColumnHtml">
                                <span class="px-1 two_line-hidden max-w-full" [innerHTML]="row[column.id]"></span>
                            </ng-container>
                            <ng-template #elseHiddenColumnHtml>
                                <span *ngIf="xServices.isNumber(row[column.id])" class="px-1 two_line-hidden max-w-full">{{ row[column.id] }}</span>
                                <span *ngIf="!xServices.isNumber(row[column.id])" class="px-1 two_line-hidden max-w-full">{{ row[column.id] }}</span>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-template #elseActionsColumn2>
                <mat-cell *matCellDef="let row;let i = dataIndex" [fxFlex]="column.width + 'px'" fxLayoutAlign="end">
                    <ng-container *ngIf="actionsBtns.length > 0">
                        <div class="inline-flex flex-wrap items-center gap-2 w-auto max-w-full py-1">
                            <ng-container *ngFor="let action of actionsBtns; let iAction = index">
                                <ng-container *ngIf="action.active && ((visibilityBtns == null) ||(getItemID(action['id']) == undefined||getItemID(action['id'])&&(getItemID(action['id'])['active'] != null) &&getItemID(action['id'])['active'] == true)); else elseVisibility">
                                    <ng-container *ngIf="action['id'] == 'inactive'||action['id'] == 'active'; else elseActiveBooleanBtn2">
                                        <ng-container *ngIf="row['active'] == 0||!row['active']; else elseActiveBtn">
                                            <button *ngIf="action['id'] == 'active'" [class]="action['class'] || 'py-2 px-4 rounded bg-gray-300'" [matTooltip]="action['only_icon'] ? action['label'] : null" (click)="action.function(i, action['id'], row['id'], row)">
                                                <i *ngIf="action['icon']" [class]="action['icon']"></i><span *ngIf="!action['only_icon']">{{ action['label'] }}</span>
                                            </button>
                                        </ng-container>
                                        <ng-template #elseActiveBtn>
                                            <button *ngIf="action['id'] == 'inactive'" [class]="action['class'] || 'py-2 px-4 rounded bg-gray-300'" [matTooltip]="action['only_icon'] ? action['label'] : null" (click)="action.function(i, action['id'], row['id'], row)">
                                                <i *ngIf="action['icon']" [class]="action['icon']"></i><span *ngIf="!action['only_icon']">{{ action['label'] }}</span>
                                            </button>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #elseActiveBooleanBtn2>
                                        <button [class]="action['class'] || 'py-2 px-4 rounded bg-gray-300'" [matTooltip]="action['only_icon'] ? action['label'] : null" (click)="action.function(i, action['id'], row['id'], row)">
                                            <i *ngIf="action['icon']" [class]="action['icon']"></i><span *ngIf="!action['only_icon']">{{ action['label'] }}</span>
                                        </button>
                                    </ng-template>
                                </ng-container>
                                <ng-template #elseVisibility>
                                    <!-- <p>No hay botón</p> -->
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-template>
        </ng-container>
        
        
        <ng-container matColumnDef="hidden">
            <mat-cell *matCellDef="let row;let i = dataIndex;" class="matCell py-2" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
                <span class="p-2">
                    <div *ngFor="let hiddenColumn of hiddenColumns" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <strong>
                            {{ hiddenColumn.label }}: 
                        </strong>
                        <ng-container *ngIf="hiddenColumn.id !== 'action_buttons'; else elseActionColumn">
                            <ng-container *ngIf="hiddenColumn.id == 'active'; else elseActiveBoolean">
                                <ng-container *ngIf="row['active'] == 0||!row['active']; else elseActive">
                                    <span class="bg-red-500/5 text-red-500 rounded-full py-1 px-2 text-xs">Inactivo</span>
                                </ng-container>
                                <ng-template #elseActive>
                                    <span class="bg-green-500/5 text-green-500 rounded-full py-1 px-2 text-xs">Activo</span>
                                </ng-template>
                            </ng-container>
                            <ng-template #elseActiveBoolean>
                                <ng-container *ngIf="hiddenColumn.html; else elseHiddenColumnHtml">
                                    <span class="two_line-hidden" [innerHTML]="row[hiddenColumn.id]"></span>
                                </ng-container>
                                <ng-template #elseHiddenColumnHtml>
                                    <span *ngIf="xServices.isNumber(row[hiddenColumn.id])" class="two_line-hidden">{{ row[hiddenColumn.id] }}</span>
                                    <span *ngIf="!xServices.isNumber(row[hiddenColumn.id])" class="two_line-hidden">{{ row[hiddenColumn.id] }}</span>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseActionColumn>
                            <ng-container *ngIf="actionsBtns.length > 0">
                                <div class="inline-flex flex-wrap gap-2 w-auto max-w-full">
                                    <ng-container *ngFor="let action of actionsBtns; let iAction = index">
                                        <ng-container *ngIf="action.active && ((visibilityBtns == null) ||(getItemID(action['id']) == undefined||getItemID(action['id'])&&(getItemID(action['id'])['active'] != null) &&getItemID(action['id'])['active'] == true)); else elseVisibility">
                                            <ng-container *ngIf="action['id'] == 'inactive'||action['id'] == 'active'; else elseActiveBooleanBtn2">
                                                <ng-container *ngIf="row['active'] == 0||!row['active']; else elseActiveBtn">
                                                    <button *ngIf="action['id'] == 'active'" [class]="action['class'] || 'py-2 px-4 rounded bg-gray-300'" [matTooltip]="action['only_icon'] ? action['label'] : null" (click)="action.function(i, action['id'], row['id'], row)">
                                                        <i *ngIf="action['icon']" [class]="action['icon']"></i><span *ngIf="!action['only_icon']">{{ action['label'] }}</span>
                                                    </button>
                                                </ng-container>
                                                <ng-template #elseActiveBtn>
                                                    <button *ngIf="action['id'] == 'inactive'" [class]="action['class'] || 'py-2 px-4 rounded bg-gray-300'" [matTooltip]="action['only_icon'] ? action['label'] : null" (click)="action.function(i, action['id'], row['id'], row)">
                                                        <i *ngIf="action['icon']" [class]="action['icon']"></i><span *ngIf="!action['only_icon']">{{ action['label'] }}</span>
                                                    </button>
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #elseActiveBooleanBtn2>
                                                <button [class]="action['class'] || 'py-2 px-4 rounded bg-gray-300'" [matTooltip]="action['only_icon'] ? action['label'] : null" (click)="action.function(i, action['id'], row['id'], row)">
                                                    <i *ngIf="action['icon']" [class]="action['icon']"></i><span *ngIf="!action['only_icon']">{{ action['label'] }}</span>
                                                </button>
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #elseVisibility>
                                            <!-- <p>No hay botón</p> -->
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-template>
                    </div>
                </span>
            </mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="visibleColumnsIds" class="matHeaderRow"></mat-header-row>
        
        <mat-row *matRowDef="let row; let i = dataIndex; columns: visibleColumnsIds" class="matRowVisible visible-row-{{row.id}}"></mat-row>
        
        <!-- Body row definition for hidden columns -->
        <mat-row *matRowDef="let row; let i = dataIndex; columns: ['hidden'];" [@detailExpand]="expandedElement.length && expandedElement[row.id] ? 'expanded' : 'collapsed'" style="overflow: hidden" class="matRowHidden hidden-row-{{row.id}} hidColLength-{{hiddenColumns.length}}" [ngClass]="expandedElement[row.id] ? 'expanded' : 'collapsed'"></mat-row>

        <mat-footer-row *matFooterRowDef="['noData']" [hidden]="!noData"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loadingData"></mat-footer-row>

        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="100%" fxLayoutAlign="center center">
                <span class="data_loader">
                    <div class="btn__roller">
                        <div class="loader__spin"></div>
                    </div> Cargando Datos, espere por favor ...
                </span>
            </mat-footer-cell>
        </ng-container>
    
        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="100%" class="text-center" fxLayoutAlign="center center">
                No se tiene datos.
            </mat-footer-cell>
        </ng-container>
        
    </mat-table>
    
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event"></mat-paginator>
    
</div>