import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from 'src/app/interfaces/interfaces';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
// import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { MatButtonModule } from '@angular/material/button';
import { ModalTemplateFormComponent } from '../components/form/form.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';
import { DatatableComponent } from 'src/app/components/datatable-custom/datatable.component';

declare var $: any;

@Component({
  selector: 'business',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatatableComponent,
    PaginatorComponent,
    MatButtonModule,
    MatDialogModule,
    ButtonCapYeiComponent,
  ]
})
export class BusinessListPage implements OnInit, OnDestroy {

  title = 'Empresa';

  pagination = null;
  data:any = [];

  organizations:any = [];
  codOrganization = new FormControl(null);
  model = 'business-group';

  isAdmin:boolean = false;

  noData: boolean = false;
  loadingData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:0,width:100},
    {id:'name',label:'Nombre',hideOrder:1},
    {id:'status',label:'Estado',hideOrder:2},
    {id:'action_buttons',label:'Acciones',hideOrder:1},
  ];

  actions:any[] = [
    { id: 'view-list', label: 'Contactos', only_icon: true, icon: 'fa fa-list-ul', class: 'btn-action info', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'read', label: 'Ver', only_icon: true, icon: 'fa-light fa-eye', class: 'btn-action info', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'edit', label: 'Editar', only_icon: true, icon: 'fa-light fa-edit', class: 'btn-action success', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
    { id: 'remove', label: 'Eliminar', only_icon: true, icon: 'fa-light fa-trash', class: 'btn-action danger', active: true, function: (i:any, iAction:any, data_id: any, item?: any) => this.clickEvent(i, iAction, data_id, item) },
  ];

  visibilityBtns = {
    create: false,
    read: false,
    edit: false,
    remove: false,
  };

  constructor(
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,

    private metaTitle: Title,
    private userModel: UserModel,
    private toast: NotificationService,
    public xServices: XtrasService,
    private alert: AlertService,
    private dialog: MatDialog,
  ) {
    this.initializeApp();
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    if(action == 'remove') {
      this.removeItem((`"${item['name']}"`), id);
    } else if(action == 'active') {
      this.activeItem((`"${item['name']}"`), id);
    }else if(action == 'view-list') {
      this.router.navigate([`/business-detail/`, id]);
    } else {
      // this.router.navigate([`/${this.model}`, action, id]);
      this.openForm(action, id);
    }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.data = [];
    this.dataSource.data = [];
    
    this.noData = false;
    this.loadingData = true;

    this.http.getRequest(SettingsProvider.getUrl(`model/${this.model}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      console.log(error);
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['items']['data'];
    let rows: any[] = [];

    result['items']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      element['name'] = element['name'] ?? null;
      element['status'] = element['active'] ?? false;
      let items = {...element};
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      this.pagination = result['items'];
    } else {
      this.noData = true;
    }

    if(result['actions']) {
      this.showActionsButtons(result);
    }
  }

  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      // console.log(url);
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any)=> {
        if(result['status']) {
          // console.log(result['items']);
          this.processData(result);
        } else {
          if(result['redirect']) {
            this.router.navigateByUrl('/account');
          }
          if(result['message']){
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(name: string, id: any) {
    Swal.fire({
      title: 'Eliminar Item',
      text: '¿Desea eliminar a '+ name +'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/remove`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Eliminado', result['message'], 4000);
            } else {
              this.toast.success('Item Eliminado', 'Se elimino a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al procesar la acción', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  activeItem(name: string, id: any) {
    Swal.fire({
      title: 'Activar Item',
      text: '¿Desea activar a '+ name +'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((res) => {
      if(res.value) {
        this.load.loadingShow();
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/active`), { id: id }).subscribe(result => {
          // console.log(result);
          if(result['status']) {
            this.load.dismiss();
            if(result['message']){
              this.toast.success('Item Activado', result['message'], 4000);
            } else {
              this.toast.success('Item Activado', 'Se activo a '+ name +' exitosamente', 4000);
            }
            this.initializeApp();
          } else {
            this.load.dismiss();
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, [result['message']]);
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la acción, intente nuevamente.', 4000);
              }
            }
          }
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al activar el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      } else if(res.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = {
      create: result['actions']['create'],
      read: result['actions']['read'],
      edit: result['actions']['edit'],
      remove: result['actions']['delete'],
    };
  }

  openForm(action: string, id?: any): void {
    const dialogRef = this.dialog.open(ModalTemplateFormComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      data: {
        action: action,
        id: (id ?? null),
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result&&result['reloadData']) {
        this.initializeApp();
      }
    });
  }
}
