import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { XtrasService } from 'src/app/services/xtras.service';
import { NgxGaugeModule } from 'ngx-gauge';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';


@Component({
  selector: 'report-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgxGaugeModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonCapYeiComponent,
  ]
})
export class MetasListPage implements OnInit, OnDestroy {

  title = 'Metas';

  data:any = [];

  dataMetas = [];

  /* --------------CHARR OPTION--------------------- */
  colorGauge="#57f12d"
  gaugeType:any = "full";
  gaugeValue = 28.3;
  gaugeAppendText = "%";
  gaugeColor:any = "#57f12d";
  gaugeColorBackground = "#58f12d43"
  gaugeCap:any = "round"

  /* ------------------END-------------------- */

  filterSellers:string = '';
  loadingSellers:boolean = false;
  sellers:any[] = [];

  dataForm = new FormGroup({
    start_date:     new FormControl('' as any),
    end_date:       new FormControl('' as any),
    user_id:        new FormControl('' as any),
  });

  start_date: Date | null = null;
  end_date: Date | null = null;

  constructor(
    private metaTitle: Title,
    private userModel: UserModel,
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private datePipe: DatePipe,
  ) {
    let formValues = JSON.parse(localStorage.getItem('formReportMetas') ?? '{}');
    if (formValues && Object.keys(formValues).length > 0) {
      this.start_date = formValues.start_date_format ? new Date(formValues.start_date_format) : null;
      this.end_date = formValues.end_date_format ? new Date(formValues.end_date_format) : null;
      this.dataForm.patchValue(formValues);
    }

    this.dataForm.get('start_date')?.valueChanges.subscribe(value => {
      if (value == null || value == '') {
        this.dataForm.get('end_date')?.clearValidators();
      } else {
        this.dataForm.get('end_date')?.setValidators([Validators.required]);
      }
      this.dataForm.get('end_date')?.updateValueAndValidity();
    });
    this.initializeApp();
  }

  initializeApp() {
    this.dataInit();

    //* Role 5 - Vendedores
    this.sellers = [];
    this.loadingSellers = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/user?role_id=5&is_paginate=0`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.sellers = result?.items ?? [];
        this.loadingSellers = false;
      } else {
        this.loadingSellers = false;
        this.xServices.getError(result, false, '/account', 'No se pudo obtener la información, intente nuevamente.');
      }
    }, error => {
      console.log(error);
      this.loadingSellers = false;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }
  
  dataInit() {
    let form = {
      ...this.dataForm.value,
      start_date_format: this.start_date,
      end_date_format: this.end_date,
    };
    localStorage.setItem('formReportMetas', JSON.stringify(form));

    this.dataMetas = [];
    this.load.loadingShow();
    let queryParams = new HttpParams({ fromObject: this.dataForm.value });
    this.http.getRequest(SettingsProvider.getUrl(`get-report-metas`), null, queryParams).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.dataMetas = result?.data?.metas ?? [];
        this.load.dismiss();
      } else {
        this.xServices.getError(result, true, '/account', 'No se pudo obtener la información, intente nuevamente');
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  ngOnDestroy(): void {
  }

  dateChange(event: any, field: any) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.dataForm.get(field)?.patchValue(date ?? '');
  }

  clearFilter() {
    this.start_date = null;
    this.end_date = null;
    this.dataForm.patchValue({
      start_date:     '',
      end_date:       '',
      user_id:        '',
    });
    localStorage.setItem('formReportMetas', JSON.stringify({}));
    setTimeout(() => {
      this.dataInit();
    }, 1);
  }
}
