import { Component, Input, ChangeDetectionStrategy, AfterContentInit, OnDestroy, ViewChild, ChangeDetectorRef, NgZone, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { FormControl, FormsModule } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription, map } from 'rxjs';

import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Column } from 'src/app/interfaces/interfaces';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { XtrasService } from 'src/app/services/xtras.service';

export interface CList {
  name: any;
  completed: boolean;
  data?: any;
  subtasks?: CList[];
}

@Component({
  selector: 'app-cap-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MatInputModule, MatTableModule, MatFormFieldModule, MatIconModule, MatTooltipModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, FlexLayoutModule]
})
export class DatatableComponent implements AfterContentInit, OnDestroy, OnInit, OnChanges {
  public MIN_COLUMN_WIDTH: number = 200;

  // Filter Fields
  generalFilter = new FormControl();

  // Visible Hidden Columns
  visibleColumns!: Column[];
  hiddenColumns: Column[] = [];
  expandedElement:any = {};

  // MatPaginator Inputs
  length = 100;
  // pageSize = 5;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  pageSize = 100;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  // MatPaginator Output
  pageEvent!: PageEvent;

  // Shared Variables
  @Input() dataSource!: MatTableDataSource<any>;
  @Input() columnsdef!: Column[];
  @Input() actionsBtns:any = [];
  @Input() actionCheck?:any;
  @Input() visibilityBtns:any = null;

  @Input() noData?:boolean | null = null;
  @Input() loadingData?:boolean | null = null;
  @Input() search?:boolean = true;

  @Output() updateCheckList = new EventEmitter<any>();

  // MatTable
  @ViewChild(MatTable, { static: true }) dataTable!: MatTable<Element>;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  private rulerSubscription: Subscription;

  get visibleColumnsIds() {
    const visibleColumnsIds = this.visibleColumns.map((column) => column.id);

    return this.hiddenColumns.length
      ? ['trigger', ...visibleColumnsIds]
      : visibleColumnsIds;
  }

  get hiddenColumnsIds() {
    return this.hiddenColumns.map((column) => column.id);
  }

  isExpansionDetailRow = (index: number, item: any) => item.hasOwnProperty('detailRow');

  task: CList = {
    name: 'all',
    completed: false,
    subtasks: [],
  };

  constructor(
    public xServices: XtrasService,
    private ruler: ViewportRuler,
    private _changeDetectorRef: ChangeDetectorRef,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private zone: NgZone
  ) {
    this.rulerSubscription = this.ruler.change(100).subscribe((data) => {
      this.toggleColumns(
        this.dataTable['_elementRef'].nativeElement.clientWidth
      );
    });
  }

  ngOnInit() {
    this._MatPaginatorIntl.itemsPerPageLabel = 'Items por página';
    this._MatPaginatorIntl.previousPageLabel = 'Anterior';
    this._MatPaginatorIntl.nextPageLabel = 'Siguiente';
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
  }

  ngAfterContentInit() {
    this.toggleColumns(this.dataTable['_elementRef'].nativeElement.clientWidth);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      const value: any = data[sortHeaderId];
      return typeof value === 'string' ? value.toLowerCase() : value;
    };
    
  }

  ngOnDestroy() {
    this.rulerSubscription.unsubscribe();
  }

  applyFilter(item: any) {
    if(item&&item.value) {
      let filterValue = item.value;
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    } else {
      this.dataSource.filter = '';
    }
  }

  toggleColumns(tableWidth: number) {
    this.zone.runOutsideAngular(() => {
      const sortedColumns = this.columnsdef
        .slice()
        .map((column, index) => ({ ...column, order: index }))
        .sort((a, b) => a.hideOrder - b.hideOrder);

      for (const column of sortedColumns) {
        const columnWidth = column.width ? column.width : this.MIN_COLUMN_WIDTH;
        //console.log('columnWidth',columnWidth)

        if (column.hideOrder && tableWidth < columnWidth) {
          column.visible = false;

          continue;
        }

        tableWidth -= columnWidth;
        column.visible = true;
      }

      this.columnsdef = sortedColumns.sort((a, b) => a.order - b.order);
      this.visibleColumns = this.columnsdef.filter((column) => column.visible);
      this.hiddenColumns = this.columnsdef.filter((column) => !column.visible);
    });

    this._changeDetectorRef.detectChanges();
  }

  getItemID(id: any) {
    let item = this.visibilityBtns.find((item:any) => item.id == id);
    // console.log(item);
    return item;
  }

  allComplete: boolean = false;

  updateAllComplete(name: any, data: any) {
    let existingSubtask = (this.task.subtasks ?? []).find(t => t.name == name);

    if (existingSubtask) {
      existingSubtask.completed = !existingSubtask.completed;
    } else {
      this.task.subtasks = [...(this.task.subtasks ?? []), {name: name, completed: true, data: data}];
    }
    let filterData = (this.task.subtasks ?? []).filter(t => t.completed == true);
    this.updateCheckList ? this.updateCheckList.emit(filterData) : null;
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }
}
