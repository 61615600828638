<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        Filtros de búsqueda
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full col-span-full">
                <label for="filter-modal_name" class="text-secondary block w-full text-sm font-medium mb-2">Nombre:</label>
                <input type="text" name="name" maxlength="255" formControlName="name" id="filter-modal_name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="filter-modal_cellphone" class="text-secondary block w-full text-sm font-medium mb-2">Celular:</label>
                <input type="phone" name="cellphone" maxlength="255" formControlName="cellphone" id="filter-modal_cellphone" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="filter-modal_ci_number" class="text-secondary block w-full text-sm font-medium mb-2">Carnet de identidad:</label>
                <input type="number" name="ci_number" maxlength="255" formControlName="ci_number" id="filter-modal_ci_number" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full col-span-full">
                <label for="filter-modal_created_at" class="text-secondary block w-full text-sm font-medium mb-2">Creado en:</label>
                <input type="date" name="created_at" maxlength="255" formControlName="created_at" id="filter-modal_created_at" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-2">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <div class="flex flex-wrap gap-2">
                <button mat-button type="button" (click)="submitForm(null)" class="cy__btn btn__primary">
                    <i class="fa-light fa-close"></i> Limpiar
                </button>
                <button mat-button type="button" (click)="submitForm(dataForm.value)" class="cy__btn btn__second">
                    <i class="fa-light fa-check"></i> Filtrar
                </button>
            </div>
        </div>
    </div>
</div>
