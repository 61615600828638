<div class="pt-5 lg:px-5 px-0 pb-[5rem] w-full container mx-auto">
  <div class="lg:grid lg:grid-cols-[minmax(0,100%)] flex md:flex-col flex-wrap gap-4 w-full">
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <div class="grid grid-cols-12 justify-between">
        <div class="sm:col-span-6 col-span-12">
          <div class="flex flex-wrap gap-4 items-center mb-4">
            <h5 class="text-slate-800 font-semibold text-lg inline-block relative m-0 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">
              {{ title }}
            </h5>
            <a (click)="back()" class="flex gap-1 items-center justify-center cursor-pointer">
              <i class="fa-regular fa-circle-left"></i> <span class="md:block hidden leading-none">Volver</span>
            </a>
          </div>
          <p>(<b><span class="required"></span></b> ) Indica que el campo es obligatorio.</p>
        </div>
        <div class="sm:col-span-6 col-span-12 text-right">
          <button *ngIf="action != 'create'" [routerLink]="['/audits']" [queryParams]="{ node: 'role', codItem: id }" type="button" class="w-auto text-third border border-third bg-white hover:bg-third hover:text-white focus:ring-4 focus:ring-third font-semibold rounded-lg text-sm px-4 py-2 focus:outline-none">
            <i class="fa fa-history" aria-hidden="true"></i> Historial
          </button>
        </div>
      </div>

      <form class="account-settings-form grid grid-cols-1" (ngSubmit)="submit()" *ngIf="show" [formGroup]="dataForm">
        <div class="w-full mt-2 grid grid-cols-12 gap-4">

          <div class="col-span-12 w-full flex sm:flex-wrap sm:flex-row flex-col gap-4">
            <div class="sm:w-auto w-full max-w-full">
              <label for="displayName" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Nombre de Rol</span>:</label>
              <input type="text" name="displayName" maxlength="255" formControlName="displayName" id="displayName" [(ngModel)]="dataForm.value.displayName" uppercase class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0">
            </div>

            <div class="sm:w-auto w-full max-w-full">
              <label for="active" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Estado</span>:</label>
              <select class="block m-0 w-full bg-slate-50  rounded py-2 px-4 text-base border-none !shadow-none focus:border-[#F1F1F1] focus:ring-0" id="active" name="active" formControlName="active">
                <option value="">Seleccione una opción</option>
                <option [value]="1">Activo</option>
                <option [value]="0">Inactivo</option>
              </select>
            </div>

            <div class="w-full col-span-12" [formGroup]="form">
              <label class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Selecciona los permisos</span>:</label>
              <div class="w-full grid grid-cols-1 gap-2">
                <ng-container *ngFor="let item of permissions; let i=index; let last=last">
                  <div class="w-full flex flex-wrap gap-2 justify-between">
                    <div class="sm:col-span-1 col-span-3 flex items-center">
                      <input *ngIf="action == 'read'" disabled id="checkbox-{{ item['pkyPermission'] }}" type="checkbox" [value]="item['pkyPermission']" class="w-6 h-6 text-green-500 bg-gray-100 rounded border-gray-100 focus:ring-green-400 focus:ring-2 disabled:bg-gray-100 disabled:checked:bg-green-300 outline-none">
                      <input *ngIf="action != 'read'" id="checkbox-{{ item['pkyPermission'] }}" type="checkbox" [value]="item['pkyPermission']" (change)="addRemovePermission(item['pkyPermission'])" class="cursor-pointer w-6 h-6 text-green-500 bg-gray-100 rounded border-gray-100 focus:ring-green-400 focus:ring-2 disabled:bg-gray-100 disabled:checked:bg-green-300 outline-none">
                      <label for="checkbox-{{ item['pkyPermission'] }}" class="ml-2 select-none" [ngClass]="{'cursor-pointer': action != 'read'}"><b>{{ item['displayName'] }}</b></label>
                    </div>

                    <div id="item_selector-{{ item['pkyPermission'] }}" class="sm:col-span-2 col-span-3 flex flex-wrap gap-4 py-2 px-4 items_checkbox-roles">
                      <div class="flex items-center px-4">
                        <input type="checkbox" id="{{ item['pkyPermission'] }}-checkbox-C" [value]="'C'" (change)="checkboxChange($event, item['pkyPermission'], 'C')" class="border border-gray-100 w-6 h-6 bg-gray-100 focus:ring-green-300 hover:bg-gray-300 hover:checked:bg-green-500 checked:bg-green-500 focus:bg-gray-300 focus:checked:bg-green-500 disabled:bg-gray-100 disabled:checked:bg-green-300 cursor-pointer" />
                        <label for="{{ item['pkyPermission'] }}-checkbox-C" class="ml-2 select-none">Crear</label>
                      </div>
                      <div class="flex items-center px-4">
                        <input type="checkbox" id="{{ item['pkyPermission'] }}-checkbox-R" [value]="'R'" (change)="checkboxChange($event, item['pkyPermission'], 'R')" class="border border-gray-100 w-6 h-6 bg-gray-100 focus:ring-green-300 hover:bg-gray-300 hover:checked:bg-green-500 checked:bg-green-500 focus:bg-gray-300 focus:checked:bg-green-500 disabled:bg-gray-100 disabled:checked:bg-green-300 cursor-pointer" />
                        <label for="{{ item['pkyPermission'] }}-checkbox-R" class="ml-2 select-none">Ver</label>
                      </div>
                      <div class="flex items-center px-4">
                        <input type="checkbox" id="{{ item['pkyPermission'] }}-checkbox-W" [value]="'W'" (change)="checkboxChange($event, item['pkyPermission'], 'W')" class="border border-gray-100 w-6 h-6 bg-gray-100 focus:ring-green-300 hover:bg-gray-300 hover:checked:bg-green-500 checked:bg-green-500 focus:bg-gray-300 focus:checked:bg-green-500 disabled:bg-gray-100 disabled:checked:bg-green-300 cursor-pointer" />
                        <label for="{{ item['pkyPermission'] }}-checkbox-W" class="ml-2 select-none">Editar</label>
                      </div>
                      <div class="flex items-center px-4">
                        <input type="checkbox" id="{{ item['pkyPermission'] }}-checkbox-D" [value]="'D'" (change)="checkboxChange($event, item['pkyPermission'], 'D')" class="border border-gray-100 w-6 h-6 bg-gray-100 focus:ring-green-300 hover:bg-gray-300 hover:checked:bg-green-500 checked:bg-green-500 focus:bg-gray-300 focus:checked:bg-green-500 disabled:bg-gray-100 disabled:checked:bg-green-300 cursor-pointer" />
                        <label for="{{ item['pkyPermission'] }}-checkbox-D" class="ml-2 select-none">Eliminar</label>
                      </div>
                    </div>
                  </div>
                  <hr *ngIf="!last">
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center justify-between mt-5">
          <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-wrap gap-5" *ngIf="action != 'create'">
              <div class="sm:col-span-6 col-span-12" *ngIf="createdAt">
                <p class="text-sm">Fecha creación: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(createdAt) }}</span></p>
              </div>
              <div class="sm:col-span-6 col-span-12" *ngIf="updatedAt">
                <p class="text-sm">Fecha edición: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(updatedAt) }}</span></p>
              </div>
            </div>
          </div>
          <div class="lg:col-span-6 col-span-12 text-right">
            <ng-container *ngIf="action !== 'read'">
              <div class="text-right block w-full mt-5">
                <input [disabled]="(action == 'read'||dataForm.invalid)" type="submit" class="font__text cursor-pointer inline-flex items-center gap-2 text-base font-medium py-3 px-10 rounded bg-primary hover:bg-secondary text-white disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-default" [value]="share" />
              </div>
            </ng-container>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
