<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>
      
      <div class="w-auto max-w-full flex flex-wrap gap-2">
        <button mat-button (click)="filterModal()" class="cy__btn btn__second">
          <i class="fa-light fa-filter"></i> Filtrar
        </button>
        <ng-container *ngIf="this.user$ | async as _user">
          <ng-container *ngIf="_user.role_id == 1 || _user.role_id == 3">
            <ng-container *ngIf="data.length > 1">
              <button mat-button (click)="distribuiteLeads()" class="cy__btn btn__third">
                <i class="fa-light fa-right-left"></i> Distribuir Leads
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="_user.role_id == 1">
            <button mat-button (click)="reportModal()" class="cy__btn btn__second">
              <i class="fa-light fa-memo"></i> Reporte
            </button>
          </ng-container>
          <ng-container>
            <button mat-button (click)="reportLeadsModal()" class="cy__btn btn__third">
              <i class="fa-light fa-download"></i> Descargar Leads
            </button>
          </ng-container>
        </ng-container>
        
        <button *ngIf="visibilityBtns.create" mat-button (click)="formAction('create')" class="cy__btn btn__primary">
          <i class="fa-light fa-plus"></i> Nuevo Lead
        </button>
      </div>
    </div>

    <div class="block w-full pt-5">
      <datatable-capyei
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [loadingData]="loadingData"
        [noData]="noData"
        [customTemplate]="customTemplate"
        [pageSize]="20">
        <!-- TODO: Si se manda "customTemplate" debes añadir un default al switch de lo que se manda -->
        <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
          <ng-container [ngSwitch]="column.id">
            <ng-container *ngSwitchCase="'source'">
              <div *ngIf="item" class="society not_bullet">{{ item }}</div>
              <span *ngIf="!item" class="two_line-hidden">-</span>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <div *ngIf="item" class="status_id_{{ row['category']['id'] }} society not_bullet">{{ item }}</div>
              <span *ngIf="!item" class="two_line-hidden">-</span>
            </ng-container>
            <ng-container *ngSwitchCase="'owner_item'">
              <span *ngIf="row['user']" [innerHTML]="userData(item, undefined, row['user']['email'])"></span>
              <div *ngIf="!row['user']" class="not__asign">Sin asignar</div>
            </ng-container>
            <ng-container *ngSwitchCase="'whatsapp'">
              <a *ngIf="item" href="{{ item }}" target="_blank">
                <button-cy icon="fab fa-whatsapp" [iconOnly]="true" addClass="btn-action success"></button-cy>
              </a>
              <span *ngIf="!item" class="two_line-hidden">-</span>
            </ng-container>
            <ng-container *ngSwitchCase="'action_buttons'">
              <a *ngIf="visibilityBtns.read" routerLink="/activity/lead/{{ row['customer_id'] }}/{{ row.id }}" target="_blank">
                <button-cy label="Ver" addClass="btn-action info" icon="fa-light fa-eye" [iconOnly]="true" />
              </a>
              <button-cy *ngIf="visibilityBtns.edit" (onPress)="clickEvent(i, 'edit', row['id'], row)" label="Editar" addClass="btn-action success" icon="fa-light fa-edit" [iconOnly]="true" />
              <button-cy (onPress)="formLeadReminderCreate(row['customer'], row['id'], row['customer_id'])" label="Crear Tarea" addClass="btn-action add-task" icon="fa fa-file-text" [iconOnly]="true" />
              <button-cy (onPress)="registerActivity(row['user'], row['id'], row['customer_id'])" label="Crear nota" addClass="btn-action add-activity" icon="fa fa-book" [iconOnly]="true" />
              <button-cy *ngIf="role_id == 1 || role_id == 3 || role_id == 5" (onPress)="editCustomer(row['customer_id'])" label="Editar Contacto" addClass="btn-action info" icon="fa fa-edit" [iconOnly]="true" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="two_line-hidden">{{ item ?? '-' }}</span>
            </ng-container>
          </ng-container>
        </ng-template>
      </datatable-capyei>
    </div>
  </div>
</div>