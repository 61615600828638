<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <div class="pt-4 border-b border-b-gray-300">
        <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title">
            AÑADIR FILTROS A LA LISTA
        </h1>
        <h5 *ngIf="item" class="text-primary block w-full text-base font-medium m-0"><i class="fa-light fa-caret-right"></i> {{ item.name }}</h5>
    </div>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6 pt-4">
        <form *ngIf="item" [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <ng-container *ngIf="item.type">
                <ng-container *ngIf="item.type == 'dynamic'">
                    <div class="w-full">
                        <label for="type" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Tipo</span>:</label>
                        <mat-select id="type" name="type" formControlName="type" placeholder="Seleccione una opción" class="custom__mat-select">
                            <div class="filter_option">
                                <input placeholder="Buscar" appSearch (appSearch)="filterTypes = $event" class="!outline-none !ring-0 !border-0">
                            </div>
                            <ng-container *ngIf="!loadingTypes; else elseLoadingTypes">
                                <ng-container *ngIf="!noDataTypes; else elseTypes">
                                    <mat-option *ngFor="let item of types | filter: filterTypes : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                                </ng-container>
                                <ng-template #elseTypes>
                                    <mat-option [value]="null">No se encontraron tipos de lista</mat-option>
                                </ng-template>
                            </ng-container>
                            <ng-template #elseLoadingTypes>
                                <mat-option [value]="null">Cargando tipos de lista...</mat-option>
                            </ng-template>
                        </mat-select>
                    </div>
                    <ng-container *ngIf="typeSelected">
                        <div *ngIf="typeSelected == 'product'" class="w-full">
                            <label for="product_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Tipo de Producto</span>:</label>
                            <mat-select id="product_id" name="product_id" formControlName="product_id" placeholder="Seleccione una opción" class="custom__mat-select">
                                <div class="filter_option">
                                    <input placeholder="Buscar" appSearch (appSearch)="filterProducts = $event" class="!outline-none !ring-0 !border-0">
                                </div>
                                <ng-container *ngIf="!loadingProducts; else elseLoadingProducts">
                                    <ng-container *ngIf="!noDataProducts; else elseProducts">
                                        <mat-option *ngFor="let item of products | filter: filterProducts : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                                    </ng-container>
                                    <ng-template #elseProducts>
                                        <mat-option [value]="null">No se encontraron tipos de productos</mat-option>
                                    </ng-template>
                                </ng-container>
                                <ng-template #elseLoadingProducts>
                                    <mat-option [value]="null">Cargando tipos de productos...</mat-option>
                                </ng-template>
                            </mat-select>
                        </div>
                        <div *ngIf="typeSelected == 'product-item'" class="w-full">
                            <label for="product_item_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Tipo de Subproducto</span>:</label>
                            <mat-select id="product_item_id" name="product_item_id" formControlName="product_item_id" placeholder="Seleccione una opción" class="custom__mat-select">
                                <div class="filter_option">
                                    <input placeholder="Buscar" appSearch (appSearch)="filterSubproducts = $event" class="!outline-none !ring-0 !border-0">
                                </div>
                                <ng-container *ngIf="!loadingSubproducts; else elseLoadingSubproducts">
                                    <ng-container *ngIf="!noDataSubproducts; else elseSubproducts">
                                        <mat-option *ngFor="let item of subproducts | filter: filterSubproducts : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                                    </ng-container>
                                    <ng-template #elseSubproducts>
                                        <mat-option [value]="null">No se encontraron tipos de subproductos</mat-option>
                                    </ng-template>
                                </ng-container>
                                <ng-template #elseLoadingSubproducts>
                                    <mat-option [value]="null">Cargando tipos de subproductos...</mat-option>
                                </ng-template>
                            </mat-select>
                        </div>
                        <div *ngIf="typeSelected == 'year'" class="w-full">
                            <label for="year_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Gestión</span>:</label>
                            <mat-select id="year_id" name="year_id" formControlName="year_id" placeholder="Seleccione una opción" class="custom__mat-select">
                                <div class="filter_option">
                                    <input placeholder="Buscar" appSearch (appSearch)="filterYears = $event" class="!outline-none !ring-0 !border-0">
                                </div>
                                <ng-container *ngIf="!loadingYears; else elseLoadingYears">
                                    <ng-container *ngIf="!noDataYears; else elseYears">
                                        <mat-option *ngFor="let item of years | filter: filterYears : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                                    </ng-container>
                                    <ng-template #elseYears>
                                        <mat-option [value]="null">No se encontraron gestiones</mat-option>
                                    </ng-template>
                                </ng-container>
                                <ng-template #elseLoadingYears>
                                    <mat-option [value]="null">Cargando gestiones...</mat-option>
                                </ng-template>
                            </mat-select>
                        </div>
                        <div *ngIf="typeSelected == 'on_date'" class="w-full">
                            <label for="on_date" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Al día</span>:</label>
                            <mat-slide-toggle id="on_date" name="on_date" formControlName="on_date"></mat-slide-toggle>
                        </div>
                    </ng-container>
                    <div *ngIf="dataDynamic.length > 0" class="w-full">
                        <datatable-capyei
                            [dataSource]="dataSourceDynamic"
                            [columnsdef]="columnsDynamic"
                            [customTemplate]="customTemplate">
                            <!-- TODO: Si se manda "customTemplate" debes añadir un default al switch de lo que se manda -->
                            <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
                                <ng-container [ngSwitch]="column.id">
                                    <ng-container *ngSwitchCase="'action_buttons'">
                                        <button-cy (onPress)="removeItem(((row.name && row.name != '-') ? row.name : ('ID: '+row.id)), row.id, 'dynamic', 'delete-list-group-filter')" addClass="btn-action danger" label="" icon="fa-light fa-trash" [iconOnly]="true"></button-cy>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <span class="two_line-hidden">{{ item ?? '-' }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </datatable-capyei>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.type == 'static'">
                    <div class="w-full">
                        <label for="customer_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Contacto(s)</span>:</label>
                        <ng-container *ngIf="names.length > 0; else elseCustomersData">
                            <mat-chip-grid #chipGrid [placeholder]="'Buscar...'">
                                <mat-chip-row *ngFor="let item of selectedNames; let i = index" [removable]="true" (removed)="remove(i)">
                                    {{ item.name }}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input
                                    placeholder="Seleccionar a..."
                                    #nameInput
                                    [formControl]="nameCtrl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipGrid"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="add($event)"
                                    class="!m-0 !block !flex-none !w-full !box-border !py-2 !px-4 !h-auto !bg-slate-50 !text-base !rounded !border-solid !border !border-[#F1F1F1] !focus:border-[#F1F1F1] !focus:ring-0"
                                >
                            </mat-chip-grid>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="mat__check-option-custom">
                                <mat-option *ngFor="let item of filteredNames | async" [value]="item">
                                    {{ item.name }}
                                    <mat-icon *ngIf="selectedNames.includes(item)" class="icon__multiple-check">check</mat-icon>
                                </mat-option>
                            </mat-autocomplete>
                        </ng-container>
                        <ng-template #elseCustomersData>
                            <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">No se encontraron elementos</div>
                        </ng-template>
                        <ng-container *ngIf="hasError">
                            <div *ngIf="this.dataForm.get('customer_ids')?.errors?.['required']" class="error__tg">
                                El campo <b>Contacto(s)</b> debe tener al menos un contacto seleccionado.
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="dataStatic.length > 0" class="w-full">
                        <datatable-capyei
                            [dataSource]="dataSourceStatic"
                            [columnsdef]="columnsStatic"
                            [customTemplate]="customTemplate">
                            <!-- TODO: Si se manda "customTemplate" debes añadir un default al switch de lo que se manda -->
                            <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
                                <ng-container [ngSwitch]="column.id">
                                    <ng-container *ngSwitchCase="'action_buttons'">
                                        <button-cy (onPress)="removeItem(((row.name && row.name != '-') ? row.name : ('ID: '+row.id)), row.id, 'static', 'delete-list-group-contact')" addClass="btn-action danger" label="" icon="fa-light fa-trash" [iconOnly]="true"></button-cy>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <span class="two_line-hidden">{{ item ?? '-' }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </datatable-capyei>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cerrar</button>

            <button mat-button type="button" (click)="submitForm()" class="cy__btn btn__second">
                <i class="fa-light fa-check"></i> Agregar Filtro
            </button>
        </div>
    </div>
</div>
