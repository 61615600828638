import { Component, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { MatTableDataSource } from '@angular/material/table';
import { Column, DynamicFormFieldModel } from 'src/app/interfaces/interfaces';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';

// import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import { DatatableComponent } from 'src/app/components/datatable-custom/datatable.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';

import {UserCustomComponent} from 'src/app/components/user-custom/user-custom.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MailingMsgModalPage } from 'src/app/components/msg-modal/msg-modal.page';
// import { ModalBroadcastFormComponent } from '../components/form/form.component';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';
import { FilterFormModal } from 'src/app/components/filter-form/filter-form.component';
import { FilterModalComponent } from '../components/filter-modal/filter-modal.component';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'reminder-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatatableComponent,
    PaginatorComponent,
    UserCustomComponent,
    MatDialogModule,
    MatButtonModule,
    ButtonCapYeiComponent
  ]
})
export class ReminderListPage implements OnInit, OnDestroy {

  title = 'Tareas';

  pagination = null;
  page: number = 1;
  data:any = [];

  organizations:any = [];
  codOrganization = new FormControl(null);
  model = 'get-lead-reminders';

  isAdmin:boolean = false;

  noData: boolean = false;
  loadingData: boolean = false;

  public dataSource = new MatTableDataSource();

  columns:Column[]=[
    {id:'index',label:'#',hideOrder:0,width:100},
    {id:'id',label:'ID',hideOrder:1},
    {id:'user',label:'Vendedor',hideOrder:2},
    {id:'name',label:'Nombre',hideOrder:2},
    {id:'detail',label:'Detalle',hideOrder:3},
    {id:'status',label:'Estado',hideOrder:4},
    {id:'type',label:'Tipo',hideOrder:5},
    {id:'date_time',label:'Fecha y hora',hideOrder:6},
    // {id:'date',label:'Fecha',hideOrder:6},
    // {id:'time',label:'Hora',hideOrder:6},
    // {id:'action_buttons',label:'Acciones',hideOrder:1},
  ];

  visibilityBtns = {
    create: false,
    read: false,
    edit: false,
    remove: false,
  };

  formFilter:any = null;

  // fieldsFilterForm: DynamicFormFieldModel[] = [
  //   {
  //     name: 'id',
  //     label: 'ID',
  //     col: '6',
  //     placeholder: '',
  //     type: 'text',
  //     show: true,
  //     action: 'filter',
  //     value: '',
  //   },
  //   {
  //     name: 'name',
  //     label: 'Nombre / Apellido',
  //     col: '6',
  //     placeholder: '',
  //     type: 'name',
  //     show: true,
  //     action: 'filter',
  //     value: '',
  //   },
  //   {
  //     name: 'email',
  //     label: 'Correo electrónico',
  //     col: '6',
  //     placeholder: '',
  //     type: 'email',
  //     show: true,
  //     action: 'filter',
  //     value: '',
  //   },
  //   {
  //     name: 'memberships_name_t',
  //     label: 'Perfil Asociado',
  //     col: '6',
  //     placeholder: '',
  //     type: 'text',
  //     show: true,
  //     action: 'filter',
  //     value: '',
  //   },
  //   {
  //     name: 'memberships_ci_number_t',
  //     label: 'Carnet de identidad asociado',
  //     col: '6',
  //     placeholder: '',
  //     type: 'text',
  //     show: true,
  //     action: 'filter',
  //     value: '',
  //   },
  // ];

  // form = {};

  constructor(
    public dialog: MatDialog,
    private metaTitle: Title,
    private userModel: UserModel,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    public alert: AlertService,
    public xServices: XtrasService,
  ) {
    this.initializeApp();
  }

  clickEvent(fila: any, action: string, id: any, item: any) {
    // if(action == 'remove') {
    //   this.removeItem((`"ID: ${item['id']}"`), id);
    // } else if(action == 'active') {
    //   this.activeItem((`"ID: ${item['id']}"`), id);
    // } else if(action == 'send') {
    //   this.confirmSend(id);
    // } else {
    //   // this.router.navigate([`/${this.model}`, action, id]);
    //   this.openForm(action, id);
    // }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  initializeApp() {
    this.pagination = null;
    this.page = 1;
    this.data = [];
    this.dataSource.data = [];
    
    this.noData = false;
    this.loadingData = true;
    let data = { page: this.page };
    if(this.formFilter) {
      data = { ...data, ...this.formFilter};
    }
    let queryParams = new HttpParams({ fromObject: data });
    this.http.getRequest(SettingsProvider.getUrl(`${this.model}`), null, queryParams).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.processData(result);
      } else {
        this.loadingData = false;
        this.noData = true;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      console.log(error);
      this.loadingData = false;
      this.noData = true;
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  processData(result:any) {
    this.data = result['data']['data'];
    let rows: any[] = [];

    result['data']['data'].forEach((element:any,index:number)=> {
      element['index'] = index +1;
      let items = {
        ...element,
        id: element['id'] ?? 0,
        date_time: element['date'] ? (element['date']+(element['time'] ? (' - '+element['time']) : '')) :null,
      };
      rows.push(items);
    });
    this.dataSource.data = rows;

    this.loadingData = false;
    if(rows.length > 0) {
      this.noData = false;
      this.pagination = result['data'];
      this.page = result['data']['current_page'] ?? 1;
    } else {
      this.noData = true;
    }

    if(result['actions']) {
      this.showActionsButtons(result);
    }
  }
  
  goToPaginate(url: any) {
    if(url) {
      this.load.loadingShow();
      this.pagination = null;
      this.data = [];
      this.dataSource.data = [];
      let data = {};
      if(this.formFilter) {
        data = this.formFilter;
      }
      let queryParams = new HttpParams({ fromObject: data });
      this.http.getRequest(SettingsProvider.getCustomUrl(url), null, queryParams).subscribe((result: any)=> {
        if(result['status']) {
          this.load.dismiss();
          this.processData(result);
        } else {
          this.xServices.getError(result, true, '/account');
        }
      }, error => {
        this.load.dismiss();
        console.log(error);
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  ngOnDestroy(): void {
  }

  showActionsButtons(result: any) {
    this.visibilityBtns = {
      create: result['actions']['create'],
      read: result['actions']['read'],
      edit: result['actions']['edit'],
      remove: result['actions']['delete'],
    };
  }

  openForm(action: string, id?: any): void {
    // const dialogRef = this.dialog.open(ModalBroadcastFormComponent, {
    //   width: '550px',
    //   disableClose: false,
    //   autoFocus: false,
    //   data: {
    //     action: action,
    //     id: (id ?? null),
    //   }
    // });

    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if(result&&result['reloadData']) {
    //     this.initializeApp();
    //   }
    // });
  }

  filterForm() {
    // // * Dynamic filter form
    // const dialogRef = this.dialog.open(FilterFormModal, {
    //   width: '1000px',
    //   maxWidth: '100%',
    //   disableClose: false,
    //   autoFocus: false,
    //   data: {
    //     fields: this.fieldsFilterForm,
    //   },
    // })
    // dialogRef.afterClosed().subscribe((data:any)=>{
    //   if(data&&(data['form']&&data['fields'])) {
    //     this.form = data['form'] ?? {};
    //     this.initializeApp();
    //     this.fieldsFilterForm = data['fields'] ?? [];
    //   }
    // });

    const dialogRef = this.dialog.open(FilterModalComponent, {
      width: '550px',
      disableClose: false,
      autoFocus: false,
      data: {
        form: this.formFilter ?? null
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if(result) {
        this.formFilter = result['data'] ?? null;
        this.initializeApp();
      }
    });
  }
}