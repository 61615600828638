import { AlertService } from './../../../services/alert.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserModel } from 'src/app/services/user-model';
import { Title } from '@angular/platform-browser';
import { XtrasService } from 'src/app/services/xtras.service';
import { CommonModule, DatePipe } from '@angular/common';
import { DatatableComponent } from 'src/app/components/datatable/datatable.component';
import {UserCustomComponent} from 'src/app/components/user-custom/user-custom.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SettingsProvider } from 'src/app/services/settings';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ButtonCapYeiComponent } from 'src/app/components/button/button.component';
import { HttpParams } from '@angular/common/http';



declare var $: any;

@Component({
  selector: 'notifications-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    DatatableComponent,
    UserCustomComponent,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonCapYeiComponent,
  ]
})
export class NotificationsListPage implements OnInit, OnDestroy {

  title = 'Notificaciones';

  private unsubscribe: Subscription[] = [];

  data:any = [];

  notifications:any[] = [];

  dataForm = new FormGroup({
    start_date:     new FormControl('' as any),
    end_date:       new FormControl('' as any),
  });

  start_date: Date | null = null;
  end_date: Date | null = null;

  constructor(
    private metaTitle: Title,
    private userModel: UserModel,
    public xServices: XtrasService,
    public dialog: MatDialog,
    private http: HttpClientProvider,
    private toast: NotificationService,
    private load: LoadingService,
    private datePipe: DatePipe,
    private router: Router,
    private alert: AlertService,
  ) {
    let formValues = JSON.parse(localStorage.getItem('formNotifications') ?? '{}');
    if (formValues && Object.keys(formValues).length > 0) {
      this.start_date = formValues.start_date_format ? new Date(formValues.start_date_format) : null;
      this.end_date = formValues.end_date_format ? new Date(formValues.end_date_format) : null;
      this.dataForm.patchValue(formValues);
    }

    this.dataForm.get('start_date')?.valueChanges.subscribe(value => {
      if (value == null || value == '') {
        this.dataForm.get('end_date')?.clearValidators();
      } else {
        this.dataForm.get('end_date')?.setValidators([Validators.required]);
      }
      this.dataForm.get('end_date')?.updateValueAndValidity();
    });
    this.initializeApp();
  }

  initializeApp() {
    let form = {
      ...this.dataForm.value,
      start_date_format: this.start_date,
      end_date_format: this.end_date,
    };
    localStorage.setItem('formNotifications', JSON.stringify(form));

    this.load.loadingShow();
    this.notifications = [];
    let queryParams = new HttpParams({ fromObject: this.dataForm.value });
    const subscr = this.http.getRequest(SettingsProvider.getUrl(`get-notification`), null, queryParams).subscribe((result: any) => {
      console.log(result);
      this.load.dismiss();
      if(result['status']) {
        this.notifications = result['data'] ?? [];
      }
    }, () => {
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
    this.unsubscribe.push(subscr);
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  dateChange(event: any, field: any) {
    let date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.dataForm.get(field)?.patchValue(date ?? '');
  }

  clearFilter() {
    this.start_date = null;
    this.end_date = null;
    this.dataForm.patchValue({
      start_date:     '',
      end_date:       '',
    });
    localStorage.setItem('formNotifications', JSON.stringify({}));
    setTimeout(() => {
      this.initializeApp();
    }, 1);
  }

  async toUrlProcess(id: any, url: any) {
    this.load.loadingShow();
    if(url) {
      try {
        await this.http.getRequest(SettingsProvider.getUrl(`read-notification/${id}`)).toPromise();
        this.load.dismiss();
        this.router.navigateByUrl(url);
      } catch (error) {
        this.load.dismiss();
        this.router.navigateByUrl(url);
        console.log(error);
      }
    }
  }

  deleteItem(item: any) {
    this.alert.alertCapYei(
      '¿Eliminar notificación?', 
      'question', 
      { 
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: 'Confirmar',
      },
      '¿Confirma la eliminación de esta notificación?'
    ).then(data => {
      console.log(data);
      if(data?.isConfirmed) {
        const subscr = this.http.postRequest(SettingsProvider.getUrl(`delete-notification/${item.id}`), {}).subscribe({
          next: (result) => {
            this.load.dismiss();
            this.initializeApp();
          }, error: (error => {
            this.load.dismiss();
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
          })
        });
        this.unsubscribe.push(subscr);
      }
    })
  }
}