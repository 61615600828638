<input type="hidden" class="!hidden col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12 col-auto col-span-full sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6 sm:col-span-7 sm:col-span-8 sm:col-span-9 sm:col-span-10 sm:col-span-11 sm:col-span-12">
<div *ngIf="(formItem.show == null || formItem.show == undefined) || formItem.show == true" [formGroup]="form" class="block w-full sm:{{ (formItem.col != null || formItem.col != undefined) ? ( (formItem.col === 'auto') ? ('col-'+formItem.col) : ('col-span-'+formItem.col)) : '' }} col-span-12">
  <label class="block w-full text-slate-800 text-sm font-semibold mb-2">
    <span [ngClass]="{'required': hasRequiredField(form.get(formItem.name))}">{{ formItem.label }}</span>
  </label>

  <ng-container *ngIf="type != null">

    <div class="w-full block" *ngIf="type == 'text' || type == 'name' || type == 'email' || type == 'tel' || type == 'number' || type == 'url' || type == 'month' || type == 'time' || type == 'week' || type == 'password'">
      <input maxlength="255" [type]="type" [name]="formItem.name" [formControlName]="formItem.name" [placeholder]="formItem.placeholder ?? 'Escriba aquí...'" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base border !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0">
    </div>

    <div class="w-full block" *ngIf="type == 'textarea'">
      <textarea maxlength="600" [name]="formItem.name" [formControlName]="formItem.name" [placeholder]="formItem.placeholder ?? 'Escriba aquí...'" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base border !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0"></textarea>
    </div>

    <div class="w-full block" *ngIf="type == 'checkbox'">
      <label class="relative grid grid-cols-[minmax(0,2.75rem)_minmax(0,1fr)] gap-2 items-center cursor-pointer" [ngClass]="{'!grid-cols-1 justify-items-center': !formItem.placeholder}">
        <input type="checkbox" class="sr-only peer" [name]="formItem.name" [formControlName]="formItem.name">
        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
        <span *ngIf="formItem.placeholder" class="text-sm font-medium text-gray-900 dark:text-gray-300 select-none">{{ formItem.placeholder }}</span>
      </label>
    </div>

    <div class="w-full flex flex-wrap gap-4" *ngIf="type == 'radio'">
      <div *ngFor="let option of formItem.options; let i = index" class="grid grid-cols-[minmax(0,1rem)_minmax(0,1fr)] gap-2 items-center cursor-pointer">
        <input type="radio" [value]="option.id" [name]="formItem.name" [formControlName]="formItem.name" [id]="formItem.name+'-'+option.id+'-'+(i+1)" class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
        <label [for]="formItem.name+'-'+option.id+'-'+(i+1)" class="text-sm font-medium text-gray-900 dark:text-gray-300 cursor-[inherit] select-none">{{ option.name }}</label>
      </div>
    </div>

    <div class="w-full block relative input__date type_full" *ngIf="type == 'date'">
      <input [name]="formItem.name" [(ngModel)]="date" (ngModelChange)="dateChange($event)" [ngModelOptions]="{standalone: true}" matInput [matDatepicker]="picker" [placeholder]="formItem.placeholder ?? 'DD/MM/YYYY'" readonly class="input__date-item">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <div class="w-full block" *ngIf="type == 'select'">
      <mat-select no-space #select="matSelect" [attr.name]="formItem.name" [formControlName]="formItem.name" class="custom__mat-select">
        <ng-container *ngIf="formItem.select_options != null && formItem.select_options.length >= 1">
          <div class="filter_option">
            <input placeholder="Buscar" appSearch (appSearch)="filterTerm = $event" class="!outline-none !ring-0 !border-0">
          </div>
        </ng-container>
        <mat-option selected [value]="null">{{ formItem.placeholder ?? 'Seleccione una opción' }}</mat-option>

        <mat-option [value]="option.id" *ngFor="let option of (formItem.select_options ?? []) | filter: filterTerm : 'name'">
          {{ option.name }}
        </mat-option>
      </mat-select>
    </div>

    <div class="w-full block input__range" *ngIf="type == 'range'">
      <div class="bg-[#F5F7F9] rounded p-2">
        <mat-slider [min]="formItem.min" [max]="formItem.max" [step]="formItem.step" [formControlName]="formItem.name" showTickMarks discrete [displayWith]="formatLabel">
          <input matSliderThumb [name]="formItem.name">
        </mat-slider>
      </div>
    </div>

    <div class="w-full block" *ngIf="type == 'color'">
      <div class="bg-[#F5F7F9] rounded p-2 flex gap-2 items-center justify-center">
        <input type="color" [formControlName]="formItem.name" [name]="formItem.name" class="input__color !w-full">
        <span >{{ form.value[formItem.name] || '-' }}</span>
      </div>
    </div>

    <div class="w-full block" *ngIf="type == 'file'">
      <div class="w-full grid {{ filePreview ? 'sm:grid-cols-[minmax(0,88px)_minmax(0,1fr)]' : '' }} grid-cols-1 gap-4 items-center">
        <div *ngIf="filePreview" class="sm:w-full w-[180px] max-w-full mx-auto block relative before:block before:pb-[100%]">
          <a [href]="filePreview" [title]="filePreview" target="_blank" class="absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded border border-dashed border-[#707070]">
            <img src="/assets/img/icons/default-app.svg" class="border absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded object-cover object-center" alt="Image">
          </a>
        </div>
        <div class="content__file-input cursor-pointer select-none">
          <label [for]="formItem.name+'-file_'+1" class="btn__image-change flex items-center py-2 px-4 rounded bg-sky-100 hover:bg-sky-500 text-sky-500 hover:text-white cursor-pointer text-base">
            <i class="fa-light fa-file mr-2"></i>
            <span>{{ (fileItem && fileItem.name) ? fileItem.name : 'Seleccionar Archivo' }}</span>
          </label>
          <input *ngIf="action != 'read'" type="file" [name]="formItem.name" [id]="formItem.name+'-file_'+1" (change)="fileChange($event)" class="file__img hidden">
        </div>
      </div>
    </div>

    <div class="w-full block" *ngIf="type == 'image'">
      <div class="w-full grid sm:grid-cols-[minmax(0,88px)_minmax(0,1fr)] grid-cols-1 gap-4 items-center">
        <div class="sm:w-full w-[200px] max-w-full mx-auto block relative before:block before:pb-[100%]">
          <div class="absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded border border-dashed border-[#707070]">
            <ng-container *ngIf="filePreview; else elseFilePreview">
              <img [src]="filePreview" class="border absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded object-cover object-center" alt="Image">
            </ng-container>
            <ng-template #elseFilePreview>
              <!-- TODO: Activar cuando esté funcionando -->
              <!-- <ng-container *ngIf="action !== 'create'"> -->
                <img *ngIf="fileItem != null && fileItem.file" [src]="fileItem.file" class="border absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded object-cover object-center" [alt]="fileItem.name">
              <!-- </ng-container> -->
            </ng-template>
          </div>
        </div>
        <div class="content__file-input cursor-pointer select-none">
          <label [for]="formItem.name+'-image_'+1" class="btn__image-change flex items-center py-2 px-4 rounded bg-sky-100 hover:bg-sky-500 text-sky-500 hover:text-white cursor-pointer text-base">
            <i class="fa-light fa-image mr-2"></i>
            <span>{{ (fileItem && fileItem.name) ? fileItem.name : 'Seleccionar Imagen' }}</span>
          </label>
          <input *ngIf="action != 'read'" type="file" [name]="formItem.name" accept="image/*" [id]="formItem.name+'-image_'+1" (change)="showPreview($event)" class="file__img hidden">
        </div>
      </div>
    </div>
  </ng-container>


  <div *ngIf="regFormControl[formItem.name].invalid && (regFormControl[formItem.name].dirty || regFormControl[formItem.name].touched || submitted)" class="text-red-500 text-xs italic">
    <div *ngIf="regFormControl[formItem.name].errors?.['required']">
      El campo <b>{{formItem.label}}</b> es requerido.
    </div>

    <!-- <div *ngIf="regFormControl[formItem.name].errors?.['requiredTrue']">
      El campo <b>{{formItem.label}}</b> debe estár marcado (check).
    </div> -->

    <div *ngIf="regFormControl[formItem.name].errors?.['number']">
      El campo <b>{{formItem.label}}</b> debe contener solo números.
    </div>

    <div *ngIf="regFormControl[formItem.name].errors?.['minlength']">
      El campo <b>{{formItem.label}}</b> debe tener al menos "{{regFormControl[formItem.name].errors?.['minlength']['requiredLength']}}" carácteres.
    </div>

    <div *ngIf="regFormControl[formItem.name].errors?.['maxlength']">
      El campo <b>{{formItem.label}}</b> debe tener un máximo de "{{regFormControl[formItem.name].errors?.['maxlength']['requiredLength']}}" carácteres.
    </div>

    <div *ngIf="regFormControl[formItem.name].errors?.['min']">
      El campo <b>{{formItem.label}}</b> debe contener al menos "{{regFormControl[formItem.name].errors?.['min']['min']}}" elementos.
    </div>

    <div *ngIf="regFormControl[formItem.name].errors?.['min']">
      El campo <b>{{formItem.label}}</b> debe tener un máximo de "{{regFormControl[formItem.name].errors?.['min']['min']}}" elementos.
    </div>

    <div *ngIf="regFormControl[formItem.name].errors?.['email']">
      El campo <b>{{formItem.label}}</b> debe ser un email válido.
    </div>

    <!-- {{ regFormControl[formItem.name].errors | json }} -->
  </div>
</div>
