<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300 uppercase">
        {{ xServices.getAction(action) }} CONTACTO
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full">
                <label for="first_name" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Nombres</span>:</label>
                <input type="name" name="first_name" maxlength="600" formControlName="first_name" id="last_name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="last_name" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Apellidos</span>:</label>
                <input type="name" name="last_name" maxlength="600" formControlName="last_name" id="last_name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="ci_number" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">C.I.</span>:</label>
                <input type="text" name="ci_number" maxlength="600" formControlName="ci_number" id="ci_number" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="email" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Correo electrónico</span>:</label>
                <input type="email" name="email" maxlength="600" formControlName="email" id="email" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
            <div class="w-full">
                <label for="cellphone" class="text-secondary block w-full text-sm font-medium mb-2"><span class="required">Celular</span>:</label>
                <input type="phone" name="cellphone" maxlength="600" formControlName="cellphone" id="cellphone" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <button mat-button type="button" (click)="submitForm()" class="cy__btn btn__second">
                <i class="fa-light fa-check"></i> {{ xServices.getAction(action) }} contacto
            </button>
        </div>
    </div>
</div>
