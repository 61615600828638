<div class="w-full bg-white">
    <div class="w-full block header_modal">
        <h1 class="text-center text-[color:var(--second-color)] text-2xl m-0 font-bold font__title pb-4 border-b border-b-gray-300"> {{ data.type == 'select' ? 'Agregar Usuarios' : 'Invitar Usuario' }}</h1>
    </div>
    
    <form (ngSubmit)="submitForm()" class="block w-full p-0 m-0">
        <div class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <p class="m-0 text-base text-center font-medium text-gray-500">Agregue al usuario que desea invitar a la organización.</p>
            <div class="chips">
                <mat-form-field class="w-full !block" appearance="fill">
                    <mat-label>Usuarios</mat-label>
                    <mat-chip-grid #chipGrid>
                        <mat-chip
                            *ngFor="let item of names; let i = index"
                            (removed)="remove(item, i)">
                            {{ item.name }}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <input
                            placeholder="Invitar a..."
                            #nameInput
                            [formControl]="nameCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)"
                            class="!m-0 !mt-2 !block !flex-none !w-full !box-border">
                    </mat-chip-grid>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let user of filteredNames | async" [value]="user">
                            {{ user.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    
        <div class="w-full flex flex-wrap justify-between actions_modal">
            <div class="block"></div>
            <div class="flex flex-wrap justify-between gap-4">
                <button cdkFocusInitial mat-button class="!hidden">Autofocus disabled</button>
                <button mat-button type="button" (click)="onNoClick()">Cancelar</button>
                <!-- <button mat-button [mat-dialog-close]="data.input_name">Agregar</button> -->
                <button *ngIf="data.type == 'select'" mat-button type="submit" [disabled]="!users||users == ''" class="bg-[color:var(--primary-color)] text-white disabled:bg-gray-300">Agregar</button>
                <button *ngIf="data.type == 'input'" mat-button type="submit" [disabled]="(!names||names.length == 0)||!organization_id" class="bg-[color:var(--primary-color)] text-white disabled:bg-gray-300">Agregar</button>
            </div>
        </div>
    </form>
</div>