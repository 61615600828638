<div mat-dialog-title class="!m-0 w-full block before:!hidden">
  <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300 uppercase">
    {{ actionText }} {{ title }}
  </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
  <div class="block w-full p-6">
    <form class="account-settings-form" (ngSubmit)="submitForm()" [formGroup]="dataForm">
      <div class="w-full mt-2 grid grid-cols-1 gap-4">
        <div class="w-full">
          <label for="name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Nombre del Template</span>:</label>
          <input type="text" name="name" maxlength="255" formControlName="name" id="name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
        </div>

        <div class="w-full">
          <label for="title" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Asunto</span>:</label>
          <input type="text" name="title" maxlength="255" formControlName="title" id="title" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
        </div>

        <div class="w-full">
          <label for="type" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Tipo de Template</span>:</label>
          <mat-select id="type" name="type" formControlName="type" placeholder="Seleccione una opción" class="custom__mat-select">
            <div class="filter_option">
              <input placeholder="Buscar" appSearch (appSearch)="filterTemplate = $event" class="!outline-none !ring-0 !border-0">
            </div>
            <ng-container *ngIf="!loadingTemplate; else elseLoadingTemplate">
              <ng-container *ngIf="!noDataTemplate; else elseTemplate">
                <mat-option *ngFor="let item of types | filter: filterTemplate : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
              </ng-container>
              <ng-template #elseTemplate>
                <mat-option [value]="null">No se encontraron tipos de templates</mat-option>
              </ng-template>
            </ng-container>
            <ng-template #elseLoadingTemplate>
              <mat-option [value]="null">Cargando tipos de templates...</mat-option>
            </ng-template>
          </mat-select>
        </div>

        <div *ngIf="typeSelected == 'image'" class="w-full">
          <label for="image" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span>Imagen</span>:</label>
          <div class="content__file-input cursor-pointer">
            <label for="image" class="btn__image-change flex items-center py-2 px-4 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white cursor-pointer text-base">
              <i class="fa-light fa-image mr-2"></i>
              <span>Seleccionar Imagen</span>
            </label>
            <input *ngIf="action != 'read'" type="file" id="image" accept="image/*" (change)="showPreview($event)" required class="file__img hidden">
          </div>
          <div *ngIf="imagePreview" class="content__img-profile border border-2 border-dotted rounded-lg block max-w-full w-fit mx-auto overflow-hidden max-h-[400px] mt-2 p-2">
            <div class="block rounded-md overflow-hidden max-h-[calc(400px_-_(1rem_+_4px))]"><img [src]="imagePreview" alt="Account Image"></div>
          </div>
        </div>

        <div *ngIf="typeSelected == 'video'" class="w-full">
          <label for="video_url" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Video</span>:</label>
          <input type="text" name="video_url" maxlength="255" formControlName="video_url" id="video_url" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
        </div>
    
        <div *ngIf="typeSelected == 'text'" class="w-full">
          <label for="content" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Contenido</span>:</label>
          <!-- plugins: 'a11ychecker advcode advlist advtable anchor autocorrect autolink autoresize autosave casechange charmap checklist code codesample directionality editimage emoticons export footnotes formatpainter fullscreen help image importcss inlinecss insertdatetime link linkchecker lists media mediaembed mentions mergetags nonbreaking pagebreak pageembed permanentpen powerpaste preview quickbars save searchreplace table tableofcontents tinydrive tinymcespellchecker typography visualblocks visualchars wordcount', -->
          <editor
            [apiKey]="environment.tinyKey"
            [init]="{
              plugins: 'advlist anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss insertdatetime link linkchecker lists media nonbreaking pagebreak preview quickbars save searchreplace table tinydrive visualblocks visualchars wordcount',
              toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code checklist | help',
              language: 'es_MX',
              language_url : '/assets/xtras/TinyMCE/language_es_MX.js'
            }"
            formControlName="content"
          ></editor>
        </div>

        <div *ngIf="action != 'create' && createdAt" class="flex flex-wrap justify-between gap-2 mt-5 w-full">
          <div class="sm:col-span-6 col-span-12">
            <p class="text-sm">creación: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(createdAt) }}</span></p>
          </div>
          <div class="sm:col-span-6 col-span-12" *ngIf="updatedAt">
            <p class="text-sm">edición: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(updatedAt) }}</span></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
  <div class="w-full flex flex-wrap justify-between actions_modal">
    <div class="block"></div>
    <div class="flex flex-wrap justify-between gap-4">
      <ng-container *ngIf="action != 'read'; else elseActionButton">
        <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

        <button mat-button type="button" (click)="submitForm()" [disabled]="action == 'read'||dataForm.invalid" class="cy__btn btn__second">
          <i class="fa-light fa-check"></i> {{ actionText }} Template
        </button>
      </ng-container>
      <ng-template #elseActionButton>
        <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cerrar</button>
      </ng-template>
    </div>
  </div>
</div>
