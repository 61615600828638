import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { UserModel } from 'src/app/services/user-model';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';

declare var $: any;

@Component({
	selector: 'layout-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterOutlet, SidebarComponent, HeaderComponent, FooterComponent],
	// schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainComponent implements OnInit {

	user$: Observable<UserType>;

	title_page: any = '';

	organizations = null;

	constructor(
		private authService: AuthService,
		public userModel: UserModel
	) {
		this.user$ = this.authService.user$;
	}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		$(function() {
			$('.toogle_dropdown').on('click', function(this: any) {
				$(this).next('.dropdown').slideToggle();
			});

			$('.toogle_dropdown_a').on('click', function(this: any) {
				$('.dropdown').slideUp()
			});

			$(document).on('click', function(e: any) {
				var target = e.target;
				if (!$(target).is('.toogle_dropdown') && !$(target).parents().is('.toogle_dropdown')) {
					$('.dropdown').slideUp()
				}
			});

			$('.content_header-space').css('padding-top', `${$('.header__menu').outerHeight()}px`);

			$('.btn_a-sidebar').on('click', function() {
				$('.bg__sidebar_left').fadeOut();
				$('.sidebar_left').removeClass('active');
			});
		});

		$('.btn__sidebar, .bg__sidebar_left').on('click', function() {
			$('.bg__sidebar_left').fadeToggle();
			$('.sidebar_left').toggleClass('active');
		});
	}

}
