<div class="pt-5 lg:px-5 px-0 pb-[5rem] w-full container mx-auto">
  <div class="lg:grid lg:grid-cols-[minmax(0,100%)] flex md:flex-col flex-wrap gap-4 w-full">
    <div class="w-full overflow-hidden bg-white rounded-2xl shadow py-8 md:px-10 px-5 self-start">
      <div class="grid grid-cols-12 justify-between">
        <div class="sm:col-span-6 col-span-12">
          <div class="flex flex-wrap gap-4 items-center mb-4">
            <h5 class="text-slate-800 font-semibold text-lg inline-block relative m-0 pb-1 before:absolute before:top-full before:left-0 before:w-[60%] before:h-[2px] before:bg-[color:var(--primary-color)] w-max">
              {{ title }}
            </h5>
            <a (click)="back()" class="flex gap-1 items-center justify-center cursor-pointer">
              <i class="fa-regular fa-circle-left"></i> <span class="md:block hidden leading-none">Volver</span>
            </a>
          </div>
          <p>(<b><span class="required"></span></b> ) Indica que el campo es obligatorio.</p>
        </div>
        <div class="sm:col-span-6 col-span-12 text-right">
          <button *ngIf="action != 'create'" [routerLink]="['/audits']" [queryParams]="{ node: 'user', codItem: id }" type="button" class="w-auto text-third border border-third bg-white hover:bg-third hover:text-white focus:ring-4 focus:ring-third font-semibold rounded-lg text-sm px-4 py-2 focus:outline-none">
            <i class="fa fa-history" aria-hidden="true"></i> Historial
          </button>
        </div>
      </div>

      <form class="account-settings-form grid grid-cols-1" (ngSubmit)="submit()" [formGroup]="dataForm">
        <div class="w-full mt-2 grid grid-cols-12 gap-4">

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Nombres</span>:</label>
            <input type="text" name="name" maxlength="255" formControlName="name" id="name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="last_name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Apellido paterno</span>:</label>
            <input type="text" name="last_name" maxlength="255" formControlName="last_name" id="last_name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="mother_last_name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Apellido materno</span>:</label>
            <input type="text" name="mother_last_name" maxlength="255" formControlName="mother_last_name" id="mother_last_name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="email" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Correo electrónico</span>:</label>
            <input type="email" name="email" maxlength="255" formControlName="email" id="email" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="city_id" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Ciudad</span>:</label>
            <mat-select id="city_id" name="city_id" formControlName="city_id" placeholder="Seleccione una opción" class="custom__mat-select">
              <div class="filter_option">
                <input placeholder="Buscar" appSearch (appSearch)="filterCities = $event" class="!outline-none !ring-0 !border-0">
              </div>
              <ng-container *ngIf="!loadingCities; else elseLoadingCities">
                <ng-container *ngIf="xServices.lengthItems(cities); else elseCities">
                  <mat-option *ngFor="let item of cities | filter: filterCities : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                </ng-container>
                <ng-template #elseCities>
                  <mat-option [value]="null">No se encontraron ciudades</mat-option>
                </ng-template>
              </ng-container>
              <ng-template #elseLoadingCities>
                <mat-option [value]="null">Cargando ciudades...</mat-option>
              </ng-template>
            </mat-select>
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="roles" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="">Rol</span>:</label>
            <mat-select id="roles" name="role_id" formControlName="role_id" placeholder="Seleccione una opción" class="custom__mat-select">
              <div class="filter_option">
                <input placeholder="Buscar" appSearch (appSearch)="filterRoles = $event" class="!outline-none !ring-0 !border-0">
              </div>
              <ng-container *ngIf="!loadingRoles; else elseLoadingRoles">
                <ng-container *ngIf="xServices.lengthItems(roles); else elseRoles">
                  <mat-option *ngFor="let item of roles | filter: filterRoles : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                </ng-container>
                <ng-template #elseRoles>
                  <mat-option [value]="null">No se encontraron roles</mat-option>
                </ng-template>
              </ng-container>
              <ng-template #elseLoadingRoles>
                <mat-option [value]="null">Cargando roles...</mat-option>
              </ng-template>
            </mat-select>
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="cellphone" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Teléfono</span>:</label>
            <input type="phone" name="cellphone" maxlength="255" formControlName="cellphone" id="cellphone" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="address" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Dirección</span>:</label>
            <input type="text" name="address" maxlength="255" formControlName="address" id="address" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="active" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Estado</span>:</label>
            <mat-select id="active" name="active" formControlName="active" placeholder="Seleccione una opción" class="custom__mat-select">
              <div class="filter_option">
                <input placeholder="Buscar" appSearch (appSearch)="filterStatus = $event" class="!outline-none !ring-0 !border-0">
              </div>
              <mat-option *ngFor="let item of status | filter: filterStatus : 'name'" [value]="item['value']" >{{ item['name'] }}</mat-option>
            </mat-select>
          </div>

          <div class="xl:col-span-4 md:col-span-6 col-span-12">
            <label for="password" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Contraseña</span>:</label>
            <input type="text" name="password" maxlength="255" formControlName="password" id="password" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
          </div>

          <div class="xl:col-span-8 col-span-12" *ngIf="action != 'read'">
            <label class="block w-full text-slate-800 text-sm font-semibold mb-2">Generar Contraseña:</label>
            <div class="flex flex-wrap gap-2">
              <button (click)="generatePassword()" type="button" class="font__text cursor-pointer inline-flex items-center gap-2 text-base font-medium py-2 px-10 rounded bg-sky-500 hover:bg-sky-600 text-white">
                Autogenerar
              </button>
              <button type="button" class="font__text cursor-pointer inline-flex items-center gap-2 text-base font-medium py-2 px-10 rounded bg-sky-500 hover:bg-sky-600 text-white">
                Enviar por email
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center justify-between mt-5">
          <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-wrap gap-5" *ngIf="action != 'create'">
              <div class="sm:col-span-6 col-span-12" *ngIf="createdAt">
                <p class="text-sm">Fecha creación: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(createdAt) }}</span></p>
              </div>
              <div class="sm:col-span-6 col-span-12" *ngIf="updatedAt">
                <p class="text-sm">Fecha edición: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(updatedAt) }}</span></p>
              </div>
            </div>
          </div>
          <div class="lg:col-span-6 col-span-12 text-right">
            <ng-container *ngIf="action !== 'read'">
              <div class="text-right block w-full mt-5">
                <button mat-button [disabled]="action == 'read'||dataForm.invalid" type="submit" class="cy__btn btn__second">
                  <i class="fa-light fa-check"></i> {{ share }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
