<div class="absolute top-0 left-0 h-full w-full">
    <div class="bg-[#F5F7F9] h-screen overflow-hidden w-full">
        <div class="hide__menu-desktop h-full max-h-full grid 2xl:grid-cols-[minmax(0,300px)_minmax(0,1fr)] lg:grid-cols-[minmax(0,350px)_minmax(0,1fr)] w-full overflow-hidden">
            <app-sidebar></app-sidebar>

            <div class="h-full relative block w-full overflow-hidden">
                <div class="w-full h-full overflow-hidden relative z-[2]">
                    <div class="h-full w-full max-w-full relative grid grid-rows-[minmax(0,max-content)_minmax(0,1fr)] overflow-hidden">
                        <app-header></app-header>

                        <div class="h-full w-full overflow-hidden">
                            <div class="content_scroll-site">
                                <div class="lg:px-5 py-5 px-3 w-full grid grid-cols-1">
                                    <router-outlet class="contents"></router-outlet>
                                </div>

                                <app-footer></app-footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
