<div mat-dialog-title class="!m-0 w-full block before:!hidden">
  <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300 uppercase">
    {{ actionText }} {{ title }}
  </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
  <div class="block w-full p-6">
    <form class="account-settings-form" (ngSubmit)="submitForm()" [formGroup]="dataForm">
      <div class="w-full mt-2 grid grid-cols-1 gap-4">
        <div class="w-full">
          <label for="name" class="block w-full text-slate-800 text-sm font-semibold mb-2"><span class="required">Nombre</span>:</label>
          <input type="text" name="name" maxlength="255" formControlName="name" id="name" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
        </div>

        <div *ngIf="action != 'create' && createdAt" class="flex flex-wrap justify-between gap-2 mt-5 w-full">
          <div class="sm:col-span-6 col-span-12">
            <p class="text-sm">creación: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(createdAt) }}</span></p>
          </div>
          <div class="sm:col-span-6 col-span-12" *ngIf="updatedAt">
            <p class="text-sm">edición: <span class="text-sky-500 font-semibold">{{ xServices.formatDateTime(updatedAt) }}</span></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
  <div class="w-full flex flex-wrap justify-between actions_modal">
    <div class="block"></div>
    <div class="flex flex-wrap justify-between gap-4">
      <ng-container *ngIf="action != 'read'; else elseActionButton">
        <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

        <button mat-button type="button" (click)="submitForm()" [disabled]="action == 'read'||dataForm.invalid" class="cy__btn btn__second">
          <i class="fa-light fa-check"></i> {{ actionText }} {{ title }}
        </button>
      </ng-container>
      <ng-template #elseActionButton>
        <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cerrar</button>
      </ng-template>
    </div>
  </div>
</div>
