import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsProvider } from 'src/app/services/settings';
import { UserModel } from 'src/app/services/user-model';

import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { MatButtonModule } from '@angular/material/button';
import { DynamicformFieldComponent } from 'src/app/components/dynamic-form-field/dynamic-form-field.component';
import { DynamicFormFieldModel } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'model-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MatSelectModule, MatButtonModule, FilterPipe, SearchDirective, DynamicformFieldComponent],
  // providers: [UppercaseDirective, NoSpaceDirective]
})
export class ModelFormPage implements OnInit {

  title = 'Model';
  share = '';
  action: DynamicFormFieldModel['action'] = 'create';

  titleSingular: string| null = null;

  pageTitle: string = '';

  dataForm!: FormGroup;
  formFields!: DynamicFormFieldModel[];

  createdAt     = null;
  updatedAt     = null;
  id            = null;
  isSuperAdmin  = false;

  model = null;

  submitted:boolean = false;

  map: any;
  initLocationItem:boolean = false;

  constructor(
    private router: Router,
    private metaTitle: Title,
    private userModel: UserModel,
    private route: ActivatedRoute,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    public xServices: XtrasService,
    private authService: AuthService,
    private fb: FormBuilder,
    private alert: AlertService
  ) {
    this.route.params.subscribe((data: any) => {
      this.dataForm = this.fb.group({});
      
      this.model = data['model'];
      this.action = data['action'];
      if(data['action'] == 'edit'&&data['id']||data['action'] == 'read'&&data['id']){
        this.id = data['id'] || null;
      }

      this.loadData();
    });
  }

  setTitle(title: string) {
    this.title = this.share + ' ' + title;

    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
    this.xServices.sharingObservableData = { title: this.title };
  }

  loadData() {

    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`model-list/${this.model}/${this.action}/${this.id}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {

        if(result['node']) {
          this.titleSingular   = result['node']['singular']  || null;
        }

        if(result['fields'] && result['fields'].length > 0) {
          var listFields: DynamicFormFieldModel[] = [];
          result['fields'].forEach((element:any,index:number) => {
            listFields.push({
              type: element['type'],
              name: element['name'],
              label: element['label'],
              placeholder: element['placeholder'] || null,
              show: (element['displayItem'] && element['displayItem'] == 'show') ? true : false,
              validators: element['required'] ? [Validators.required] : [],
              col: element['col'],
              select_options: element['options'] ?? [],
            });
          });
          this.formFields = listFields;
          console.log(this.formFields);

          this.formFields.forEach(formItem => {
            const formControl = this.fb.control(formItem.value, formItem.validators);
            this.dataForm.addControl(formItem.name, formControl);

            // if(formItem.name == 'latitude' || formItem.name == 'longitude') {
            //   setTimeout(() => this.init(), 1);
            // }
          });
          if(this.action == 'edit') {
            this.share = 'Editar';
          } else if(this.action == 'create') {
            this.share = 'Crear';
          } else if(this.action == 'read') {
            this.dataForm.disable();
            this.share = 'Ver';
          }

          this.pageTitle = result['name'] || this.model;
          this.setTitle(this.titleSingular || this.pageTitle);

          if(this.action != 'create' && result['item']) {
            this.createdAt = result['item']['createdAt'] || null;
            this.updatedAt = result['item']['updatedAt'] || null;
            Object.entries(result['item']).forEach(([key, value]:any) => {
              let field = this.formFields.find(field => field.name == key);
              if(field) {
                if(field.type == 'select') {
                  var selected = typeof(value) == 'number' ? parseInt(String(value)) : String(value);
                  this.dataForm.get(key)?.patchValue(selected);
                } else {
                  this.dataForm.get(key)?.patchValue(value);
                }
              }
            });
            // if(!this.initLocationItem) {
            //   setTimeout(() => this.initScripts(this.dataForm.get('latitude')?.value, this.dataForm.get('longitude')?.value), 1);
            // }
          }
        }


        this.load.dismiss();
      } else {
        this.load.dismiss();
        // this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
        if(result['redirect']) {
          this.router.navigateByUrl('/account');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      // this.errorPage();
    });
  }

  // init() {
  //   var latlng = new google.maps.LatLng(-16.5030583, -68.1266917);

  //   var myOptions = {
  //     zoom: 12,
  //     center: latlng,
  //     mapTypeId: google.maps.MapTypeId.ROADMAP,
  //     streetViewControl: false,
  //     mapTypeControl: false,
  //   };

  //   var map = new google.maps.Map(document.getElementById("map")  as HTMLElement, myOptions);

  //   this.map = map;

  //   let $this = this;

  //   if(this.action !== 'read') {
  //     google.maps.event.addListener(map, 'click', function(event: any) {
  //       $this.placeMarker(event.latLng, map);
  //       $('#latitude').val(event.latLng.lat());
  //       $('#longitude').val(event.latLng.lng());
  //       $this.dataForm.get('latitude')?.patchValue(event.latLng.lat());
  //       $this.dataForm.get('longitude')?.patchValue(event.latLng.lng());
  //       console.log($this.dataForm.value);
  //     });

  //     this.searchMap();
  //   }
  // }

  // initScripts(latitude?: any, longitude?: any) {
  //   console.log(latitude,longitude);
  //   latitude = latitude || -16.5030583;
  //   longitude = longitude || -68.1266917;

  //   var latlng = new google.maps.LatLng(latitude, longitude);

  //   this.placeMarker(latlng, this.map);
  //   this.dataForm.get('latitude')?.patchValue(latitude);
  //   this.dataForm.get('longitude')?.patchValue(longitude);
  //   this.initLocationItem = true;
  //   console.log('enter here');
  // }

  // placeMarker(location: any, map: any) {
  //   let marker = new google.maps.Marker({
  //     position: location,
  //     map: map,
  //     animation: google.maps.Animation.DROP,
  //   });
  //   if (oldMarker != undefined){
  //     oldMarker.setMap(null);
  //   }
  //   oldMarker = marker;
  //   map.setCenter(location);
  // }

  // searchMap() {
  //   let $this = this;
  //   const input = document.getElementById("location") as HTMLInputElement;
  //   const searchBox = new google.maps.places.SearchBox(input);

  //   $this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  //   $this.map.addListener("bounds_changed", () => {
  //     searchBox.setBounds($this.map.getBounds() as google.maps.LatLngBounds);
  //   });

  //   searchBox.addListener("places_changed", () => {
  //     const places: any = searchBox.getPlaces();

  //     if (places.length == 0) {
  //       return;
  //     }

  //     const bounds = new google.maps.LatLngBounds();

  //     places.forEach((place: any) => {
  //       if (!place.geometry || !place.geometry.location) {
  //         console.log("Returned place contains no geometry");
  //         return;
  //       }


  //       $this.placeMarker(place.geometry.location, $this.map);

  //       if (place.geometry.viewport) {
  //         bounds.union(place.geometry.viewport);
  //       } else {
  //         bounds.extend(place.geometry.location);
  //       }
  //     });
  //     $this.map.fitBounds(bounds);
  //   });
  // }

  ngOnInit() {
  }

  submit() {
    if(this.action !== 'read') {
      let form = this.dataForm.value;
      let body = {};
      if(this.id) {
        body = { ...form, id: this.id };
      } else {
        body = { ...form };
      }
      console.log(body);
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]:any) => {
        if(value && Array.isArray(value)) {
          value.forEach((idItem:any) => {
            formData.append(`${key}[]`, JSON.stringify(idItem));
          });
        } else {
          formData.append(key, value);
        }
      });
      this.submitted = true;
      this.initLocationItem = false;
      if (this.dataForm.valid) {
        this.load.loadingShow();
        this.http.postRequest(SettingsProvider.getUrl(`node/${this.model}/${this.action}`), formData).subscribe(result => {
          if(result['status']) {
            if(result['message']){
              this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), result['message'], 4000);
            } else {
              this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), 'El item fue '+ (this.action== 'edit' ? 'editado' : 'creado')+' exitosamente', 4000);
            }
            this.router.navigateByUrl(`/model/${this.model}/edit/${this.id || result['item']['id']}`);
            // this.router.navigateByUrl(`/list/${this.model}`);
          } else {
            if(result['message']) {
              if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo procesar la información, intente nuevamente.');
              }
            } else {
              if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
              } else {
                this.toast.error('¡Error!', 'No se pudo procesar la información, intente nuevamente.', 4000);
              }
            }
          }
          this.load.dismiss();
        }, error => {
          console.log(error);
          this.load.dismiss();
          this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        });
      }
    }
  }

  back() {
    this.router.navigateByUrl(`/model/${this.model}`);
  }

  changeValue(item: DynamicFormFieldModel, value: any) {
    console.log('ha cambiado');
    console.log(item.parent, value);
    this.reloadData(item, value);
  }

  reloadData(item: DynamicFormFieldModel, value: any) {
    let field: any = this.formFields.find(field => field.name == item.parent?.name);
    if(item.parent?.action == 'reloadData') {
      if(value == 'item1') {
        field.options = [
          {id: '1', name: "Item 12"},
          {id: '2', name: "Item 22"},
          {id: '3', name: "Item 33"},
          {id: '4', name: "Juan44"},
        ];
      } else {
        field.options = [
          {id: '12', name: "Item 123"},
          {id: '22', name: "Item 223"},
          {id: '32', name: "Item 333"},
          {id: '42', name: "Juan443"},
        ];
      }
      console.log(this.formFields);
    } else {
      if(item.parent?.action == 'hideField') {
        field.show = false;
        item.parent.action = 'showField';
        console.log(field.show);
      } else if(item.parent?.action == 'showField') {
        field.show = true;
        item.parent.action = 'hideField';
        console.log(field.show);
      }
    }
  }
}
