<div mat-dialog-title class="!m-0 w-full block before:!hidden">
  <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300 uppercase">{{ title }}</h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
  <div class="block w-full pb-6 px-6">
    <div class="w-full">
      <div class="animation__item-sending">
        <ng-lottie [options]="optionsAnimation" class="animation__item"></ng-lottie>
      </div>
      <p class="px-2 text-center font-sans text-[14px] text-gray-400" >{{ content }}</p>
    </div>
  </div>
</div>

<div mat-dialog-actions class="!m-0 w-full block">
  <div class="w-full flex flex-wrap justify-between actions_modal">
    <div class="block"></div>
    <div class="flex flex-wrap justify-around gap-4">
      <button mat-button type="button" (click)="dismiss('reloadData')" class="cy__btn btn__second">
        Cerrar
      </button>
    </div>
  </div>
</div>