<div class="w-full block relative z-[2]">
	<div class="bg-white lg:p-5 py-4 px-5 flex items-center justify-between relative z-10">
		<div class="content__logo flex items-center gap-3 sm:pl-5 pl-4">
      		<div class="lg:block hidden">
				<a (click)="toggleSidebarDesk()" class="btn__sidebar_desktop cursor-pointer md:text-2xl text-xl block w-[25px] h-[25px]">
					<i class="fa-light fa-bars-sort"></i>
				</a>
			</div>
			<div class="lg:hidden block">
				<a class="btn__sidebar cursor-pointer md:text-2xl text-xl block w-[25px] h-[25px]">
					<i class="fa-light fa-bars-sort"></i>
				</a>
			</div>
			<div class="md:block max-w-full hidden">
				<h5 class="m-auto text-xl font__title font-semibold truncate"><span class="font-bold uppercase">{{ environment.title }}</span> - <span>{{ (data$ | async)?.title }}</span></h5>
			</div>
			<!-- <a (click)="back()" class="flex gap-1 items-center justify-center cursor-pointer">
				<i class="fa-regular fa-circle-left"></i> <span class="md:block hidden leading-none">Volver</span>
			</a> -->
			<!-- <a routerLink="{{ back_location ? back_location : '/' }}"><i class="fa-regular fa-circle-left"></i> Volver</a> -->
		</div>

		<div class="w-auto max-w-full">
			<ul class="list-none flex flex-wrap items-center gap-2">
				<ng-container *ngIf="user$ | async as _data; elseNotificationsData">
					<li class="relative dropdown_contnt">
						<a routerLink="/notifications" class="lg:hidden flex text-[color:var(--primary-color)] text-lg bg-gray-100 py-2 px-2 rounded-full transition-all duration-300 ease-in-out hover:bg-[color:var(--primary-color)] hover:text-white">
							<span class="w-[22px] relative before:pb-[100%] before:block">
								<i class="fa-light fa-bell absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"></i>
							</span>
							<span *ngIf="_data.unread_notifications_count > 0" class="bg-[color:var(--primary-color)] text-white rounded-[8rem] min-w-[18px] h-[18px] text-[11px] flex items-center justify-center text-center px-[3px] absolute -top-[5px] -right-[5px]">
								{{ _data.unread_notifications_count }}
							</span>
						</a>
						<button [matMenuTriggerFor]="notificationsDropdown" (menuClosed)="closeMenu()" class="lg:flex hidden text-[color:var(--primary-color)] text-lg bg-gray-100 py-2 px-2 rounded-full transition-all duration-300 ease-in-out hover:bg-[color:var(--primary-color)] hover:text-white">
							<span class="w-[22px] relative before:pb-[100%] before:block">
								<i class="fa-light fa-bell absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"></i>
							</span>
							<span *ngIf="_data.unread_notifications_count > 0" class="bg-[color:var(--primary-color)] text-white rounded-[8rem] min-w-[18px] h-[18px] text-[11px] flex items-center justify-center text-center px-[3px] absolute -top-[5px] -right-[5px]">
								{{ _data.unread_notifications_count }}
							</span>
						</button>
						<mat-menu #notificationsDropdown="matMenu" class="w-[400px] !max-w-full">
							<div class="py-2 px-4 whitespace-nowrap">
								<div class="w-full block mb-4">
									<div class="w-full border-b-[1px] border-b-gray-300">
										<div class="grid grid-cols-2 items-center gap-2 pb-2 px-4 w-full">
											<div class="col-span-1 w-full flex flex-wrap items-center">
												<div (click)="$event.stopPropagation();notificationsData()" class="p-2 cursor-pointer text-sky-500">
													<i class="fa-sharp fa-solid fa-rotate-right"></i>
												</div>
												<h5 class="font__title text-xl text-left ml-2">Notificaciones</h5>
											</div>
											<div class="col-span-1 w-full block text-right">
												<div routerLink="/notifications" class="p-2 cursor-pointer text-sky-500">
													<i class="fa-duotone fa-right"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<ul class="w-full grid grid-cols-1 gap-2 overflow-y-scroll max-h-[350px] scrollbar_css min_bar">
									<ng-container *ngIf="xServices.lengthItems(_data.first_notifications); else elseNotifications">
										<li class="w-full" *ngFor="let item of _data.first_notifications">
											<a (click)="toUrlProcess(item.id, item['url'])" [title]="item['name']" class="py-2 px-4 w-full grid grid-cols-[minmax(0,50px)_minmax(0,1fr)] gap-4 items-center transition-all rounded bg-transparent hover:bg-gray-50 ">
												<div class="w-full block">
													<div class="w-full block relative before:block before:pb-[100%]">
														<span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
															<i *ngIf="item.is_read" title="Leído" class="fa-solid fa-bell text-[2rem] text-cyan-400"></i>
															<i *ngIf="!item.is_read" title="No leído" class="fa-solid fa-bell-on text-[2rem] text-emerald-400"></i>
														</span>
													</div>
												</div>
												<div class="w-full block">
													<div class="w-full grid grid-cols-2 gap-2 items-center">
														<div class="col-span-1 w-full block">
															<p class="text-xs text-gray-400 ">{{ item['date'] ? xServices.formatDate(item['date']) : xServices.formatDate(item['created_at']) }}</p>
														</div>
														<div class="col-span-1 w-full block text-right">
															<p class="text-xs text-gray-400 ">{{ item['time'] ? item['time'] : '00:00' }}</p>
														</div>
													</div>
													<div class="w-full block">
														<h5 class="block w-full text-base font-bold text-gray-600 m-0">{{ item['name'] }}</h5>
														<div class="block w-full truncate text-sm" [innerHtml]="item['message']"></div>
													</div>
												</div>
											</a>
										</li>
										<li class="w-full block">
											<button routerLink="/notifications" class="w-full block py-2 px-4 bg-transparent text-gray-600 border border-gray-200 rounded appearance-none leading-none hover:bg-sky-50 hover:text-sky-400 transition-all">Ver Todos</button>
										</li>
									</ng-container>
									<ng-template #elseNotifications>
										<li class="w-full">
											<p class="text-sm font__text">No se encontraron notificaciones</p>
										</li>
									</ng-template>
								</ul>
							</div>
						</mat-menu>
					</li>
				</ng-container>
				<li class="relative">
					<button [matMenuTriggerFor]="userDropdown" class="text-gray-800 text-lg py-2 px-4 inline-flex flex-wrap items-center relative before:w-full before:h-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:top-1/2 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:scale-50 before:bg-gray-100 before:rounded-xl before:z-[-1] before:opacity-0 hover:before:opacity-100 hover:before:scale-100">
						<i class="fa-light fa-user mr-[5px] w-[22px]"></i>
						<span class="sm:flex sm:flex-col hidden max-w-[115px]">
							<ng-container *ngIf="!authService.getAuth(); else elseAuth1">
								<span class="text-sm block text-left leading-[normal] font-semibold text-ellipsis whitespace-nowrap overflow-hidden max-w-full">Inicia sesión</span>
								<span class="text-[13px] block text-left leading-[normal]">Dashboard</span>
							</ng-container>
							<ng-template #elseAuth1>
								<ng-container *ngIf="user$ | async as _user">
									<span [title]="_user.name" class="text-sm block text-left leading-[normal] font-semibold text-ellipsis whitespace-nowrap overflow-hidden max-w-full">{{ _user.name }}</span>
									<span class="text-[13px] block text-left leading-[normal]">Dashboard</span>
								</ng-container>
							</ng-template>
						</span>
						<small><i class="fa-light fa-angle-down sm:ml-[8px]"></i></small>
					</button>
					<mat-menu #userDropdown="matMenu">
						<ul class="py-2 px-4 whitespace-nowrap">
							<ng-container *ngIf="!authService.getAuth(); else elseAuth2">
								<li class="w-full">
									<a routerLink="/auth" class="text-gray-800 text-base py-2 px-4 inline-flex items-center relative w-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:left-4 before:w-0 before:bottom-0 before:border-b-2 before:border-b-[color:var(--primary-color)] hover:before:w-[50%]">
										<i class="fa-light fa-arrow-right-to-bracket mr-[5px] text-base w-[20px]"></i>
										<span>Iniciar sesión</span>
									</a>
								</li>
							</ng-container>
							<ng-template #elseAuth2>
								<li class="w-full">
									<a routerLink="/account" class="text-gray-800 text-base py-2 px-4 inline-flex items-center relative w-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:left-4 before:w-0 before:bottom-0 before:border-b-2 before:border-b-[color:var(--primary-color)] hover:before:w-[50%]">
										<i class="fa-light fa-file-user mr-[5px] text-base w-[20px]"></i>
										<span>Mi Perfil</span>
									</a>
								</li>
								<li class="w-full">
									<a routerLink="/account-security" class="text-gray-800 text-base py-2 px-4 inline-flex items-center relative w-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:left-4 before:w-0 before:bottom-0 before:border-b-2 before:border-b-[color:var(--primary-color)] hover:before:w-[50%]">
										<i class="fa-light fa-shield-check mr-[5px] text-base w-[20px]"></i>
										<span>Seguridad</span>
									</a>
								</li>
								<li class="w-full">
									<a (click)="logout()" href="javascript:void(0)" class="text-gray-800 text-base py-2 px-4 inline-flex items-center relative w-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:left-4 before:w-0 before:bottom-0 before:border-b-2 before:border-b-[color:var(--primary-color)] hover:before:w-[50%]">
										<i class="fa-light fa-arrow-right-from-bracket mr-[5px] text-base w-[20px]"></i>
										<span>Cerrar Sesión</span>
									</a>
								</li>
							</ng-template>
						</ul>
					</mat-menu>
				</li>
			</ul>
		</div>
	</div>
</div>
