<div class="w-full tl:h-full pb-3 block tl:overflow-hidden">
  <div class="w-full tl:h-full bg-white rounded-xl drop-shadow p-6 tl:px-8 px-6 tl:overflow-hidden">

    <div class="w-full tl:h-full grid grid-rows-[minmax(0,max-content)_minmax(0,1fr)] tl:overflow-hidden">
      <div class="w-full border-b">
        <div class="content_btns flex flex-wrap gap-4 justify-between items-center w-full pb-4">
          <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
            <a (click)="back()" class="flex gap-1 items-center justify-center cursor-pointer">
              <i class="fa-regular fa-circle-left"></i> <span class="md:block hidden leading-none">Volver</span>
            </a>
            <div class="text-sm text-secondary border rounded-md px-2 py-1 font-medium">Historial de acciones y registro de actividades</div>
          </div>
          <div *ngIf="lead" class="flex items-center gap-2" [formGroup]="dataForm">
            <label for="category_id" class="text-secondary block text-sm font-medium m-0">
              Estado:
            </label>
            <mat-select id="category_id" name="category_id" formControlName="category_id" class="custom__mat-select !w-[200px]" (valueChange)="changeStatus($event)" placeholder="Estado...">
              <div class="filter_option">
                <input placeholder="Buscar" appSearch (appSearch)="filterTypes = $event" class="!outline-none !ring-0 !border-0">
              </div>
              <ng-container *ngIf="!loadingTypes; else elseLoadingTypes">
                <ng-container *ngIf="!noDataTypes; else elseTypes">
                  <ng-container *ngFor="let item of types | filter: filterTypes : 'name'">
                    <!-- ID 4 - Convertido -->
                    <mat-option [value]="item['id']" [disabled]="item.id == 4">{{ item['name'] }}</mat-option>
                  </ng-container>
                </ng-container>
                <ng-template #elseTypes>
                  <mat-option [value]="null">No se encontraron contactos</mat-option>
                </ng-template>
              </ng-container>
              <ng-template #elseLoadingTypes>
                <mat-option [value]="null">Cargando contactos...</mat-option>
              </ng-template>
            </mat-select>
          </div>
        </div>
      </div>
  
      <div class="block w-full tl:h-full tl:overflow-hidden">
        <div class="w-full tl:h-full pt-5">
  
          <div class="w-full tl:h-full grid grid-cols-1 tl:grid-cols-[minmax(0,380px)_minmax(0,1px)_minmax(0,1fr)] tl:grid-rows-1 tl:overflow-hidden">
            <div class="w-full tl:h-full tl:overflow-hidden">
              <div class="w-full tl:max-h-full tl:overflow-auto p-4">
  
                <div *ngIf="item" class="border-b border-gray-200 pb-4 mb-4" >
                  
                  <h5 class="text-[#101828] font-semibold">Contacto</h5>
                  <br>
                  <div class="user-custom user-custom--big">
                    <div class="img-custom">
                      <img [src]="item.image ?? 'assets/img/user.png'" alt="users">
                    </div>
                    <div class="info-custom">
                      <h5>{{ item.name }}</h5>
                      <p>{{ item.email ?? '-' }}</p>
                      <p>{{ item.cellphone ?? '-' }}</p>
                    </div>
                  </div>
  
                  <div class="flex gap-1 mt-5 flex-wrap items-center justify-center">
                    <!-- <button (click)="nota()" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle note-icon"><i class="fa fa-pencil-square" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">Nota</p>
                    </button> -->
  
                    <a *ngIf="item.email" href="mailto:{{ item.email }}" target="_blank" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle email-icon"><i class="fa fa fa-envelope" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">Correo</p>
                    </a>
                    <a *ngIf="item.cellphone" href="tel:591{{ item.cellphone }}" target="_blank" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle call-icon"><i class="fa fa-phone" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">Llamada</p>
                    </a>
                    <a *ngIf="item.cellphone" href="https://api.whatsapp.com/send?phone=591{{ item.cellphone }}" target="_blank" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle what-icon"><i class="fa-brands fa-whatsapp" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">WhatsApp</p>
                    </a>
                    <button (click)="formCreate()" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle task-icon"><i class="fa fa-file-text" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">Tarea</p>
                    </button>
                    <button (click)="registerActivity()" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle"><i class="fa fa-book" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">Nota</p>
                    </button>
                    <!-- <button (click)="reunion()" class="flex flex-col items-center hover-btn">
                      <div class="btn-circle reu-icon"><i class="fa fa-calendar-check" aria-hidden="true"></i></div>
                      <p class="text-[#53769c] text-[14px]">Reunion</p>
                    </button> -->
                  </div>
  
                </div>
  
                <mat-accordion *ngIf="item" class="mat__type-2" expanded>
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h5 class="text-[#101828] font-semibold" >Acerca de este contacto</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="w-full max-h-full overflow-auto py-4">
                      <div class="flex flex-col gap-4">
                        <ng-container *ngIf="item">
                          <div *ngIf="item.name" class="w-full">
                            <label for="fullname" class="text-secondary block w-full text-sm font-medium mb-2"><span>Nombre completo</span>:</label>
                            <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                              {{ item.name }}
                            </div>
                          </div>
                          <div *ngIf="item.ci_number" class="w-full">
                            <label for="ci_number" class="text-secondary block w-full text-sm font-medium mb-2">C.I.:</label>
                            <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                              {{ item.ci_number }}
                            </div>
                          </div>
                          <div *ngIf="item.email" class="w-full">
                            <label for="email" class="text-secondary block w-full text-sm font-medium mb-2"><span>E-mail</span>:</label>
                            <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                              {{ item.email }}
                            </div>
                          </div>
                          <div *ngIf="item.cellphone" class="w-full">
                            <label for="cellphone" class="text-secondary block w-full text-sm font-medium mb-2"><span>Celular</span>:</label>
                            <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                              {{ item.cellphone }}
                            </div>
                          </div>
                        </ng-container>
                        <div *ngIf="item.channel&&item.channel?.name" class="w-full">
                          <label for="source" class="text-secondary block w-full text-sm font-medium mb-2"><span>Fuente</span>:</label>
                          <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                            {{ item.channel.name }}
                          </div>
                        </div>
                        <div *ngIf="lead?.category" class="w-full">
                          <label for="status" class="text-secondary block w-full text-sm font-medium mb-2"><span>Estado</span>:</label>
                          <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                            {{ lead?.category?.name ?? '-' }}
                          </div>
                        </div>
                        <div *ngIf="lead&&lead.product_name" class="w-full">
                          <label for="status" class="text-secondary block w-full text-sm font-medium mb-2"><span>Producto</span>:</label>
                          <div class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#f8fafc] focus:border-[#f8fafc] focus:ring-0 border min-h-[42px]">
                            {{ lead.product_name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>

            <div class="w-full h-[1px] tl:h-[90%] bg-[#D0D5DD] self-center"></div>

            <div class="w-full tl:h-full tl:overflow-hidden">
              <div class="w-full tl:h-full tl:overflow-hidden tl:grid tl:grid-rows-[minmax(0,max-content)_minmax(0,1fr)]">
                <div class="p-4 flex flex-wrap justify-between items-center gap-2">
                  <h5 class="text-[#101828] font-semibold m-0">Historial de Actividades</h5>
                </div>
                <div class="w-full tl:h-full tl:overflow-hidden">
                  <div class="block w-full tl:max-h-full tl:overflow-auto p-4">
                    <ng-container *ngFor="let item of history">
                      <div class="rounded-md border border-[#F1F1F1] p-3 mb-4" [ngClass]="item.type_history == 'reminder' ? 'bg-[#FEFFE9]' : 'bg-[#F2F4F7]'">
                        <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">{{ xServices.formatDate(item.created_at) }}</p>
                        <div class="block m-0 text-sm leading-[normal] text-[#344054] " [innerHTML]="item.content"></div>
                      </div>

                      <!-- <div *ngFor="let action of item.actions ?? []" class="flex flex-wrap tl:justify-end gap-2">
                        <button mat-button class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Ver mail</span>
                        </button>
                      </div> -->
                    </ng-container>

                    <!-- #FEFFE9 -->
                    <!-- <div class="bg-[#F2F4F7] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">6 noviembre 2023 | 15:30</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Vendedor de prueba</span> ha cambiado el estado del lead de <span class="text-[color:var(--second-color)] font-semibold">pendiente a convertido</span>
                      </p>
                    </div>
      
                    <div class="bg-[#FEFFE9] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">2 noviembre 2023 | 08:23</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        Se ha detectado un registro de nuevo socio con datos coicidentes al Lead
                      </p>
                      <br>
                      <div class="flex flex-wrap tl:justify-end gap-2">
                        <button mat-button class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Ver registro</span>
                        </button>
                      </div>
                    </div>
      
                    <div class="bg-[#F2F4F7] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">29 octubre 2023 | 08:30</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Vendedor de prueba</span> ha registrado la actividad:: <span class="text-[color:var(--second-color)] font-semibold">Llamada para crear socio celeste</span>
                      </p>
                    </div>
      
                    <div class="bg-[#F2F4F7] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">28 octubre 2023 | 15:30</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Vendedor de prueba</span> ha cambiado el estado del lead de <span class="text-[color:var(--second-color)] font-semibold">nuevo a pendiente</span>
                      </p>
                    </div>
      
                    <div class="bg-[#FEFFE9] rounded-md border border-[#F1F1F1] p-3 mb-4">
                      <p class="block m-0 text-sm leading-[normal] text-[#344054] mb-1">27 octubre 2023 | 08:23</p>
                      <p class="block m-0 text-sm leading-[normal] text-[#344054]">
                        <span class="text-[color:var(--second-color)] font-semibold">Vendedor de prueba</span> ha enviado un <span class="text-[color:var(--second-color)] font-semibold">Email</span> a <span class="text-[color:var(--second-color)] font-semibold">Eduardo Mejia Silva</span>
                        <br>
                        <span class="text-[color:var(--second-color)] font-semibold">Mensaje:</span> Buenos días Sr Eduardo, tenemos preparado el registro de su suscripción a Socio Celeste, no encantaría recibir su confirmación para continar, Saludos.
                      </p>
                      <br>
                      <div class="flex flex-wrap tl:justify-end gap-2">
                        <button mat-button class="cy__btn btn__grad small__btn">
                          <span class="font-semibold">Ver mail</span>
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>