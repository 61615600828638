<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300 uppercase">
        {{ actionText }} LEAD
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <ng-container *ngIf="this.user$ | async as _user">
                <div *ngIf="_user.role_id == 1 || _user.role_id == 3" class="w-full">
                    <label for="user_id" class="text-secondary block text-sm font-medium"><span class="">Usuario</span>:</label>
                    <mat-select id="user_id" name="user_id" formControlName="user_id" placeholder="Seleccione una opción" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterUsers = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingUsers; else elseLoadingUsers">
                            <ng-container *ngIf="!noDataUsers; else elseUsers">
                                <mat-option [value]="null" >Ningúno</mat-option>
                                <mat-option *ngFor="let item of users | filter: filterUsers : 'fullname'" [value]="item['id']" >{{ item['fullname'] }}</mat-option>
                            </ng-container>
                            <ng-template #elseUsers>
                                <mat-option [value]="null">No se encontraron usuarios</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingUsers>
                            <mat-option [value]="null">Cargando usuarios...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
            </ng-container>
            <div class="w-full">
                <div class="flex flex-wrap items-center justify-between gap-2 mb-2">
                    <label for="customer" class="text-secondary block text-sm font-medium"><span class="{{ action === 'create' ? 'required' : '' }}">Contacto</span>:</label>
                    <button type="button" (click)="createClient()" class="text-[color:var(--primary-color)] underline text-sm">Crear contacto &nbsp;<i class="fa-light fa-right"></i></button>
                </div>
                <div class="grid grid-cols-[minmax(0,1fr)_minmax(0,100px)] gap-2 items-center">
                    <input type="text" name="customer" maxlength="600" id="customer" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border" />
                    <button mat-button type="button" (click)="changeCustomer(search)" [disabled]="!search" class="cy__btn btn__second">
                        <i class="fa-light fa-search"></i>
                    </button>
                </div>
                <div class="h-4 w-full"></div>
                <ng-container *ngIf="xServices.lengthItems(customers); else elseDataContacts">
                    <mat-select id="customer_id" name="customer_id" formControlName="customer_id" placeholder="Seleccione un contacto" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterCustomers = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <mat-option *ngFor="let item of customers | filter: filterCustomers : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                    </mat-select>
                </ng-container>
                <ng-template #elseDataContacts>
                    <ng-container *ngIf="customerName; else elseCustomerId">
                        <input type="text" readonly disabled [value]="customerName" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border" />
                    </ng-container>
                    <ng-template #elseCustomerId>
                        <p>No se encontraron contactos, realice una búsqueda <i class="fa-light fa-up-long"></i></p>
                    </ng-template>
                </ng-template>
            </div>
            <div class="w-full">
                <label for="product_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="{{ action === 'create' ? 'required' : '' }}">Producto</span>:</label>
                <mat-select id="product_id" name="product_id" formControlName="product_id" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterProducts = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingProducts; else elseLoadingProducts">
                        <ng-container *ngIf="!noDataProducts; else elseProducts">
                            <mat-option *ngFor="let item of products | filter: filterProducts : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                        </ng-container>
                        <ng-template #elseProducts>
                            <mat-option [value]="null">No se encontraron productos</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingProducts>
                        <mat-option [value]="null">Cargando productos...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <ng-container *ngIf="dataForm.get('product_id')?.value">
                <div class="w-full">
                    <label for="product_item_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="" [ngClass]="noDataSubProducts ? '' : 'required'">Sub producto</span>:</label>
                    <mat-select id="product_item_id" name="product_item_id" formControlName="product_item_id" placeholder="Seleccione una opción" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterSubProducts = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingSubProducts; else elseLoadingSubProducts">
                            <ng-container *ngIf="!noDataSubProducts; else elseSubProducts">
                                <mat-option *ngFor="let item of subProducts | filter: filterSubProducts : 'name'" [value]="item['id']" >{{ item['name'] }}{{ item['price'] ? (' - Bs. '+item['price']) : ''  }}</mat-option>
                            </ng-container>
                            <ng-template #elseSubProducts>
                                <mat-option [value]="null">No se encontraron subproductos</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingSubProducts>
                            <mat-option [value]="null">Cargando subproductos...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
            </ng-container>
            <div class="w-full">
                <label for="detail" class="text-secondary block w-full text-sm font-medium mb-2"><span class="{{ action === 'create' ? 'required' : '' }}">Detalle del Lead</span>:</label>
                <div class="w-full">
                    <textarea type="phone" name="detail" maxlength="600" formControlName="detail" id="detail" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border"></textarea>
                </div>
            </div>
            <div class="w-full">
                <label for="channel_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="{{ action === 'create' ? 'required' : '' }}">Fuente</span>:</label>
                <!-- <input type="text" name="channel_id" formControlName="channel_id" id="channel_id" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border"> -->
                <mat-select id="channel_id" name="channel_id" formControlName="channel_id" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterChannels = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingChannels; else elseLoadingChannels">
                        <ng-container *ngIf="!noDataChannels; else elseChannels">
                            <mat-option *ngFor="let item of channels | filter: filterChannels : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                        </ng-container>
                        <ng-template #elseChannels>
                            <mat-option [value]="null">No se encontraron fuentes</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingChannels>
                        <mat-option [value]="null">Cargando fuentes...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="w-full">
                <label for="category_id" class="text-secondary block w-full text-sm font-medium mb-2"><span class="{{ action === 'create' ? 'required' : '' }}">Estado</span>:</label>
                <div class="radio__btns">
                    <ng-container *ngIf="!loadingCategories; else elseLoadingCategories">
                        <ng-container *ngIf="!noDataCategories; else elseCategories">
                            <mat-radio-group name="category_id" formControlName="category_id">
                                <mat-radio-button *ngFor="let item of categories" [value]="item['id']">{{ item['name'] }}</mat-radio-button>
                            </mat-radio-group>
                        </ng-container>
                        <ng-template #elseCategories>
                            <mat-option [value]="null">No se encontraron categorías de estados</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingCategories>
                        <mat-option [value]="null">Cargando categorías de estados...</mat-option>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <ng-container *ngIf="action != 'read'; else elseActionButton">
                <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>
        
                <button mat-button type="button" (click)="submitForm()" [disabled]="action == 'read'||dataForm.invalid" class="cy__btn btn__second">
                    <i class="fa-light fa-check"></i> {{ actionText }} Template
                </button>
            </ng-container>
            <ng-template #elseActionButton>
                <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cerrar</button>
            </ng-template>
        </div>
    </div>
</div>
