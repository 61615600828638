<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        PROGRAMAR TAREA
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full">
                <label for="name" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Contacto</span>:</label>
                <div class="block m-0 w-full bg-[#e5faff] rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border min-h-[42px]">{{ contactName ?? '-' }}</div>
            </div>
            <div *ngIf="!data?.lead_id" class="w-full">
                <label for="lead_id" class="text-secondary block w-full text-sm font-medium mb-2">Lead:</label>
                <mat-select id="lead_id" name="lead_id" formControlName="lead_id" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterLeads = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingLeads; else elseLoadingLeads">
                        <ng-container *ngIf="xServices.lengthItems(leads); else elseLeads">
                            <mat-option *ngFor="let item of leads | filter: filterLeads : 'detail'" [value]="item['id']" >ID:{{ item['id'] }} - {{ item['detail'] }} - {{ item.channel?.name ?? '-' }}</mat-option>
                        </ng-container>
                        <ng-template #elseLeads>
                            <mat-option [value]="null">No se encontraron leads</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingLeads>
                        <mat-option [value]="null">Cargando leads...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="w-full">
                <label for="name" class="text-secondary block w-full text-sm font-medium mb-2">Nombre:</label>
                <input type="text" name="name" formControlName="name" id="name" maxlength="600" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border" />
            </div>
            <div class="w-full">
                <label for="type" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Tipo</span>:</label>
                <mat-select id="type" name="type" formControlName="type" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterTypes = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <mat-option *ngFor="let item of types | filter: filterTypes : 'name'" [value]="item['id']" >{{ item['name'] }}</mat-option>
                </mat-select>
            </div>
            <div>
                <label for="date" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Fecha</span>:</label>
                <div class="grid grid-cols-1 md:grid-cols-[minmax(0,1fr)_minmax(0,max-content)] gap-4">
                    <div >
                        <div class="w-full relative input__date type_full border border-[#F1F1F1]">
                            <input name="date" [(ngModel)]="date" (ngModelChange)="dateChange($event)" [ngModelOptions]="{standalone: true}" [matDatepicker]="picker" [placeholder]="'DD/MM/YYYY'" readonly class="input__date-item">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        </div>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                    <div>
                        <input type="time" name="time" formControlName="time" id="time" maxlength="600" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border">
                    </div>
                </div>
            </div>
            <div class="w-full">
                <label for="detail" class="text-secondary block w-full text-sm font-medium mb-2">Notas:</label>
                <textarea type="text" name="detail" formControlName="detail" id="detail" maxlength="600" class="block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base !shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0 border"></textarea>
            </div>
            <div>
                <hr>
            </div>
            <div>
                <label for="reminder_type" class="text-secondary block w-full text-sm font-medium mb-2"><span class="">Recordatorio</span>:</label>
                <mat-select id="type" name="reminder_type" formControlName="reminder_type" placeholder="Seleccione una opción" class="custom__mat-select">

                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterReminderTypes = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingReminderTypes; else elseLoadingReminderTypes">
                        <ng-container *ngIf="xServices.lengthItems(reminder_types); else elseReminderTypes">
                            <mat-option *ngFor="let item of reminder_types | filter: filterReminderTypes : 'name'" [value]="item['value']" >{{ item['name'] }}</mat-option>
                        </ng-container>
                        <ng-template #elseReminderTypes>
                            <mat-option [value]="null">No se encontraron opciones</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingReminderTypes>
                        <mat-option [value]="null">Cargando opciones...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <button mat-button type="button" (click)="submitForm()" [disabled]="dataForm.invalid" class="cy__btn btn__second">
                <i class="fa-light fa-check"></i> Registrar tarea
            </button>
        </div>
    </div>
</div>
