import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogData } from 'src/app/interfaces/interfaces';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-modal-addusers',
  templateUrl: './modal-addusers.component.html',
  styleUrls: ['./modal-addusers.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatChipsModule, MatFormFieldModule, MatIconModule, MatAutocompleteModule, MatButtonModule]
})
export class ModalAddUsersComponent implements OnInit {


  users = null;
  user = null;
  type = null;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  nameCtrl = new FormControl();
  filteredNames?: Observable<any[]>;
  names: any[] = [];
  items: string[] = [];
  organizations: any[] = [];
  isAdmin:boolean = false;
  user$: Observable<UserType>;
  codOrganization = new FormControl(null);
  organization_id = null;

  @ViewChild('nameInput') nameInput?: ElementRef<HTMLInputElement>;

  constructor(
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ModalAddUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.isAdmin = this.data.isAdmin;
    this.user$ = this.authService.user$;
    this.codOrganization.valueChanges.subscribe(data => {
      this.organization_id = data || null;
    });
    this.http.getRequest(SettingsProvider.getUrl('get-organizations/0?active=1')).subscribe(result => {
      if(result['status']) {
        this.organizations = result['items'];
      } else {
        if(result['message']){ 
          this.toast.error('Error al obtener los datos', result['message']);
        } else {
          this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
        }
      }
    }, error => {
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
    });
    if(this.data.type == 'input') {
      this.load.loadingShow();
      this.http.getRequest(SettingsProvider.getUrl(`model/user/0`)).subscribe(result => {
        if(result['status']) {
          this.items = result['items'];
          this.load.dismiss();
          setTimeout(()=>{
            this.filteredNames = this.nameCtrl.valueChanges.pipe(
              startWith(null),
              map((email: any | null) => (email ? this._filter(email.name) : this.items.slice())),
            );
          }, 1);
        } else {
          this.load.dismiss();
          this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
        }
      }, error => {
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    event.chipInput!.clear();
    this.nameCtrl.setValue(null);
  }

  remove(email: string, index: number): void {
    this.names.splice(index, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // * "this.names.length < 1" -> evita que se añada más de un elemento
    if (this.names.length < 1) {
      if (!this.names.includes(event.option.value)) {
        this.names.push(event.option.value);
      }
      this.nameInput!.nativeElement.value = '';
      this.nameCtrl.setValue(null);
    }
  }

  private _filter(value: string): string[] {
    console.log(value);
    let filterValue = value.toLowerCase();
    return this.data.users.filter(user => user.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  accept() {
    this.data.dismiss_action = true;
    this.dialogRef.close({dismiss_action: this.data.dismiss_action});
  }

  ngOnInit() {
  }

  submitForm() {
    this.load.loadingShow();
    if(this.data.type == 'input') {
      this.http.postRequest(SettingsProvider.getUrl('add-user-to-organization'), { codOrganization: this.organization_id, codUser: this.names[0].pkyUser, checked: true }).subscribe(result => {
        if(result['status']) {
          if(result['message']){
            if(result['messages']&&result['messages'].length > 0&&result['errors']&&result['errors'].length > 0) {
              this.load.dismiss();
              this.accept();
              this.toast.success(result['message'], result['messages'], 6000);
              this.toast.warning(result['message'], result['errors'], 10000);
            } else if(result['messages']&&result['messages'].length > 0){
              this.load.dismiss();
              this.accept();
              this.toast.success(result['message'], result['messages'], 6000);
            } else if(result['errors']&&result['errors'].length > 0){
              this.load.dismiss();
              this.accept();
              this.toast.warning(result['message'], result['errors'], 10000);
            } else {
              this.load.dismiss();
              this.accept();
              this.toast.success(result['message'], 'Se envió una solicitud al o los usuarios añadidos.', 6000);
            }
          } else {
            this.load.dismiss();
            this.toast.success('Acción ejecutada con éxito', 'Se envió una solicitud al o los usuarios añadidos.', 6000);
          }
        } else {
          this.load.dismiss();
          if(result['message']){ 
            if(result['errors']){ 
              this.toast.error(result['message'], result['errors'], 4000);
            } else {
              this.toast.error('Error al realizar la solicitud', result['message'], 4000);
            }
          } else {
            this.toast.error('Error al realizar la solicitud', 'Hubo un error al conectar con el servidor, intente nuevamente.', 4000);
          }
        }
      }, error => {
        this.load.dismiss();
        this.toast.error('Error al realizar la solicitud', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      });
    } else {
    }
  }

}