<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        Filtros de búsqueda
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full">
                <label class="text-secondary block w-full text-sm font-medium mb-2">Rango de fechas:</label>
                <div class="w-full relative input__date type_full border border-[#F1F1F1]">
                    <mat-date-range-input [rangePicker]="picker" class="input__date-item !bg-white">
                        <input matStartDate readonly [(ngModel)]="start_date" (ngModelChange)="dateChange($event, 'start_date')" [ngModelOptions]="{standalone: true}">
                        <input matEndDate readonly [(ngModel)]="end_date" (ngModelChange)="dateChange($event, 'end_date')" [ngModelOptions]="{standalone: true}">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
            </div>
            <div class="w-full">
                <label for="filter-modal_status" class="text-secondary block w-full text-sm font-medium mb-2">Estado:</label>
                <mat-select id="filter-modal_status" name="status" formControlName="status" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterStatus = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingStatus; else elseLoadingStatus">
                        <ng-container *ngIf="xServices.lengthItems(status); else elseStatus">
                            <mat-option *ngFor="let item of status | filter: filterStatus : 'name'" [value]="item['id']" >{{ item?.name ?? '-' }}</mat-option>
                        </ng-container>
                        <ng-template #elseStatus>
                            <mat-option [value]="null">No se encontraron estados</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingStatus>
                        <mat-option [value]="null">Cargando estados...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <ng-container *ngIf="this.user$ | async as _user">
                <div *ngIf="_user.role_id == 1" class="w-full">
                    <label for="user_id" class="text-secondary block w-full text-sm font-medium mb-2">Vendedor:</label>
                    <mat-select id="user_id" name="user_id" formControlName="user_id" placeholder="Seleccione una opción" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterUsers = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingUsers; else elseLoadingUsers">
                            <ng-container *ngIf="xServices.lengthItems(users); else elseUsers">
                                <mat-option [value]="null" >Ningúno</mat-option>
                                <mat-option *ngFor="let item of users | filter: filterUsers : 'fullname'" [value]="item['id']" >{{ item['fullname'] }}</mat-option>
                            </ng-container>
                            <ng-template #elseUsers>
                                <mat-option [value]="null">No se encontraron usuarios</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingUsers>
                            <mat-option [value]="null">Cargando usuarios...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
            </ng-container>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-2">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <div class="flex flex-wrap gap-2">
                <button mat-button type="button" (click)="submitForm(null)" class="cy__btn btn__primary">
                    <i class="fa-light fa-close"></i> Limpiar
                </button>
                <button mat-button type="button" (click)="submitForm(dataForm.value)" class="cy__btn btn__second">
                    <i class="fa-light fa-check"></i> Filtrar
                </button>
            </div>
        </div>
    </div>
</div>
