import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/app/environment/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
})


export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;

  constructor(
    private router: Router
  ) { }
    
  ngOnInit() {
    this.year = environment.year;
    this.title = environment.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

}
