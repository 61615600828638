<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full flex flex-wrap gap-4 items-start">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>

      <div class="w-auto max-w-full flex flex-wrap gap-4">
        <button-cy (onPress)="filterForm()" addClass="btn__second">
          <i class="fal fa-filter"></i> Filtrar
        </button-cy>
        <!-- <button (click)="openForm('create')" *ngIf="visibilityBtns.create" mat-button class="cy__btn btn__second">
          <i class="fa-light fa-plus"></i> Crear {{ title }}
        </button> -->
      </div>
        
    </div>


    <div class="block w-full pt-5">
      <datatable-capyei
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [loadingData]="loadingData"
        [noData]="noData"
        [customTemplate]="customTemplate">
        <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
          <ng-container [ngSwitch]="column.id">
            <!-- <ng-container *ngSwitchCase="'status'">
              <span [innerHTML]="statusData(item)"></span>
            </ng-container> -->
            <ng-container *ngSwitchCase="'action_buttons'">
              <button-cy *ngIf="row.status&&row.status == 'pending'" (onPress)="clickEvent(i, 'send', row.id, row)" addClass="btn-action warning" label="Enviar" icon="fa-light fa-send" />
              <button-cy *ngIf="visibilityBtns.read" (onPress)="clickEvent(i, 'read', row.id, row)" addClass="btn-action info" label="Ver" icon="fa-light fa-eye" [iconOnly]="true" />
              <button-cy *ngIf="visibilityBtns.edit" (onPress)="clickEvent(i, 'edit', row.id, row)" addClass="btn-action success" label="Editar" icon="fa-light fa-edit" [iconOnly]="true" />
              <button-cy *ngIf="visibilityBtns.remove" (onPress)="clickEvent(i, 'remove', row.id, row)" addClass="btn-action danger" label="Eliminar" icon="fa-light fa-trash" [iconOnly]="true" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="two_line-hidden">{{ item ?? '-' }}</span>
            </ng-container>
          </ng-container>
        </ng-template>
      </datatable-capyei>
    </div>
  </div>
</div>