import { Component, OnInit, Inject, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';

declare var $: any;


import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { XtrasService } from 'src/app/services/xtras.service';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpParams } from '@angular/common/http';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'modal-lead-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatRadioModule, MatSelectModule, FilterPipe, SearchDirective],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class ModalRegisterActivityComponent implements OnInit {

  dataForm = this.fb.group({
    parent_id:          new FormControl(null as any, [Validators.required]), //* Lead id
    customer_id:        new FormControl(null as any, [Validators.required]), //* id Cliente
    activity_type_id:   new FormControl(null, [Validators.required]),
    detail:             new FormControl(null),
    //* Con tarea
    // task:         new FormControl(0),
    // name:         new FormControl(null, [Validators.required]),
    // type_task:    new FormControl(null, [Validators.required]),
    // date:         new FormControl(null, [Validators.required]),
    // time:         new FormControl(null, [Validators.required]),
  });

  loadingTypes:boolean = false;
  types = [];
  filterTypes!: string;

  saleName?: string;

  loadingLeads:boolean = false;
  filterLeads!: string;
  leads:any[] = [];
  customerId:any = null;

  user$: Observable<UserType>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalRegisterActivityComponent>,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private toast: NotificationService,
    private http: HttpClientProvider,
    private load: LoadingService,
    public xServices: XtrasService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.user$ = this.authService.user$;
    console.log(data);
    this.saleName = data?.fullname;
    this.dataForm.get('parent_id')?.patchValue(data?.parent_id ?? null);
    this.dataForm.get('customer_id')?.patchValue(data?.customer_id ?? null);
    this.dataInit();
    this.customerId = data?.customer_id ?? null;
    if(!data?.parent_id&&data?.customer_id) {
      this.initializeApp();
    }
  }

  initializeApp() {
    this.loadingLeads = true;
    let data = {
      is_paginate: 0,
      customer_id: this.customerId
    }
    let queryParams = new HttpParams({ fromObject: data });
    this.http.getRequest(SettingsProvider.getUrl(`model/lead`), null,  queryParams).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.leads = result['items'] ?? [];
        this.loadingLeads = false;
      } else {
        this.loadingLeads = false;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      this.loadingLeads = false;
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  dataInit() {
    this.loadingTypes = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/activity-type?is_paginate=0`), null).subscribe(result => {
      console.log('activity-type', result);
      if(result['status']) {
        this.types = result['items'] ?? [];
        this.loadingTypes = false;
      } else {
        this.loadingTypes = false;
        this.xServices.getError(result, false, '/account');
      }
    }, error => {
      this.loadingTypes = false;
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(data?: any): void {
    this.dialogRef.close((data ?? null));
  }

  submitForm() {
    console.log(this.dataForm.value);
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl('add-lead-activity-reminder'), this.dataForm.value).subscribe(result => {
      this.load.dismiss();
      if(result['status']) {
        this.toast.success('¡Éxito!', (result['message'] ?? 'Item creado correctamente.'));
        this.dismiss({'reloadData': true});
      } else {
        this.xServices.getError(result, true, '/account');
      }
    }, error => {
      this.load.dismiss();
      console.log(error);
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    });
  }

}