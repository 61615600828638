import { SearchDirective } from 'src/app/directives/search.directive';
import { Component, OnInit, Inject, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { HttpClientProvider } from 'src/app/services/http-client';
import { SettingsProvider } from 'src/app/services/settings';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AlertService } from 'src/app/services/alert.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
declare var $: any;

@Component({
  selector: 'modal-client-lists-form',
  templateUrl: './client-lists-form.component.html',
  styleUrls: ['./client-lists-form.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatRadioModule, MatSelectModule, FilterPipe, SearchDirective, MatSlideToggleModule],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class ModalClientListsFormComponent implements OnInit {

  dataForm: FormGroup;

  action:string = 'create';

  noDataTypes: boolean = false;
  loadingTypes: boolean = false;
  filterTypes: string = '';
  types:any[] = [
    { id: 'dynamic', name: 'Por segmentos' },
    { id: 'static', name: 'Por contactos' },
  ];

  model = 'list-group';
  id:any = null;

  constructor(
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private alert: AlertService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public dialogRef: MatDialogRef<ModalClientListsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
    ) {
    this.action = data?.action ?? 'create';
    if(this.action != 'create' && data?.id) {
      this.id = data?.id;
      this.initializeApp();
    }
    this.dataForm = new FormGroup({
      type:          new FormControl('dynamic', [Validators.required]),
      name:          new FormControl(null, [Validators.required]),
    }) as FormGroup;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/read`), { id: this.id }).subscribe((result:any) => {
      if(result['status']) {
        console.log(result);
        this.dataForm.patchValue({
          name: result.item?.name ?? null,
          type: result.item?.type ?? null,
        });
        this.load.dismiss();
      } else {
        this.load.dismiss();
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo procesar la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo procesar la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

  submitForm() {
    this.load.loadingShow();
    console.log(this.dataForm.value);
    let form = this.dataForm.value;
    if(this.id) {
      form = {...form, id: this.id};
    }
    this.http.postRequest(SettingsProvider.getUrl(`model/${this.model}/${this.action}`), form).subscribe((result:any) => {
      if(result['status']) {
        this.load.dismiss();
        if(result['message']){
          this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), result['message'], 4000);
        } else {
          this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), 'El item fue '+ (this.action== 'edit' ? 'editado' : 'creado')+' exitosamente', 4000);
        }
        this.dialogRef.close({ reloadData: true });
      } else {
        this.load.dismiss();
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo procesar la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo procesar la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }
}