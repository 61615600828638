<div class="w-full block">
  <div class="w-full block table_responsive p-6 md:px-8 px-6 bg-white rounded-xl drop-shadow">
    <div class="content_btns flex flex-wrap gap-4 justify-between items-start w-auto">
      <div class="w-auto max-w-full block">
        <app-paginator *ngIf="pagination" [pagination]="pagination" (toPage)="goToPaginate($event)"></app-paginator>
      </div>
      
      <div *ngIf="create" class="w-auto max-w-full block">
        <a [routerLink]="['/role', 'create']" href="javascript:void(0)" class="font__text inline-flex items-center gap-2 text-base font-medium py-3 px-5 rounded bg-secondary hover:bg-primary text-white">
          <i class="fa-light fa-plus"></i> Nuevo Rol
        </a>
      </div>
    </div>

    <div class="block w-full pt-5">
      <app-cap-datatable
        [dataSource]="dataSource"
        [columnsdef]="columns"
        [actionsBtns]="actions"
        [visibilityBtns]="visibilityBtns"
        [loadingData]="loadingData"
        [noData]="noData"
      ></app-cap-datatable>
    </div>
  </div>
</div>