import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsProvider } from 'src/app/services/settings';
import { UserModel } from 'src/app/services/user-model';

import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XtrasService } from 'src/app/services/xtras.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from 'src/app/pipes/filter';
import { SearchDirective } from 'src/app/directives/search.directive';
import { MatButtonModule } from '@angular/material/button';

declare var $: any;

@Component({
  selector: 'user-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MatSelectModule, MatButtonModule, FilterPipe, SearchDirective],
  // providers: [UppercaseDirective, NoSpaceDirective]
})
export class UserFormPage implements OnInit {

  title = 'Usuario';
  share = '';
  action:any = 'create';
  show: boolean = true;
  item = null;

  dataForm = this.fb.group({
    name:                 new FormControl(null, [Validators.required]),
    last_name:            new FormControl(null, [Validators.required]),
    mother_last_name:     new FormControl(null, [Validators.required]),
    email:                new FormControl(null, [Validators.required]),
    cellphone:            new FormControl(null, [Validators.required]),
    password:             new FormControl(null as string | null, [Validators.required]),
    address:              new FormControl(null),
    role_id:              new FormControl(null as any[] | null),
    city_id:              new FormControl(null, [Validators.required]),
    active:               new FormControl(null, [Validators.required]),
  });

  rolesItems = new FormControl(null);

  cities:any    = [];
  roles         = [];
  createdAt     = null;
  updatedAt     = null;
  id            = null;
  isSuperAdmin  = false;

  type = null;

  organization_id = null;

  loadingCities:boolean = false;
  loadingRoles:boolean = false;

  filterRoles!: string;
  filterStatus!: string;
  filterCities!: string;

  status = [
    { value: 1, name: 'Activo' },
    { value: 0, name: 'Inactivo' },
  ]

  constructor(
    private router: Router,
    private metaTitle: Title,
    private userModel: UserModel,
    private route: ActivatedRoute,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    public xServices: XtrasService,
    private authService: AuthService,
    private fb: FormBuilder,
    private alert: AlertService
  ) {
    this.route.params.subscribe((res) => {
      this.loadData();
      this.authService.getUserByToken().subscribe((res: any) => {
        this.isSuperAdmin = res.pkyUser == 1 ? true : false;
      });
      this.type = res['action'];

      this.action = res['action'];
      if(res['action'] == 'edit'&&res['id']||res['action'] == 'read'&&res['id']){
        this.id = res['id'];
        this.initializeApp();
      }
      if(res['action'] == 'edit') {
        this.share = 'Editar';
        this.dataForm.get('password')?.removeValidators(Validators.required);
        this.dataForm.get('password')?.updateValueAndValidity();
      } else if(res['action'] == 'create') {
        this.share = 'Crear';
      } else if(res['action'] == 'read') {
        this.dataForm.disable();
        this.rolesItems.disable();
        this.share = 'Ver';
      }

      this.title = this.share +' Usuario';

      this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
      this.xServices.sharingObservableData = { title: this.title };
    });
  }

  loadData() {
    this.loadingCities = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/city?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingCities = false;
      this.cities = result['items'];
    }, error => {
      console.log(error);
      this.loadingCities = false;
      // this.errorPage();
    });
    this.loadingRoles = true;
    this.http.getRequest(SettingsProvider.getUrl(`model/role?is_paginate=0`)).subscribe(result => {
      console.log(result);
      this.loadingRoles = false;
      this.roles = result['items'];
    }, error => {
      console.log(error);
      this.loadingRoles = false;
      // this.errorPage();
    });
  }

  initializeApp() {
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl(`model/user/read`), { id: this.id }).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.item = result['item'];
        this.dataForm.patchValue({
          name:                 result['item']['name'] || null,
          email:                result['item']['email'] || null,
          cellphone:            result['item']['cellphone'] || null,
          password:             result['item']['password'] || null,
          address:              result['item']['address'] || null,
          city_id:              result['item']['city_id'] || null,
          active:               result['item']['active'] || null,
          role_id:              result['item']['role_id'] || null,
        });
        // console.log(this.dataForm.value);
        this.createdAt = result['item']['createdAt'];
        this.updatedAt = result['item']['updatedAt'];
        this.load.dismiss();
      } else {
        this.load.dismiss();
        // this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.', 4000);
        if(result['redirect']) {
          this.router.navigateByUrl('/users');
        }
        if(result['message']) {
          if(result['errors']) {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
          }
        } else {
          if(result['errors']) {
            this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
          } else {
            this.toast.error('¡Error!', 'No se pudo obtener la información, intente nuevamente.', 4000);
          }
        }
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      // this.errorPage();
    });
  }

  ngAfterViewInit(){
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.title}`);
  }

  ngOnInit() {
  }

  submit() {
    if(this.action !== 'read') {
      this.load.loadingShow();
      let form = this.dataForm.value;
      if(this.action == 'edit'&&!form['password']) {
        delete form['password'];
      }
      let body = {};
      if(this.id) {
        body = { ...form, id: this.id };
      } else {
        body = { ...form };
      }
      console.log(body);
      this.http.postRequest(SettingsProvider.getUrl('model/user/'+this.action), body).subscribe((result:any) => {
        if(result['status']) {
          if(result['message']){
            this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), result['message'], 4000);
          } else {
            this.toast.success('Item '+ (this.action== 'edit' ? 'editado' : 'creado'), 'El item fue '+ (this.action== 'edit' ? 'editado' : 'creado')+' exitosamente', 4000);
          }
          this.router.navigateByUrl('/users');
        } else {
          if(result['message']) {
            if(result['errors']) {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo procesar la información, intente nuevamente.');
            }
          } else {
            if(result['errors']) {
              this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
              this.toast.error('¡Error!', 'No se pudo procesar la información, intente nuevamente.', 4000);
            }
          }
        }
        this.load.dismiss();
      }, error => {
        console.log(error);
        this.load.dismiss();
        this.toast.error('Error al '+ (this.action== 'edit' ? 'editar' : 'crear') +' el item', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      });
    }
  }

  generatePassword() {
    let password = this.xServices.generateMinToken();
    this.dataForm.get('password')?.patchValue(password);
  }

  back() {
    this.router.navigateByUrl('/users');
  }
}
