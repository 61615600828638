
 import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';

export interface Data {
    title: string;
}

@Injectable({
    providedIn: 'root',
})
export class XtrasService {

    private emitChangeSource: BehaviorSubject<Data> = new BehaviorSubject<Data>({title: 'Administrador'});

    get sharingObservable() {
        return this.emitChangeSource.asObservable();
    }

    set sharingObservableData(data: Data) {
        this.emitChangeSource.next({...data});
    }

    constructor(
        private load: LoadingService,
        private toast: NotificationService,
        private alert: AlertService,
        private router: Router,
    ) {}

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    formatDate(date?: any) {
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agostos', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
        if(!date) return '-';
        const dateFormat = new Date(date);
        return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getDate()}, ${dateFormat.getFullYear()}`;
    }

    formatDateTime(date: any) {
        if(date) {
            const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agostos', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

            const dateFormat = new Date(date);
            return `${dateFormat.getDate()}/${('0' + (dateFormat.getMonth()+1)).slice(-2)}/${dateFormat.getFullYear()} - ${('0' + dateFormat.getHours()).slice(-2)}:${('0' + dateFormat.getMinutes()).slice(-2)}:${('0' + dateFormat.getSeconds()).slice(-2)}`;
        } else {
            return '-';
        }
    }

    generateMinToken() {
        var token = '';
        var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';
        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random() * str.length + 1);
            token += str.charAt(char)
        }

        return token;
    }

    removeObjectWithVar(array: any, vr: any, filtVr: any, vr2: any, filtVr2: any) {
        console.log(vr);
        console.log(filtVr);
        const objWithIdIndex = array.findIndex((obj: any) => {
            obj[vr] == filtVr;
            obj[vr2] == filtVr2;
        });
        array.splice(objWithIdIndex, 1);

        return array;
    }

    numericOnly(event: any): boolean {
        let pattern = /^([0-9])$/;
        let result = pattern.test(event.key);
        return result;
    }

    getFullName(name: string, lastName?: string, motherLastName?: string) {
        return `${name} ${lastName ?? ''} ${motherLastName ?? ''}`
    }

    getAction(action: string): string {
        switch (action) {
            case 'edit':
                return 'Editar';
        
            case 'read':
                return 'Ver';
            
            default:
                return 'Crear';
        }
    }

    async getFormData(data: { [key: string]: any }): Promise<FormData> {
        let formData = new FormData();

        await Promise.all(Object.entries(data).map( ([key, val]:any) => {
            if (val !== null && val !== undefined && val !== '') {
                formData.append(key, val);
            } 
        }));
      
        return formData;
    }

    getError(result: any, loading:boolean = false, redirectError: string = '/', errorDefault?:string) {
        if(loading) {
            this.load.dismiss();
        }
  
        if(result['redirect']) {
          this.router.navigateByUrl(redirectError);
        }
        if(result['message']) {
            if(result['errors']) {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
                this.alert.alertCapYei(result['message'], 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, 'No se pudo obtener la información, intente nuevamente.');
            }
        } else {
            if(result['errors']) {
                this.alert.alertCapYei('¡Error!', 'error', {showConfirmButton: true, confirmButtonText: 'Entendido'}, null, result['errors']);
            } else {
                this.toast.error('¡Error!', errorDefault ?? 'No se pudo obtener la información, intente nuevamente.', 4000);
            }
        }
    }

    isNumber(value: any, acceptString:boolean = false):boolean {
        if (typeof value === 'number') {
            // Retorna verdadero si el valor es un número
            return true;
        }
        // Verifica si el tipo de dato del valor es una cadena
        if (acceptString && typeof value === 'string') {
            // Convierte el valor a un número usando parseFloat
            const number = parseFloat(value);
            // Retorna verdadero si el número es válido y no es NaN
            return !isNaN(number) && isFinite(number);
        }
        // Retorna falso si el valor no es un número ni una cadena
        return false;
    }
}
