<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h1 class="text-[color:var(--second-color)] text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
        ESCRIBIR NOTA
    </h1>
</div>

<div mat-dialog-content class="w-full bg-white !p-0 !m-0">
    <div class="block w-full p-6">
        <form [formGroup]="dataForm" class="w-full grid grid-cols-1 gap-4 content_modal !py-0">
            <div class="w-full">
                <label for="fullname" class="text-secondary block w-full text-sm font-medium mb-2">Escribir nota</label>
                <textarea name="" id="" cols="30" rows="5" class="block m-0 w-full bg-[#f7feff] rounded py-2 px-4 text-base !shadow-none border-[#d6d6d6] focus:border-[#d6d6d6] focus:ring-0 border min-h-[42px] !text-left">
                </textarea>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-4">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <button mat-button type="button" (click)="submitForm()" class="cy__btn btn__second">
                <i class="fa-light fa-check"></i> Registrar Nota
            </button>
        </div>
    </div>
</div>
